import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import StepperCard from './Card'
import SuccessfulCreationDialog from './SuccessfulCreationDialog'
import ErrorDialog from './ErrorDialog'
import { ChangeEvent, useEffect, useState } from 'react'
import { useStepperControlContext } from './StepContextProvider'
import { scenarios } from '../pages/hesaplar/data/scenario'
import { StepperContainer } from './StepperContainer'
import { useFormContext } from 'react-hook-form'
import { Ihesapler } from '../types/customTypes'
import { Box } from '@mui/system'

function Scenarios() {
  const { handleNextClick } = useStepperControlContext()
  const { watch, setValue } = useFormContext<Ihesapler>()
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('scenarioType', event.target.value)
  }
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  useEffect(() => {
    //if successful, open success dialog, etc
  }, [])

  return (
    <StepperContainer display="flex" justifyContent="center" height="454px">
      <StepperCard width="600px">
        <RadioGroup
          name="scenarioType"
          onChange={handleRadioChange}
          value={watch('scenarioType')}
        >
          {scenarios.map((scenario, index) => (
            <FormControlLabel
              key={index}
              value={scenario.value}
              control={<Radio />}
              label={scenario.label}
            />
          ))}
        </RadioGroup>
        {showSuccessDialog && (
          <SuccessfulCreationDialog openDialog={showSuccessDialog} />
        )}
        {showErrorDialog && (
          <ErrorDialog
            openDialog={showErrorDialog}
            errorMessage="Could not connect bank Account."
          />
        )}
      </StepperCard>
    </StepperContainer>
  )
}

export default Scenarios
