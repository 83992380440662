import React from 'react'
import { Snackbar, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'

interface ErrorSnackbarProps {
  open: boolean
  onClose: () => void
  errorMessage: string
}

const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  open,
  onClose,
  errorMessage,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <Alert
        onClose={onClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        <Typography fontSize="20px">{errorMessage}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default ErrorSnackbar
