import React, { useState } from 'react'
import { styled } from 'styled-components'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'

const Title = styled.span<{ $isSenaryo?: boolean }>`
  margin-left: 5px;
  font-size: 24px; // Increased font size
  color: ${(props) =>
    props.$isSenaryo ? '#250000' : '#00FF00'}; // Change color based on mode
`

const NavContainer = styled.div`
  height: 70px;
  width: 100%;
  background: #679ec7;
  display: flex;
  justify-content: space-between; // Adjust layout to accommodate the dashboard switch
  align-items: center; // Center items vertically
`

const HamburgerSpan = styled.span`
  display: block;
  margin-top: 4px;
  border-radius: 15px;
  height: 4px;
  width: 35px;
  transition: 0.4s;
  background: white;
`

const Hamburger = styled.button<{ $isExpanded?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  margin: 20px 50px;
  transition: 0.4s;
`

const DashboardSwitch = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 50px;
  display: flex;
  align-items: center;
  color: white;
`

const StyledToggleOnIcon = styled(ToggleOnIcon)<{ $isSenaryo?: boolean }>`
  color: ${(props) => (props.$isSenaryo ? 'white' : '#00FF00')};
  font-size: 32px; // Increased icon size
`

const StyledToggleOffIcon = styled(ToggleOffIcon)<{ $isSenaryo?: boolean }>`
  color: ${(props) => (props.$isSenaryo ? 'black' : '#00FF00')};
  font-size: 32px; // Increased icon size
`

interface NavbarProps {
  toggleSidebar: () => void
  isExpanded: boolean
  toggleDashboard?: () => void // Optional prop if you want to handle dashboard switching from here
}

function Navbar({ toggleSidebar, isExpanded, toggleDashboard }: NavbarProps) {
  const handleDashboardToggle = () => {
    if (toggleDashboard) {
      toggleDashboard() // Call the toggleDashboard function if provided
    } else {
      // Fallback behavior if toggleDashboard is not provided
      const currentDashboard = sessionStorage.getItem('currentDashboard')
      const newDashboard = currentDashboard === 'Senaryo' ? 'Demo' : 'Senaryo'
      sessionStorage.setItem('currentDashboard', newDashboard)
      window.location.reload() // Refresh the page to apply the dashboard change
    }
  }

  const [currentDashboard, setCurrentDashboard] = useState<'Senaryo' | 'Demo'>(
    (sessionStorage.getItem('currentDashboard') as 'Senaryo' | 'Demo') ??
      'Senaryo',
  )

  return (
    <NavContainer>
      <Hamburger onClick={toggleSidebar} $isExpanded={isExpanded}>
        <HamburgerSpan></HamburgerSpan>
        <HamburgerSpan></HamburgerSpan>
        <HamburgerSpan></HamburgerSpan>
      </Hamburger>
      {/* Dashboard switch button */}
     
    </NavContainer>
  )
}

export default Navbar
