import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Paper,
  Divider,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Popover,
} from '@mui/material'
import styled from 'styled-components'
import theme from '../../../theme/theme'
import {
  useLazyGetLinkedAccountsByBankNameQuery,
  useLazyGetLinkedAccountsQuery,
} from '../../../api/paymentApi'
import { useEffect, useState } from 'react'
import {
  useLazyGetBakiyeByRefIdQuery,
  useLazyGetTransactionQuery,
  useLazyHesapRizaIptalQuery,
} from '../../../api/hesaplarApi'
import dayjs, { Dayjs } from 'dayjs'
import { formatAmount } from '../../../utils/formatter'
import { BarChart } from '@mui/x-charts'
import {
  DatePicker,
  DateRangeIcon,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TansactionDataGrid from '../../../components/TansactionDataGrid'
import AddIcon from '@mui/icons-material/Add'
import ConnectNewAccount from '../../hesaplar/connectBankAccount.tsx/ConnectNewAccount'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveAccountConsent from './DeleteHesapConfirmation'
import SuccessSnackbar from '../../../components/SuccessSnackbar'
import FullWidthSkeleton from '../../../components/FullWidthSkeleton'
import { getErrorMessageInfo } from '../../../utils/error'
import ErrorSnackbar from '../../../components/ErrorSnackbar'

const StyledCard = styled(Box)`
  margin-top: 10px;
  width: 300px;
  min-height: 50px;
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${theme.spacing(3)};
  background-color: '#f5f5f5';
  cursor: pointer;
  color: ${theme.palette.text.primary};
  position: relative;
  transition:
    box-shadow 0.3s,
    transform 0.3s;

  @media (max-width: 600px) {
    width: 240px;
    min-height: 80px;
    padding-top: ${theme.spacing(2)};
  }
`

interface BankDetailsCardProps {
  isCreditIncluded?: boolean
  account: any
  balances: any
}

export function BankDetailsCard({
  account,
  balances,
  isCreditIncluded,
}: BankDetailsCardProps) {
  const hspRef = account?.hspTml?.hspRef
  const iban = account?.hspTml?.hspNo
  const rizaNo = account?.rizaNo
  const balance = balances[hspRef]
  const bankName = account?.hspTml?.kisaAd

  const truncatedBankName =
    bankName?.length > 20 ? `${bankName.substring(0, 20)}` : bankName

  return (
    <StyledCard key={rizaNo} sx={{ cursor: 'default' }} textAlign="center">
      <Typography variant="h6" fontWeight="regular" alignContent="center">
        {bankName}
      </Typography>
      <Typography variant="h5" fontWeight="bold" style={{ marginTop: '15px' }}>
        {balance ? formatAmount(balance) : 0}TL
      </Typography>
      <Typography
        color="inherit"
        variant="body1"
        style={{ marginTop: '15px', color: 'grey' }}
      >
        {iban}
      </Typography>
      <Typography
        color="inherit"
        variant="body2"
        style={{ marginTop: '10px', color: 'grey' }}
      >
        {isCreditIncluded ? '*Kredi Dahil' : '*Kredi Dahil Degil'}
      </Typography>
    </StyledCard>
  )
}

export interface BalanceInfo {
  balance: number
  isCreditIncluded: boolean
}

export default function Hesaplar() {
  const [banks, setBanks] = useState<string[]>([
    'İş Bankası',
    'Garanti Bankası',
  ]) // Dummy banks
  const [selectedBank, setSelectedBank] = useState('')
  const [rizaNos, setRizaNos] = useState<string[]>([])
  const [selectedRiza, setSelectedRiza] = useState<string>('')
  const [accounts, setAccounts] = useState<any[]>([])
  const [balances, setBalances] = useState<Record<string, number>>({})
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedHesap, setSelectedHesap] = useState<string>('')
  const [transactions, setTransactions] = useState<any[]>([])
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false)
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'))
  const [endDate, setEndDate] = useState<Dayjs>(dayjs())
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isConnectAccountDialogOpen, setIsConnectAccountDialogOpen] =
    useState(false)
  const [isRemoveConsentDialogOpen, setIsRemoveConsentDialogOpen] =
    useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const isMoreDetailsPopUpOpen = Boolean(anchorEl)
  const accountDetailsPopoverId = isMoreDetailsPopUpOpen
    ? 'simple-popover'
    : undefined

  const [
    getLinkedAccounts,
    {
      isSuccess: isBankAccountFetchSuccess,
      isLoading: isBankAccountLoading,
      isError: isBankAccountLoadingError,
      data: linkedAccounts,
    },
  ] = useLazyGetLinkedAccountsQuery()

  const [getLinkedAccountsByBankName] =
    useLazyGetLinkedAccountsByBankNameQuery()

  const [getBakiye] = useLazyGetBakiyeByRefIdQuery()

  const [deleteConsentNumber] = useLazyHesapRizaIptalQuery()

  useEffect(() => {
    if (selectedBank) {
      setSelectedRiza('')
      setRizaNos([])
      getLinkedAccountsByBankName(selectedBank)
        .unwrap()
        .then((linkedBankAccounts) => {
          setAccounts(linkedBankAccounts)
        })
        .catch((err) => {
          console.log(err)
        })
      // getLinkedAccounts()
      //   .unwrap()
      //   .then((linkedAccounts) => {
      //     linkedAccounts?.map((linkedAccount: any) => {
      //       setRizaNos((prev) => [...prev, linkedAccount?.accounts[0]?.rizaNo])
      //     })
      //   })
      //   .catch((err) => {
      //     const errMsg = getErrorMessageInfo(err)
      //     setErrorMessage(errMsg)
      //   })
    }
  }, [selectedBank])

  // useEffect(() => {
  //   const result = linkedAccounts?.flatMap((linkedAccount: any) => {
  //     const accounts = linkedAccount?.accounts
  //     if (accounts && accounts[0]?.rizaNo === selectedRiza) {
  //       return accounts
  //     }
  //     return []
  //   })
  //   setAccounts(result)
  // }, [selectedRiza])

  useEffect(() => {
    if (accounts) {
      accounts.map((account: any) => {
        const hspRef = account?.hspTml?.hspRef
        const rizaNo = account?.rizaNo
        getBakiye({ refId: hspRef, rizaNo: rizaNo })
          .unwrap()
          .then((bakiye) => {
            const { hspRef, bky } = bakiye
            const { krdHsp } = bky
            const balanceAmount = parseFloat(bky?.bkyTtr)
            const isCreditIncluded = krdHsp.krdDhlGstr === '1'

            setBalances((prevBalances) => ({
              ...prevBalances,
              [hspRef]:
                krdHsp.krdDhlGstr === '1'
                  ? balanceAmount + parseFloat(krdHsp?.kulKrdTtr)
                  : balanceAmount,
            }))
          })
          .catch((err) => {
            console.log(err)
            setErrorMessage(err)
          })
      })

      const initialAccount = accounts[0]
      const hspRef = initialAccount?.hspTml?.hspRef
      const rizaNo = initialAccount?.rizaNo
      setSelectedHesap(hspRef)
      setSelectedRiza(rizaNo)
    }
  }, [accounts])

  const [
    getTransactions,
    {
      isSuccess: isTransactionFetchSuccess,
      isLoading: isTransactionFetchLoading,
      isError: isTransactionFetchError,
      error: transactionFetchError,
      data: accountTransactions,
    },
  ] = useLazyGetTransactionQuery()

  useEffect(() => {
    if (isTransactionFetchSuccess && accountTransactions) {
      setErrorMessage('')
      setTransactions(accountTransactions?.isller)
    }
  }, [
    isTransactionFetchSuccess,
    accountTransactions,
    isTransactionFetchError,
    transactionFetchError,
  ])

  const fetchTransaction = (srlmYon = '') => {
    const start = startDate.format('YYYY-MM-DD')
    const end = endDate.format('YYYY-MM-DD')
    if (selectedHesap && selectedRiza) {
      getTransactions({
        rizaNo: selectedRiza,
        hesapRef: selectedHesap,
        hesapIslemBslTrh: start,
        hesapIslemBtsTrh: end,
        srlmYon,
      })
        .unwrap()
        .then((transactions) => {
          setTransactions(transactions)
        })
        .catch((err: any) => {
          setErrorMessage(err)
        })
    }
  }

  useEffect(() => {
    fetchTransaction()
  }, [selectedHesap, selectedRiza])

  const handleApplyDates = () => {
    fetchTransaction()
    setIsDateRangePickerOpen(false)
    setStartDate(dayjs().subtract(7, 'day'))
    setEndDate(dayjs())
  }

  useEffect(() => {
    console.log(selectedRiza, selectedHesap)
  }, [selectedRiza, selectedHesap])

  const handleHesapClick = (hspNo: string, rizaNo: string) => {
    setSelectedRiza(rizaNo)
    if (selectedHesap === hspNo) {
      setSelectedHesap('')
    } else {
      setSelectedHesap(hspNo)
    }
  }

  useEffect(() => {
    setShowErrorAlert(true)
  }, [errorMessage])

  return (
    <Grid container spacing={2} height="85vh">
      <Grid item xs={4.5} height="100%">
        <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
          <Typography variant="h6" gutterBottom>
            Banka
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Select
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
            >
              {banks.map((bank, index) => (
                <MenuItem key={index} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* {selectedBank && (
            <>
              <Typography variant="h6" gutterBottom>
                Riza No
              </Typography>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <Select
                  value={selectedRiza ?? ''}
                  onChange={(e) => setSelectedRiza(e.target.value)}
                >
                  {!rizaNos || rizaNos.length === 0 ? (
                    <MenuItem value="">Riza Yok</MenuItem>
                  ) : (
                    rizaNos.map((rizaNo, index) => (
                      <MenuItem key={index} value={rizaNo}>
                        {rizaNo}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </>
          )} */}

          {selectedRiza && (
            <>
              <Box display="flex" justifyContent="space-between" mr={4} mt={3}>
                <Typography variant="h6" gutterBottom>
                  Hesaplar
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    width="100px"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button onClick={() => setIsConnectAccountDialogOpen(true)}>
                      <AddIcon color="success" fontSize="large" />
                    </Button>
                    {selectedHesap && (
                      <Button
                        onClick={() => setIsRemoveConsentDialogOpen(true)}
                      >
                        <DeleteIcon color="error" fontSize="large" />
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
              {isBankAccountLoading ? (
                <FullWidthSkeleton />
              ) : (
                <List>
                  {accounts?.map((account) => {
                    const hspRef = account?.hspTml?.hspRef
                    const hspNo = account?.hspTml?.hspNo
                    const rizaNo = account?.rizaNo
                    const balance = balances[hspRef]
                    const bankName = account?.hspTml?.kisaAd
                    return (
                      <ListItem key={hspRef} disablePadding sx={{ padding: 0 }}>
                        <ListItemButton
                          onClick={() => handleHesapClick(hspRef, rizaNo)}
                          sx={{
                            backgroundColor:
                              hspRef === selectedHesap
                                ? theme.palette.secondary.light
                                : 'transparent',
                            '&:hover': {
                              backgroundColor:
                                hspRef !== selectedHesap
                                  ? theme.palette.action.hover
                                  : theme.palette.secondary.light,
                            },
                          }}
                        >
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="flex-end"
                          >
                            <ListItemText
                              primary={
                                <Typography fontWeight="bold">
                                  {hspRef}
                                </Typography>
                              }
                            />
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography>₺ {formatAmount(balance)}</Typography>
                              <Button
                                sx={{ width: '1px' }}
                                onClick={(event) =>
                                  setAnchorEl(event.currentTarget)
                                }
                              >
                                <ExpandMoreIcon
                                  sx={{ transform: 'rotate(-90deg)' }}
                                />
                              </Button>
                            </Box>
                          </Box>
                        </ListItemButton>
                        <Popover
                          id={accountDetailsPopoverId}
                          open={isMoreDetailsPopUpOpen}
                          anchorEl={anchorEl}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <Box key={hspRef} sx={{ p: 1 }}>
                            <BankDetailsCard
                              account={accounts?.find(
                                (acc) => acc?.hspTml?.hspRef === selectedHesap,
                              )}
                              balances={balances}
                            />
                          </Box>
                        </Popover>
                      </ListItem>
                    )
                  })}
                </List>
              )}
            </>
          )}
        </Paper>
      </Grid>
      <Grid item xs={7.5} height="100%">
        <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
          <Typography variant="h6" gutterBottom>
            İşlemler
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Grid container>
            <Grid item sm={12} width="100%">
              <Box width="100%" overflow="auto">
                <BarChart
                  xAxis={[
                    {
                      scaleType: 'band',
                      data: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ],
                    },
                  ]}
                  series={[
                    {
                      data: [
                        1000, 500, 200, 100, 400, 300, 100, 500, 400, 250, 310,
                        100,
                      ],
                    },
                  ]}
                  width={900}
                  height={300}
                />
              </Box>
            </Grid>
            <Grid item sm={12} mb={2}>
              <Button
                color="secondary"
                variant={'outlined'}
                startIcon={<DateRangeIcon />}
                onClick={() => setIsDateRangePickerOpen(true)}
                style={{
                  textTransform: 'none',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
                disabled={
                  selectedRiza === '' ||
                  selectedHesap === '' ||
                  selectedRiza === undefined ||
                  selectedHesap === undefined
                }
              >
                İşlem Bilgileri
              </Button>
            </Grid>
            <Grid item xs={12} width="100%" height="calc(100% - 390px)">
              <TansactionDataGrid transactions={transactions} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog
        open={isDateRangePickerOpen}
        onClose={() => setIsDateRangePickerOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>Tarih Aralığı Seçin</DialogTitle>
        <DialogContent>
          <div style={{ width: '400px', padding: '0px' }}>
            <Stack spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={startDate}
                  label="Start date"
                  maxDate={endDate ? endDate : undefined}
                  onChange={(date) => {
                    date && setStartDate(date)
                  }}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  value={endDate}
                  label="End date"
                  minDate={startDate ? startDate : undefined}
                  onChange={(date) => {
                    date && setEndDate(date)
                  }}
                  format="YYYY-MM-DD"
                />
              </LocalizationProvider>
            </Stack>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            color="secondary"
            variant="contained"
            onClick={() => setIsDateRangePickerOpen(false)}
          >
            Kapat
          </Button>
          <Button
            size="medium"
            color="secondary"
            variant="contained"
            onClick={handleApplyDates}
          >
            Uygula
          </Button>
        </DialogActions>
      </Dialog>
      {isConnectAccountDialogOpen && (
        <ConnectNewAccount
          open={isConnectAccountDialogOpen}
          handleClose={() => setIsConnectAccountDialogOpen(false)}
        />
      )}
      {isRemoveConsentDialogOpen && (
        <RemoveAccountConsent
          open={isRemoveConsentDialogOpen}
          handleClose={() => setIsRemoveConsentDialogOpen(false)}
          onConfirm={() => deleteConsentNumber(selectedRiza)}
        />
      )}
      {showSuccessAlert && (
        <SuccessSnackbar
          open={showSuccessAlert}
          onClose={() => setShowSuccessAlert(false)}
          successMessage={successMessage}
        />
      )}
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setShowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
    </Grid>
  )
}
