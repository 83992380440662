import { IInvoice } from '../types/customTypes'

export const invoices: IInvoice[] = [
  {
    invoice_number: 'INV-0001',
    amount_to_be_paid: 1010,
    subtotal_amount: 910,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 910,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 910,
        unit_price: 910,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 1',
      email: 'customer1@example.com',
      address: {
        address_line_1: '1 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 1',
      email: 'supplier1@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '1 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0002',
    amount_to_be_paid: 1020,
    subtotal_amount: 920,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 920,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 920,
        unit_price: 920,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 2',
      email: 'customer2@example.com',
      address: {
        address_line_1: '2 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 2',
      email: 'supplier2@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '2 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0003',
    amount_to_be_paid: 1030,
    subtotal_amount: 930,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 930,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 930,
        unit_price: 930,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 3',
      email: 'customer3@example.com',
      address: {
        address_line_1: '3 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 3',
      email: 'supplier3@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '3 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0004',
    amount_to_be_paid: 1040,
    subtotal_amount: 940,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 940,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 940,
        unit_price: 940,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 4',
      email: 'customer4@example.com',
      address: {
        address_line_1: '4 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 4',
      email: 'supplier4@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '4 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0005',
    amount_to_be_paid: 1050,
    subtotal_amount: 950,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 950,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 950,
        unit_price: 950,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 5',
      email: 'customer5@example.com',
      address: {
        address_line_1: '5 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 5',
      email: 'supplier5@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '5 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0006',
    amount_to_be_paid: 1060,
    subtotal_amount: 960,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 960,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 960,
        unit_price: 960,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 6',
      email: 'customer6@example.com',
      address: {
        address_line_1: '6 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 6',
      email: 'supplier6@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '6 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0007',
    amount_to_be_paid: 1070,
    subtotal_amount: 970,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 970,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 970,
        unit_price: 970,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 7',
      email: 'customer7@example.com',
      address: {
        address_line_1: '7 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 7',
      email: 'supplier7@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '7 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0008',
    amount_to_be_paid: 1080,
    subtotal_amount: 980,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 980,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 980,
        unit_price: 980,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 8',
      email: 'customer8@example.com',
      address: {
        address_line_1: '8 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 8',
      email: 'supplier8@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '8 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0009',
    amount_to_be_paid: 1090,
    subtotal_amount: 990,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 990,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 990,
        unit_price: 990,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 9',
      email: 'customer9@example.com',
      address: {
        address_line_1: '9 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 9',
      email: 'supplier9@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '9 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0010',
    amount_to_be_paid: 1100,
    subtotal_amount: 1000,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1000,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1000,
        unit_price: 1000,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 10',
      email: 'customer10@example.com',
      address: {
        address_line_1: '10 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 10',
      email: 'supplier10@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '10 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0011',
    amount_to_be_paid: 1110,
    subtotal_amount: 1010,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1010,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1010,
        unit_price: 1010,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 11',
      email: 'customer11@example.com',
      address: {
        address_line_1: '11 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 11',
      email: 'supplier11@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '11 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0012',
    amount_to_be_paid: 1120,
    subtotal_amount: 1020,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1020,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1020,
        unit_price: 1020,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 12',
      email: 'customer12@example.com',
      address: {
        address_line_1: '12 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 12',
      email: 'supplier12@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '12 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0013',
    amount_to_be_paid: 1130,
    subtotal_amount: 1030,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1030,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1030,
        unit_price: 1030,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 13',
      email: 'customer13@example.com',
      address: {
        address_line_1: '13 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 13',
      email: 'supplier13@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '13 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0014',
    amount_to_be_paid: 1140,
    subtotal_amount: 1040,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1040,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1040,
        unit_price: 1040,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 14',
      email: 'customer14@example.com',
      address: {
        address_line_1: '14 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 14',
      email: 'supplier14@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '14 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0015',
    amount_to_be_paid: 1150,
    subtotal_amount: 1050,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1050,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1050,
        unit_price: 1050,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 15',
      email: 'customer15@example.com',
      address: {
        address_line_1: '15 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 15',
      email: 'supplier15@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '15 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0016',
    amount_to_be_paid: 1160,
    subtotal_amount: 1060,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1060,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1060,
        unit_price: 1060,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 16',
      email: 'customer16@example.com',
      address: {
        address_line_1: '16 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 16',
      email: 'supplier16@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '16 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0017',
    amount_to_be_paid: 1170,
    subtotal_amount: 1070,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1070,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1070,
        unit_price: 1070,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 17',
      email: 'customer17@example.com',
      address: {
        address_line_1: '17 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 17',
      email: 'supplier17@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '17 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0018',
    amount_to_be_paid: 1180,
    subtotal_amount: 1080,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1080,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1080,
        unit_price: 1080,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 18',
      email: 'customer18@example.com',
      address: {
        address_line_1: '18 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 18',
      email: 'supplier18@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '18 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0019',
    amount_to_be_paid: 1190,
    subtotal_amount: 1090,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1090,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1090,
        unit_price: 1090,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 19',
      email: 'customer19@example.com',
      address: {
        address_line_1: '19 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 19',
      email: 'supplier19@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '19 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0020',
    amount_to_be_paid: 1200,
    subtotal_amount: 1100,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1100,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1100,
        unit_price: 1100,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 20',
      email: 'customer20@example.com',
      address: {
        address_line_1: '20 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 20',
      email: 'supplier20@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '20 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0021',
    amount_to_be_paid: 1210,
    subtotal_amount: 1110,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1110,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1110,
        unit_price: 1110,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 21',
      email: 'customer21@example.com',
      address: {
        address_line_1: '21 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 21',
      email: 'supplier21@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '21 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0022',
    amount_to_be_paid: 1220,
    subtotal_amount: 1120,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1120,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1120,
        unit_price: 1120,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 22',
      email: 'customer22@example.com',
      address: {
        address_line_1: '22 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 22',
      email: 'supplier22@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '22 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0023',
    amount_to_be_paid: 1230,
    subtotal_amount: 1130,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1130,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1130,
        unit_price: 1130,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 23',
      email: 'customer23@example.com',
      address: {
        address_line_1: '23 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 23',
      email: 'supplier23@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '23 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0024',
    amount_to_be_paid: 1240,
    subtotal_amount: 1140,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1140,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1140,
        unit_price: 1140,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 24',
      email: 'customer24@example.com',
      address: {
        address_line_1: '24 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 24',
      email: 'supplier24@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '24 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0025',
    amount_to_be_paid: 1250,
    subtotal_amount: 1150,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1150,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1150,
        unit_price: 1150,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 25',
      email: 'customer25@example.com',
      address: {
        address_line_1: '25 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 25',
      email: 'supplier25@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '25 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0026',
    amount_to_be_paid: 1260,
    subtotal_amount: 1160,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1160,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1160,
        unit_price: 1160,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 26',
      email: 'customer26@example.com',
      address: {
        address_line_1: '26 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 26',
      email: 'supplier26@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '26 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0027',
    amount_to_be_paid: 1270,
    subtotal_amount: 1170,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1170,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1170,
        unit_price: 1170,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 27',
      email: 'customer27@example.com',
      address: {
        address_line_1: '27 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 27',
      email: 'supplier27@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '27 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0028',
    amount_to_be_paid: 1280,
    subtotal_amount: 1180,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1180,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1180,
        unit_price: 1180,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 28',
      email: 'customer28@example.com',
      address: {
        address_line_1: '28 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 28',
      email: 'supplier28@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '28 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0029',
    amount_to_be_paid: 1290,
    subtotal_amount: 1190,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1190,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1190,
        unit_price: 1190,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 29',
      email: 'customer29@example.com',
      address: {
        address_line_1: '29 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 29',
      email: 'supplier29@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '29 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0030',
    amount_to_be_paid: 1300,
    subtotal_amount: 1200,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1200,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1200,
        unit_price: 1200,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 30',
      email: 'customer30@example.com',
      address: {
        address_line_1: '30 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 30',
      email: 'supplier30@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '30 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0031',
    amount_to_be_paid: 1310,
    subtotal_amount: 1210,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1210,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1210,
        unit_price: 1210,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 31',
      email: 'customer31@example.com',
      address: {
        address_line_1: '31 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 31',
      email: 'supplier31@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '31 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0032',
    amount_to_be_paid: 1320,
    subtotal_amount: 1220,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1220,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1220,
        unit_price: 1220,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 32',
      email: 'customer32@example.com',
      address: {
        address_line_1: '32 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 32',
      email: 'supplier32@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '32 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0033',
    amount_to_be_paid: 1330,
    subtotal_amount: 1230,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1230,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1230,
        unit_price: 1230,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 33',
      email: 'customer33@example.com',
      address: {
        address_line_1: '33 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 33',
      email: 'supplier33@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '33 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0034',
    amount_to_be_paid: 1340,
    subtotal_amount: 1240,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1240,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1240,
        unit_price: 1240,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 34',
      email: 'customer34@example.com',
      address: {
        address_line_1: '34 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 34',
      email: 'supplier34@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '34 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0035',
    amount_to_be_paid: 1350,
    subtotal_amount: 1250,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1250,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1250,
        unit_price: 1250,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 35',
      email: 'customer35@example.com',
      address: {
        address_line_1: '35 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 35',
      email: 'supplier35@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '35 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0036',
    amount_to_be_paid: 1360,
    subtotal_amount: 1260,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1260,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1260,
        unit_price: 1260,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 36',
      email: 'customer36@example.com',
      address: {
        address_line_1: '36 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 36',
      email: 'supplier36@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '36 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0037',
    amount_to_be_paid: 1370,
    subtotal_amount: 1270,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1270,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1270,
        unit_price: 1270,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 37',
      email: 'customer37@example.com',
      address: {
        address_line_1: '37 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 37',
      email: 'supplier37@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '37 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0038',
    amount_to_be_paid: 1380,
    subtotal_amount: 1280,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1280,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1280,
        unit_price: 1280,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 38',
      email: 'customer38@example.com',
      address: {
        address_line_1: '38 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 38',
      email: 'supplier38@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '38 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0039',
    amount_to_be_paid: 1390,
    subtotal_amount: 1290,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1290,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1290,
        unit_price: 1290,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 39',
      email: 'customer39@example.com',
      address: {
        address_line_1: '39 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 39',
      email: 'supplier39@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '39 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0040',
    amount_to_be_paid: 1400,
    subtotal_amount: 1300,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1300,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1300,
        unit_price: 1300,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 40',
      email: 'customer40@example.com',
      address: {
        address_line_1: '40 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 40',
      email: 'supplier40@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '40 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0041',
    amount_to_be_paid: 1410,
    subtotal_amount: 1310,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1310,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1310,
        unit_price: 1310,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 41',
      email: 'customer41@example.com',
      address: {
        address_line_1: '41 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 41',
      email: 'supplier41@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '41 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0042',
    amount_to_be_paid: 1420,
    subtotal_amount: 1320,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1320,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1320,
        unit_price: 1320,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 42',
      email: 'customer42@example.com',
      address: {
        address_line_1: '42 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 42',
      email: 'supplier42@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '42 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0043',
    amount_to_be_paid: 1430,
    subtotal_amount: 1330,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1330,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1330,
        unit_price: 1330,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 43',
      email: 'customer43@example.com',
      address: {
        address_line_1: '43 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 43',
      email: 'supplier43@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '43 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0044',
    amount_to_be_paid: 1440,
    subtotal_amount: 1340,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1340,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1340,
        unit_price: 1340,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 44',
      email: 'customer44@example.com',
      address: {
        address_line_1: '44 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 44',
      email: 'supplier44@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '44 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0045',
    amount_to_be_paid: 1450,
    subtotal_amount: 1350,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1350,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1350,
        unit_price: 1350,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 45',
      email: 'customer45@example.com',
      address: {
        address_line_1: '45 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 45',
      email: 'supplier45@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '45 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0046',
    amount_to_be_paid: 1460,
    subtotal_amount: 1360,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1360,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1360,
        unit_price: 1360,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 46',
      email: 'customer46@example.com',
      address: {
        address_line_1: '46 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 46',
      email: 'supplier46@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '46 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0047',
    amount_to_be_paid: 1470,
    subtotal_amount: 1370,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1370,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1370,
        unit_price: 1370,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 47',
      email: 'customer47@example.com',
      address: {
        address_line_1: '47 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 47',
      email: 'supplier47@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '47 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0048',
    amount_to_be_paid: 1480,
    subtotal_amount: 1380,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1380,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1380,
        unit_price: 1380,
        line_vat_percentage: 10,
      },
    ],
    status: 'Factoring Requested',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 48',
      email: 'customer48@example.com',
      address: {
        address_line_1: '48 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 48',
      email: 'supplier48@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '48 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0049',
    amount_to_be_paid: 1490,
    subtotal_amount: 1390,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1390,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1390,
        unit_price: 1390,
        line_vat_percentage: 10,
      },
    ],
    status: 'Awaiting Payment',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 49',
      email: 'customer49@example.com',
      address: {
        address_line_1: '49 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 49',
      email: 'supplier49@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '49 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
  {
    invoice_number: 'INV-0050',
    amount_to_be_paid: 1500,
    subtotal_amount: 1400,
    total_tax_amount: 100,
    total_discount_amount: 0,
    invoice_lines: [
      {
        line_total_amount: 1400,
        line_vat_amount: 100,
        line_discount_amount: 0,
        quantity: 1,
        subtotal_amount: 1400,
        unit_price: 1400,
        line_vat_percentage: 10,
      },
    ],
    status: 'Paid',
    payment_date: '2022-01-01T00:00:00.000Z',
    issued_date: '2022-01-01T00:00:00.000Z',
    due_date: '2022-01-31T00:00:00.000Z',
    customer_info: {
      fullName: 'Customer 50',
      email: 'customer50@example.com',
      address: {
        address_line_1: '50 Main St',
        address_line_2: '',
        country: 'USA',
        city: 'New York',
        post_code: '10001',
        region_province: 'NY',
      },
    },
    supplier_info: {
      fullName: 'Supplier 50',
      email: 'supplier50@example.com',
      payment_info: {
        type: 'Bank Transfer',
        value: '1234567890',
      },
      address: {
        address_line_1: '50 Elm St',
        address_line_2: '',
        country: 'USA',
        city: 'Los Angeles',
        post_code: '90001',
        region_province: 'CA',
      },
    },
  },
]

// function generateInvoices(): IInvoice[] {
//   const invoices: IInvoice[] = []

//   for (let i = 1; i <= 50; i++) {
//     const invoice: IInvoice = {
//       invoice_number: `INV-${i.toString().padStart(4, '0')}`,
//       amount_to_be_paid: 1000 + i * 10, // Incrementing amount for each invoice
//       subtotal_amount: 900 + i * 10, // Incrementing subtotal for each invoice
//       total_tax_amount: 100,
//       total_discount_amount: 0,
//       invoice_lines: [
//         {
//           line_total_amount: 900 + i * 10,
//           line_vat_amount: 100,
//           line_discount_amount: 0,
//           quantity: 1,
//           subtotal_amount: 900 + i * 10,
//           unit_price: 900 + i * 10,
//           line_vat_percentage: 10,
//         },
//       ],
//       status: 'Paid',
//       payment_date: new Date('2022-01-01'),
//       issued_date: new Date('2022-01-01'),
//       due_date: new Date('2022-01-31'),
//       customer_info: {
//         fullName: `Customer ${i}`,
//         email: `customer${i}@example.com`,
//         address: {
//           address_line_1: `${i} Main St`,
//           address_line_2: '',
//           country: 'USA',
//           city: 'New York',
//           post_code: '10001',
//           region_province: 'NY',
//         },
//       },
//       supplier_info: {
//         fullName: `Supplier ${i}`,
//         email: `supplier${i}@example.com`,
//         payment_info: {
//           type: 'Bank Transfer',
//           value: '1234567890',
//         },
//         address: {
//           address_line_1: `${i} Elm St`,
//           address_line_2: '',
//           country: 'USA',
//           city: 'Los Angeles',
//           post_code: '90001',
//           region_province: 'CA',
//         },
//       },
//     }

//     invoices.push(invoice)
//   }

//   return invoices
// }

// // Generate 50 invoices
// const fiftyInvoices = generateInvoices()
function generateInvoices(): any[] {
  const statuses: string[] = ['Paid', 'Awaiting Payment', 'Factoring Requested']
  const invoices: IInvoice[] = []

  for (let i = 1; i <= 50; i++) {
    const statusIndex = Math.floor(Math.random() * statuses.length)
    const status = statuses[statusIndex]

    const invoice: any = {
      invoice_number: `INV-${i.toString().padStart(4, '0')}`,
      amount_to_be_paid: 1000 + i * 10, // Incrementing amount for each invoice
      subtotal_amount: 900 + i * 10, // Incrementing subtotal for each invoice
      total_tax_amount: 100,
      total_discount_amount: 0,
      invoice_lines: [
        {
          line_total_amount: 900 + i * 10,
          line_vat_amount: 100,
          line_discount_amount: 0,
          quantity: 1,
          subtotal_amount: 900 + i * 10,
          unit_price: 900 + i * 10,
          line_vat_percentage: 10,
        },
      ],
      status: status,
      payment_date: new Date('2022-01-01'),
      issued_date: new Date('2022-01-01'),
      due_date: new Date('2022-01-31'),
      customer_info: {
        fullName: `Customer ${i}`,
        email: `customer${i}@example.com`,
        address: {
          address_line_1: `${i} Main St`,
          address_line_2: '',
          country: 'USA',
          city: 'New York',
          post_code: '10001',
          region_province: 'NY',
        },
      },
      supplier_info: {
        fullName: `Supplier ${i}`,
        email: `supplier${i}@example.com`,
        payment_info: {
          type: 'Bank Transfer',
          value: '1234567890',
        },
        address: {
          address_line_1: `${i} Elm St`,
          address_line_2: '',
          country: 'USA',
          city: 'Los Angeles',
          post_code: '90001',
          region_province: 'CA',
        },
      },
    }

    invoices.push(invoice)
  }

  return invoices
}

// Generate 50 invoices
const fiftyInvoices = generateInvoices()