import {
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import StepperCard from '../../components/StepperCard'
import { StepperContainer } from '../StepperContainer'
import { useFormContext } from 'react-hook-form'
import { IOdemeHesap } from '../../types/odemeTypes'

interface Option {
  label: string
  value: string
}

export const amaciItems: Option[] = [
  { value: '01', label: 'Konut Kirası Ödemesi' },
  { value: '02', label: 'İş yeri Kirası Ödemesi' },
  { value: '03', label: 'Diğer Kira Ödemesi' },
  {
    value: '04',
    label: 'E-Ticaret Ödemesi: Elektronik ticaret işlem amaçlı aktarımlar',
  },
  {
    value: '05',
    label: 'Çalışan Ödemesi: Maaş, harcırah, prim gibi çalışan ödemeleri',
  },
  {
    value: '06',
    label:
      'Ticari ödeme: Ticari işletmelerin birbirilerine kendi hesaplarına veya müşterilerine ödemeleri, borç, ithalat, ihracat, şirket satın alma, vb. kapsamında ödemeler',
  },
  {
    value: '07',
    label:
      'Bireysel Ödeme: Özel amaçlı (aile bireylerine, hediye, bağış, borç, alışveriş vs.) ödemeler',
  },
  {
    value: '08',
    label:
      'Yatırım: Mevduat, menkul kıymet, döviz, gayrı menkul, taşıt, varlık alımı, temettü ödeme, tahsilat vb. gibi yatırım amaçlı ödemeler',
  },
  {
    value: '09',
    label:
      'Finansal: Kredi, depo, repo, türev, finansal varlık alım/satımı vb. ödemeler',
  },
  { value: '10', label: 'Eğitim ödemesi' },
  { value: '11', label: 'Aidat ödemesi' },
  { value: '12', label: 'Diğer' },
]

const paymentSources: Option[] = [
  { value: 'I', label: 'Internet Bankacılığı ile gönderilen ödemeler' },
  {
    value: 'A',
    label: 'Otomatik para makineleri (ATM) ile gönderilen ödemeler',
  },
  { value: 'T', label: 'Telefon bankacılığı ile gönderilen ödemeler' },
  { value: 'K', label: 'Kiosklar aracılığıyla gönderilen ödemeler' },
  { value: 'S', label: 'Şubeden girilen ödemeler' },
  { value: 'M', label: 'Mobil uygulamadan gönderilen ödemeler' },
  {
    value: 'O',
    label: 'Açık bankacılık aracılığı ile gönderilen ödemeler',
  },
  {
    value: 'D',
    label: 'Yukarıdakiler dışındaki ödeme kaynaklarından gönderilen ödemeler',
  },
]
function OdemeAraci() {
  const { setValue, watch, register } = useFormContext<IOdemeHesap>()

  const handlePaymentSourceChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string
    setValue('odmBsltm.odmAyr.odmKynk', value)
  }

  const handleAmaciChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string
    setValue('odmBsltm.odmAyr.odmAmc', selectedValue)
  }

  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              sx={{ marginTop: 2 }}
              select
              label="Ödeme Kaynağı*"
              variant="outlined"
              fullWidth
              margin="normal"
              value={watch('odmBsltm.odmAyr.odmKynk')}
              onChange={handlePaymentSourceChange}
            >
              {amaciItems.length === 0 ? (
                <MenuItem key="">No items available</MenuItem>
              ) : (
                [
                  <MenuItem key="select" value="">
                    Select an item
                  </MenuItem>,
                  ...paymentSources.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                ]
              )}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              select
              label="Ödeme Amacı*"
              variant="outlined"
              fullWidth
              margin="normal"
              value={watch('odmBsltm.odmAyr.odmAmc')}
              onChange={handleAmaciChange}
            >
              {amaciItems.length === 0 ? (
                <MenuItem key="">No items available</MenuItem>
              ) : (
                [
                  <MenuItem key="select" value="">
                    Select an item
                  </MenuItem>,
                  ...amaciItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                ]
              )}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Ödeme Açıklaması"
              sx={{ marginTop: 2 }}
              {...register('odmBsltm.odmAyr.odmAcklm')}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Referans Bilgisi"
              sx={{ marginTop: 2 }}
              fullWidth
              value={watch('odmBsltm.odmAyr.refBlg')}
              onChange={(e) =>
                setValue('odmBsltm.odmAyr.refBlg', e.target.value)
              }
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export default OdemeAraci
