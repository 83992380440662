import { z } from 'zod'

const required = { message: 'This field is required' }

function requiredFieldError(fieldName: string) {
  return {
    code: z.ZodIssueCode.custom,
    message: 'This field is required',
    path: [fieldName],
  }
}
export const paymentSchema = z
  .object({
    odmBsltm: z.object({
      alc: z.object({
        hspNo: z.string().optional(),
        unv: z.string().optional(),
        kolas: z
          .object({
            kolasDgr: z.string().optional(),
            kolasHspTur: z.string().optional(),
          })
          .optional(),
      }),
      kkod: z
        .object({
          aksTur: z.string().optional(),
          kkodRef: z.string().optional(),
          kkodUrtcKod: z.string().optional(),
        })
        .optional(),
      // gon: z.object({
      //   unv: z.string().min(1, required),
      //   hspNo: z.string().min(1, required),
      // }),
      // kmlk: z.object({
      //   ohkTur: z.string().min(1, required),
      //   kmlkVrs: z.string().min(1, required),
      //   krmKmlkVrs: z.string().optional(),
      // }),
      odmAyr: z.object({
        odmAmc: z.string().min(1, required),
        odmAcklm: z.string().min(1, required),
      }),
      islTtr: z.object({
        ttr: z.number().min(1, required),
      }),
    }),
    paymentMethod: z.string().min(1, required),
  })
  .superRefine(
    (
      {
        paymentMethod,
        odmBsltm: {
          alc: { hspNo, unv, kolas },
          kkod,
        },
      },
      refinementContext,
    ) => {
      let errors = []
      if (paymentMethod === 'hesapNumarasi' || paymentMethod === 'karekod') {
        if (hspNo === '') {
          errors.push(
            refinementContext.addIssue(
              requiredFieldError('odmBsltm.alc.hspNo'),
            ),
          )
        }
        if (unv === '') {
          errors.push(
            refinementContext.addIssue(requiredFieldError('odmBsltm.alc.unv')),
          )
        }
      }
      if (paymentMethod === 'karekod') {
        if (kkod?.aksTur === '') {
          errors.push(
            refinementContext.addIssue(
              requiredFieldError('odmBsltm.kkod.aksTur'),
            ),
          )
        }
        if (kkod?.kkodRef === '') {
          errors.push(
            refinementContext.addIssue(
              requiredFieldError('odmBsltm.kkod.kkodRef'),
            ),
          )
        }
        if (kkod?.kkodUrtcKod === '') {
          errors.push(
            refinementContext.addIssue(
              requiredFieldError('odmBsltm.kkod.kkodUrtcKod'),
            ),
          )
        }
      }
      if (paymentMethod === 'kolas') {
        if (kolas?.kolasHspTur === '') {
          errors.push(
            refinementContext.addIssue(
              requiredFieldError('odmBsltm.alc.kolas.kolasHspTur'),
            ),
          )
        }
        if (kolas?.kolasDgr === '') {
          errors.push(
            refinementContext.addIssue(
              requiredFieldError('odmBsltm.alc.kolas.kolasDgr'),
            ),
          )
        }
      }
      return errors
    },
  )
