import { useForm } from 'react-hook-form'
import { IChildren } from '../types/customTypes'
import StepContextProvider from './StepContextProvider'

function StepperProvider({ children }: IChildren) {
  const formMethods = useForm()

  return (
    <StepContextProvider formMethods={formMethods}>
      {children}
    </StepContextProvider>
  )
}

export default StepperProvider
