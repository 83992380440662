import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ILoginCredentials } from '../types/customTypes'
import { api } from './api';
import { setCredentials } from '../features/autheticationSlice';

export const authApi = api.injectEndpoints({  
  endpoints: (builder) => ({
    login: builder.mutation<
      ILoginCredentials,
      { username: string; password: string }
    >({
      query: (loginInfo) => {
        // Log the login details
        console.log('Login details:', loginInfo)
        return {
          url: 'https://devtest.fincloudy.com/api/v1/login',
          method: 'POST',
          body: loginInfo,
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        console.log("onQueryStarted");
        
        try {
          const { data } = await queryFulfilled
          dispatch(setCredentials(data));
        } catch (error) {
          console.log(error)
        }
      },
    }),

    logout: builder.mutation({
      query: (token) => ({
        url: 'https://devtest.fincloudy.com/api/v1/auth/signout',
        method: 'POST',
        body: token,
      }),
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation } = authApi
