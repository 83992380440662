import DashboardIcon from '@mui/icons-material/Dashboard'
import PaymentIcon from '@mui/icons-material/Payment'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { MenuItemInterface } from '../../types/customTypes'
import { Business, PeopleTwoTone } from '@mui/icons-material'

export const sidebarListItems: MenuItemInterface[] = [
  {
    text: ' Anasayfa ',
    icon: <DashboardIcon />,
    link: '/',
  },
  {
    text: 'İş Yeri Bilgilerim',
    icon: <Business />,
    link: '/işyeri-bilgileri',
  },
  {
    text: 'Müşteri/Tedarikçi Bilgileri',
    icon: <PeopleTwoTone />,
    link: '/musteri-tedarikci',
  },
  {
    text: 'ÖDEME SERViSLERi',
    icon: <PaymentIcon />,
    link: '/odemeService',
    subItems: [
      {
        text: 'Müşteriden Ödeme Talebi',
        link: '/Müşteri',
      },
      {
        text: 'Tedarikçi Fatura Ödeme',
        link: '/Tedarikçi',
      },
      {
        text: 'Para Transferi',
        link: '/para-transferi',
      },
    ],
  },
  {
    text: 'Hesap Bilgisi',
    icon: <AccountBoxIcon />,
    link: '/accountInfo',
    subItems: [
      {
        text: 'Banka Hesapları',
        link: '/hesaplar',
      },

      {
        text: 'Banka Hesap İşlemleri',
        link: '/hesaplar',
      },
    ],
  },
]
export default sidebarListItems
