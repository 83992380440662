import { Button } from '@mui/material'
import { Box } from '@mui/system'
import DialogInput from '../../../components/Dialog'
import { useLocation } from 'react-router-dom'
import { Container, Grid, Typography } from '@mui/material'
import { trTR } from '@mui/x-data-grid/locales'
import { DataGrid } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
interface ViewInvoiceDetailsProps {
  openDialog: boolean
  onClose: () => void
  data: any
}

interface InvoiceDetailItemProps {
  label: string
  value: any
}
interface TitleProps {
  title: string
}
function InvoiceDetailItem({ label, value }: InvoiceDetailItemProps) {
  if (!value) {
    return null
  }

  return (
    <Grid container alignItems="flex-end">
      <Typography
        component="span"
        sx={{
          fontWeight: 'bold',
          paddingRight: '10px',
          marginTop: '2px',
          fontSize: '15px',
        }}
      >
        {label}:
      </Typography>
      <Typography component="span">{value}</Typography>
    </Grid>
  )
}

function Title({ title }: TitleProps) {
  return (
    <Typography variant="h6" gutterBottom style={{ color: '#0047AB' }}>
      {title}
    </Typography>
  )
}

export default function ViewInvoiceDetailsDialog({
  openDialog,
  onClose,
  data,
}: ViewInvoiceDetailsProps) {
  const location = useLocation()
  const { salesInvoiceDetails, selectedInvoiceId } = data
  console.log('data', data)
  let selectedInvoice: any
  if (salesInvoiceDetails)
    selectedInvoice = salesInvoiceDetails.find(
      (invoice: any) => invoice.id === selectedInvoiceId,
    )
  const invoiceLines = selectedInvoice?.invoiceLines
  const supplier = selectedInvoice?.supplier_info[0]
  const customer = selectedInvoice?.customer_info[0]

  const columns = [
    { field: 'productName', headerName: 'Mal Hizmet', width: 500 },
    { field: 'quantity', headerName: 'Miktar', width: 200 },
    { field: 'lineSubtotal', headerName: 'Birim Fiyat', width: 150 },
    { field: 'lineTaxPercentage', headerName: 'KDV Oranı', width: 150 },
    { field: 'lineTaxTotal', headerName: 'KDV Tutarı', width: 150 },
    {
      field: 'lineTotal',
      headerName: 'Mal Hizmet Tutarı',
      width: 200,
    },
  ]

  const formatAddress = (address: any) => {
    if (!address) return null

    const parts = [
      address.streetName,
      address.citySubdivisionName,
      address.cityName,
      address.postalZone,
      address.countryName,
    ]

    return parts.filter((part) => part).join(', ')
  }

  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="xl">
      <DialogTitle>Fatura Detayları</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Title title="Tedarikçi Detayları" />

            <InvoiceDetailItem
              label="Tedarikçi Adı"
              value={supplier?.companyPartyName}
            />
            <InvoiceDetailItem label="VKN" value={supplier?.companySchemeId} />
            <InvoiceDetailItem label="Telefon" value={supplier?.companyPhone} />
            <InvoiceDetailItem label="E-Posta" value={supplier?.companyEmail} />
            <InvoiceDetailItem
              label="Tedarikçi Adresi"
              value={formatAddress(supplier?.companyAddress)}
            />
          </Grid>

          <Grid item xs={4}>
            <Title title="Fatura Bilgileri" />

            <InvoiceDetailItem
              label="Fatura No"
              value={selectedInvoice?.edm_id}
            />
            <InvoiceDetailItem
              label="Fatura Tarihi"
              value={selectedInvoice?.issue_date}
            />
            <InvoiceDetailItem
              label="Senaryo"
              value={selectedInvoice?.profileid}
            />
            <InvoiceDetailItem
              label="Fatura Tipi"
              value={selectedInvoice?.invoice_type}
            />
          </Grid>

          <Grid item xs={12}>
            <Title title="Müşteri Detayları" />

            <InvoiceDetailItem
              label="Müşteri Adı"
              value={customer?.companyPartyName}
            />
            <InvoiceDetailItem label="VKN" value={customer?.companySchemeId} />
            <InvoiceDetailItem label="Telefon" value={customer?.companyPhone} />
            <InvoiceDetailItem label="E-Posta" value={customer?.companyEmail} />
            <InvoiceDetailItem
              label="Müşteri Adresi"
              value={formatAddress(customer?.companyAddress)}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: 400,
            width: '100%',
            marginTop: '15px',
            marginBottom: '25px',
          }}
        >
          <DataGrid
            rows={invoiceLines}
            columns={columns}
            getRowId={(row) => row.quantity}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              boxShadow: 1,
            }}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Kapat</Button>
      </DialogActions>
    </Dialog>
  )
}
