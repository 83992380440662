import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import { api } from './api'
import { rtkQueryErrorLogger } from '../middlewares/errorDisplay'
import errorSlice from '../features/errorSlice'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    error: errorSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch