import { Autocomplete, TextField } from '@mui/material'
import { ChangeEvent, forwardRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface AutoCompleteProps {
  options: any[]
  value?: string
  onInput: (input: string) => void
  label?: string
  helperText?: string
  error?: boolean
  defaultValue?: string
  register?: UseFormRegisterReturn
}

const CustomAutoComplete = forwardRef<HTMLInputElement, AutoCompleteProps>(({
  options,
  value,
  onInput,
  label = '',
  helperText,
  error,
  defaultValue,
}, ref) => {
  const handleInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onInput(event.target.value)
  }

  return (
    <Autocomplete
      value={value ?? null}
      onChange={(e, newValue) => onInput(newValue)}
      freeSolo
      fullWidth
      getOptionLabel={(option) => option.toString()}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          label={label}
          onChange={handleInput}
          helperText={helperText}
          error={error}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'primary.main', // Theme primary color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'primary.dark', // Darker shade when focused
              },
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: 'primary.main', // Label color when focused
            },
          }}
        />
      )}
      sx={{ marginTop: 2 }}
    />
  )
});

export default CustomAutoComplete;
