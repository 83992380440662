import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Stack } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'

interface TransactionDatePickerProps {
  open: boolean
  onClose: () => void
  startDate: Dayjs
  endDate: Dayjs
  onStartDateInput: (date: Dayjs) => void
  onEndDateInput: (date: Dayjs) => void
  onSubmit: () => void
}

export default function TransactionDatePicker({
  open,
  onClose,
  startDate,
  endDate,
  onStartDateInput,
  onEndDateInput,
  onSubmit,
}: TransactionDatePickerProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Tarih Aralığı Seçin</DialogTitle>
      <DialogContent>
        <div style={{ width: '400px', padding: '0px' }}>
          <Stack spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate}
                label="Start date"
                maxDate={endDate ? endDate : undefined}
                onChange={(date) => {
                  date && onStartDateInput(date)
                }}
                format="YYYY-MM-DD"
              />
              <DatePicker
                value={endDate}
                label="End date"
                minDate={startDate ? startDate : undefined}
                onChange={(date) => {
                  date && onEndDateInput(date)
                }}
                format="YYYY-MM-DD"
              />
            </LocalizationProvider>
          </Stack>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Kapat
        </Button>
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={onSubmit}
        >
          Uygula
        </Button>
      </DialogActions>
    </Dialog>
  )
}
