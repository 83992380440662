import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './api/store'
import 'react-toastify/dist/ReactToastify.css'
import { enableMocking } from './mocks/enableMock'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>                                       
    </Provider>,
  )
})
