import React, { ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'

interface DialogInputProps {
  open: boolean
  onClose: () => void
  title: string
  content: ReactNode
  onCancelText?: string
  onConfirmText?: string
  onConfirm?: () => void
  noDefaultButtons?: boolean
  maxWidth?: number
}

const DialogInput: React.FC<DialogInputProps> = (props) => {
  const {
    open,
    onClose,
    title,
    content,
    onCancelText = 'Cancel',
    onConfirmText = 'Confirm',
    onConfirm,
    noDefaultButtons,
  } = props

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle variant="h5" fontWeight="bold">
        {title}
      </DialogTitle>
      <DialogContent content="500px">{content}</DialogContent>
      {!noDefaultButtons && (
        <DialogActions>
          <Button onClick={onClose}>{onCancelText}</Button>
          <Button onClick={onConfirm}>{onConfirmText}</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default DialogInput
