import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ILogin } from '../../types/customTypes'
import { useForm } from 'react-hook-form'
import { useLoginMutation } from '../../api/authenticationApi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Checkbox, FormControlLabel } from '@mui/material'
import usePersist from '../../hooks/usePersist'
import CenteredContainer from '../../components/CenteredContainer'
import styled from 'styled-components'

const Brand = styled.div`
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 5px;
`

const BrandImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px; /* Adjust margin for spacing */
`

const LoginHeader = styled.h4`
  color: #1a4566;
  margin: 0; /* Remove default margin */
`
const CenteredBrand = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure full height */
`

export default function LogIn() {
  const { register, handleSubmit, formState } = useForm<ILogin>()
  const { errors } = formState

  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [login, { isSuccess, isLoading, isError, error, data }] =
    useLoginMutation()

  const onSubmit = (loginDetails: ILogin) => {
    console.log(loginDetails)

    // handleLogin(loginDetails.username, loginDetails.password)
    login(loginDetails)
  }

  const closeAlert = () => {
    setErrorMessage('')
  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/')
      if (data) {
        sessionStorage.setItem('sessionId', data?.session_id)
      }

      console.log('auth data', data)

      setErrorMessage('')
      sessionStorage.setItem('isLoggedIn', 'true')
    } else if (isError) {
      console.log('Is error')

      let errMsg: string
      if (error) {
        console.log(error)
        const errData: any = 'data' in error && error.data
        errMsg = errData.message && errData.message
      } else {
        errMsg = 'Login Failed. Try again'
      }
      setErrorMessage(errMsg)
    }
  }, [isSuccess, error, isError])

  const handleLogin = (username: string, password: string) => {
    // Simulate authentication with credentials from .env file
    if (
      username === process.env.REACT_APP_USERNAME &&
      password === process.env.REACT_APP_PASSWORD
    ) {
      setIsLoggedIn(true)
      sessionStorage.setItem('isLoggedIn', 'true') // Set login status in sessionStorage
    } else {
      setErrorMessage('Invalid username or password')
    }
  }

  useEffect(() => {
    const loggedInStatus = sessionStorage.getItem('isLoggedIn')
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true)
    }
  }, [])

  useEffect(() => {
    const handleWindowClose = () => {
      sessionStorage.removeItem('isLoggedIn') // Clear login status on tab close
    }

    window.addEventListener('beforeunload', handleWindowClose)

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  }, [])

  return (
    <>
      <div className="login-body">
        <div className="login">
          <h4>
            <CenteredBrand>
              <Brand>
                <LoginHeader>Giriş</LoginHeader>

                <BrandImage
                  src={
                    'https://www.fincloudy.com/wp-content/uploads/2024/01/fincloudy-logo.svg'
                  }
                />
              </Brand>
            </CenteredBrand>
          </h4>

          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
              <TextField
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                margin="normal"
                id="username"
                label="Kullanıcı Adı"
                autoFocus
                {...register('username', {
                  required: 'Please enter username',
                })}
                error={!!errors.username}
                helperText={errors.username?.message}
                className="text_area"
              />
              <TextField
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                margin="normal"
                fullWidth
                label="Şifre"
                type="password"
                id="password"
                {...register('password', {
                  required: 'Please enter password',
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
                className="text_area"
              />

              {errorMessage && (
                <Alert
                  severity="error"
                  onClose={closeAlert}
                  sx={{ width: '100%', mt: 2 }}
                >
                  {errorMessage}
                </Alert>
              )}
              <input
                type="submit"
                value="Giriş"
                className="btn"
                disabled={isLoading}
              />
            </form>
          </Box>

          {/* <form>
          <div className="text_area">
            <input
              type="text"
              id="username"
              name="username"
              defaultValue="username"
              className="text_input"

            />
          </div>
          <div className="text_area">
            <input
              type="password"
              id="password"
              name="password"
              defaultValue="password"
              className="text_input"

            />
          </div>
          <input
            type="submit"
            value="LOGIN"
            className="btn"

          />
        </form> */}
        </div>
      </div>
    </>
  )
}
