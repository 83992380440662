import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import StepperRenderer from '../../../components/StepContentRenderer'
import Step1 from './Step_1'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import OdemeStep_1 from '../../../components/odeme/BireyselStep'
import Hesab from '../../../components/odeme/Hesab'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import Iban from '../../../components/odeme/Iban'
import { useEffect, useState } from 'react'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { IOdemeHesap } from '../../../types/odemeTypes'
import useFormPersist from 'react-hook-form-persist'
import { FormProvider, useForm } from 'react-hook-form'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { odemeDefaultValues } from '../../../types/formDefaultValues'

const karekodBireyselDefaultValue = {
  ...odemeDefaultValues,

  odmBsltm: {
    ...odemeDefaultValues.odmBsltm,
    islTtr: {
      ...odemeDefaultValues.odmBsltm.islTtr,
      ttr: '1',
    },
    kmlk: {
      ...odemeDefaultValues.odmBsltm.kmlk,
      kmlkVrs: '49747871412',
    },
    gon: {
      hspNo: 'TR190412804272929722664557',
      unv: 'h4128 u49747871412',
    },
    kkod: {
      aksTur: '02',
      kkodRef: '12345678901',
      kkodUrtcKod: '0800',
    },
    odmAyr: {
      odmAcklm: 'Karekod statik',
      odmAmc: '04',
      odmKynk: 'O',
      refBlg: '0111TLK01230405',
    },
  },
  isyOdmBlg: {
    isyKtgKod: '5661',
    altIsyKtgKod: '5661',
    genelUyeIsyeriNo: '01630618',
  },
}

export const components = [
  {
    index: 0,
    label: 'Ödeme Hizmeti Kullanıcısı Türü Seçimi',
    component: <Iban />,
  },
  {
    index: 1,
    label: 'Karekod-FAST Statik: Bireysel',
    component: <Step1 />,
  },
  {
    index: 2,
    label: 'Ödeme Adım 3',
    component: <OdemeStep_2 />,
  },
  {
    index: 3,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 4,
    label: 'Senaryo Tipi Seç',
    component: <Scenarios />,
  },
]
function KarekodBireysel() {
  const [odemeHesap, { isSuccess, isLoading, isError, data, error }] =
    useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)

  const karekodBireyselFormValues = 'karekodBireysel'

  const [defaultValues] = useState<any>(() => {
    const storedValues = window.sessionStorage.getItem(
      karekodBireyselFormValues,
    )
    if (storedValues) {
      // Parse the stored values
      const parsedValues: any = JSON.parse(storedValues)
      return parsedValues
    } else {
      return karekodBireyselDefaultValue
    }
  })

  const formMethods = useForm<any>({
    defaultValues: defaultValues,
    // resolver: zodResolver(hesaplerSchema),
  })

  const { watch, setValue, getValues, unregister } = formMethods

  useFormPersist(karekodBireyselFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const handleSubmitButtonClick = (data: any) => {
    unregister('odmBsltm.odmAyr.refBlg')
    const scenarioType = getValues('scenarioType')
    odemeHesap({ formData: data, scenarioType })
  }

  useEffect(() => {
    unregister('odmBsltm.odmAyr.refBlg')
  }, [])

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    } else if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepperRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default KarekodBireysel
