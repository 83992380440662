import { Grid, InputLabel } from '@mui/material'
import HesabBireyselComponent from './BireyselStep'
import AutoComplete from '../CustomAutoComplete'
import { useFormContext } from 'react-hook-form'
import { IOdemeHesap } from '../../types/odemeTypes'

const tckn = [
  '77121323400'
]

const KurmusalComponent = () => {

  const {setValue, watch} = useFormContext<IOdemeHesap>()

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <InputLabel>TCKN Kimlik Verisi</InputLabel>
        <AutoComplete
          onInput={(value) => setValue('odmBsltm.kmlk.kmlkVrs', value)}
          options={tckn}
          value={watch('odmBsltm.kmlk.kmlkVrs')}
        />
      </Grid> 
    </Grid>
  )
}

export const Iban = () => {
  return <HesabBireyselComponent Kurmusal={<KurmusalComponent />} />
}

export default Iban
