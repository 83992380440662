import { Box } from '@mui/material'
import styled from 'styled-components'

const Card = styled(Box)`
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 24px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Card
