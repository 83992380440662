import Card from './Card'
import { useState } from 'react'
import HesapDatePickerComponent from './HesapDatePicker'
import {
  Checkbox,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { StepperContainer } from './StepperContainer'
import { useFormContext } from 'react-hook-form'
import { Ihesapler } from '../types/customTypes'
import { IzinTuru } from '../enum/hesapEnums'
import { izinTuruEnumMap } from '../types/enumMap'

// const izinTuruEnumMap = [
//   { name: 'Temel Hesap Bilgisi', value: IzinTuru.TemelHesapBilgisi },
//   { name: 'Ayrıntılı Hesap Bilgisi', value: IzinTuru.AyrintiliHesapBilgisi },
//   { name: 'Bakiye Bilgisi', value: IzinTuru.BakiyeBilgisi },
//   { name: 'Temel İşlem', value: IzinTuru.TemelIslem },
//   { name: 'Ayrıntılı İşlem Bİlgisi', value: IzinTuru.AyritiliIslemBilgisi },
//   { name: 'Anlık Bakiye Bildirimi', value: IzinTuru.AnlikBakiyeBildirimi },
// ]

function HesapBilgisi() {
  const { setValue, watch } = useFormContext<Ihesapler>()
  // const newDate = (dayjs(watch('hspBlg.iznBlg.erisimIzniSonTrh')))
  const iznTurValue = watch('hspBlg.iznBlg.iznTur')

  const [selected, setSelected] = useState(iznTurValue)

  const is04Or05Selected =
    iznTurValue.includes(IzinTuru.TemelIslem) ||
    iznTurValue.includes(IzinTuru.AyritiliIslemBilgisi)

  const dateSelection = [
    {
      label: 'Erişimin Geçerli Olduğu Son Tarih',
      value: dayjs(watch('hspBlg.iznBlg.erisimIzniSonTrh')),
      isDisabled: false,
      onChange: (date: Dayjs) => {
        setValue('hspBlg.iznBlg.erisimIzniSonTrh', date)
      },
    },
    {
      label: 'Işlem Sorgulama Başlangiç Zamani',
      value: dayjs(watch('hspBlg.iznBlg.hesapIslemBslZmn')),
      isDisabled: !is04Or05Selected,
      onChange: (date: Dayjs) => {
        setValue('hspBlg.iznBlg.hesapIslemBslZmn', date)
      },
    },
    {
      label: 'Işlem Sorgulama Bitiş Zamani',
      value: dayjs(watch('hspBlg.iznBlg.hesapIslemBtsZmn')),
      isDisabled: !is04Or05Selected,
      onChange: (date: Dayjs) => {
        setValue('hspBlg.iznBlg.hesapIslemBtsZmn', date)
      },
    },
  ]

  const handleSelectionChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event

    setSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )

    setValue('hspBlg.iznBlg.iznTur', Array.isArray(value) ? value : [value])
  }

  return (
    <StepperContainer>
      <Card width="90%" p={5} pl={10} alignItems="center" height="200px">
        <Grid container alignContent="center" justifyContent="center">
          <Grid item md={3} sm={12}>
            <InputLabel>
              {' '}
              <Typography variant="h6" fontWeight="bold">
                İzin Türü
              </Typography>
            </InputLabel>
            <Select
              multiple
              renderValue={(selected: any) => {
                const selectedNames = selected.map((value: string) => {
                  const selectedOption = izinTuruEnumMap.find(
                    (option) => option.value === value,
                  )
                  return selectedOption ? selectedOption.name : ''
                })
                return selectedNames.join(', ')
              }}
              value={iznTurValue}
              onChange={handleSelectionChange}
              sx={{ width: '90%' }}
            >
              {izinTuruEnumMap.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={selected.indexOf(option.value) > -1} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {dateSelection.map(
            ({ value, label, onChange, isDisabled }, index) => (
              <Grid key={index} item md={3} sm={12}>
                <HesapDatePickerComponent
                  isDisabled={isDisabled}
                  label={label}
                  handleDateInput={onChange}
                  value={value}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Card>
    </StepperContainer>
  )
}

export default HesapBilgisi
