import { Box } from '@mui/system'
import BireyselStepper from './BireyselStepper'
import BireyselProvider from './BireyselStepperProvider'
import { Divider, TextField } from '@mui/material'
import StepperCard from '../../../components/Card'
import { NextButton } from '../../../components/StepButtons'

function Bireysel() {
  const onSorgulaClick = () => {}

  return (
    <Box
      height="100%"
      // sx={{
      //   backgroundImage: `url(${backgroundPattern})`,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      // }}
    >
      <BireyselProvider>
        <BireyselStepper />
      </BireyselProvider>
      <Divider sx={{ marginTop: 5 }} />
      <Box>
        <Box width={500} margin="auto" mt={10}>
          <StepperCard>
            <TextField label="Rıza numarası" />
            <NextButton
              handleNextClick={onSorgulaClick}
              isDisabled={false}
              buttonLabel="Sorgula"
            />
          </StepperCard>
        </Box>
      </Box>
    </Box>
  )
}

export default Bireysel
