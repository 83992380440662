import StepContentRenderer from '../../../components/StepContentRenderer'
import { kurumsalComponents } from './kurumsalComponentList'
import { useFormContext } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { clearStorage } from '../../../utils/formUtils'
import { kurumsalFormValues } from './KurumsalStepperProvider'
import { useConnectHesapMutation } from '../../../api/hesaplarApi'
import RedirectPrompt from '../../../components/RedirectPrompt'
import ErrorSnackbar from '../../../components/ErrorSnackbar'

function HesapKurumsalStepper() {
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)

  const {
    formState: { errors },
    getValues,
  } = useFormContext()
  const [showErrorAlert, setshowErrorAlert] = useState(false)

  const [connectKurumsal, { isSuccess, isLoading, isError, error, data }] =
    useConnectHesapMutation()
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = (data: any) => {
    let formData = data

    const izinTurArray = formData.hspBlg.iznBlg.iznTur

    const is04Or05Included =
      izinTurArray.includes('04') || izinTurArray.includes('05')

    if (!is04Or05Included) {
      delete formData.hspBlg.iznBlg.hesapIslemBslZmn
      delete formData.hspBlg.iznBlg.hesapIslemBtsZmn
    }
    formData.gkd.yetYntm = 'A'
    formData.gkd.ayrikGkd = {
      ohkTanimTip: 'TCKN',
      ohkTanimDeger: formData.kmlk.kmlkVrs,
    }

    const scenarioType = getValues('scenario')
    connectKurumsal({ formData, scenarioType })
    clearStorage(kurumsalFormValues)
  }

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    }
    if (isError && error) {
      console.log(error)
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 
         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <>
      {showErrorAlert && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepContentRenderer
        components={kurumsalComponents}
        onSubmit={onSubmit}
      />
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </>
  )
}

export default HesapKurumsalStepper
