import { Button, Typography } from '@mui/material'
import DialogInput from './Dialog'
import { Box } from '@mui/system'
import { GridCheckCircleIcon } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'

interface SuccessDialogProps {
  openDialog: boolean
}

function SuccessfulCreationDialog({ openDialog }: SuccessDialogProps) {
  return (
    <DialogInput
      title=""
      open={openDialog}
      onClose={() => void 0}
      content={
        <Box textAlign="center">
          <GridCheckCircleIcon sx={{ fontSize: '100px' }} color="success" />
          <Typography variant="h4" gutterBottom mb={3}>
            Succesfully Linked Bank Account
          </Typography>
          <Link to="/">
            <Button variant="contained">
              <Typography variant="body1">Go to homepage</Typography>
            </Button>
          </Link>
        </Box>
      }
      noDefaultButtons
    />
  )
}

export default SuccessfulCreationDialog
