import React, { useState, ChangeEvent } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material'

interface PaymentAccountDialogProps {
  open: boolean
  onClose: () => void
}

const PaymentAccountDialog: React.FC<PaymentAccountDialogProps> = ({
  open,
  onClose,
}) => {
  const [paymentType, setPaymentType] = useState<string>('')
  const [kolasOption, setKolasOption] = useState<string>('')
  const [fieldValue, setFieldValue] = useState<string>('')

  const handlePaymentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value)
    setFieldValue('')
    setKolasOption('')
  }

  const handleKolasOptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setKolasOption(event.target.value as string)
  }

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value)
  }

  const handleSubmit = () => {
    // Form submit işlemleri burada yapılabilir
    console.log({ paymentType, kolasOption, fieldValue })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tedarikçiniz için ödeme hesabı ekleyin</DialogTitle>
      <DialogContent>
        <RadioGroup value={paymentType} onChange={handlePaymentTypeChange}>
          <FormControlLabel value="IBAN" control={<Radio />} label="IBAN" />
          <FormControlLabel value="KOLAS" control={<Radio />} label="KOLAS" />
        </RadioGroup>

        {/* {paymentType === 'KOLAS' && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="kolas-select-label">Seçenek</InputLabel>
            <Select
              labelId="kolas-select-label"
              value={kolasOption}
              onChange={handleKolasOptionChange}
            >
              <MenuItem value="telefon">Telefon</MenuItem>
              <MenuItem value="gsm">GSM</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
        )} */}

        {(paymentType === 'IBAN' || kolasOption) && (
          <TextField
            fullWidth
            margin="normal"
            label={paymentType === 'IBAN' ? 'IBAN' : kolasOption}
            value={fieldValue}
            onChange={handleFieldChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          İptal
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentAccountDialog
