import { ReactNode, createContext, useContext, useState } from 'react'
import {
  FormProvider,
  UseFormReturn,
} from 'react-hook-form'

interface StepContextType {
  activeStep: number
  handleNextClick: () => void
  handleBackClick: () => void
}

interface StepContextProps {
  children: ReactNode
  formMethods: UseFormReturn<any>
}

const StepContext = createContext<StepContextType | undefined>(undefined)

export const useStepperControlContext = () => {
  const context = useContext(StepContext)
  if (!context) {
    throw new Error('useStepContext must be used within a StepProvider')
  }
  return context
}

function StepContextProvider({ children, formMethods }: StepContextProps) {
  const [activeStep, setActiveStep] = useState(0)

  const handleNextClick = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBackClick = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const contextValue: StepContextType = {
    activeStep,
    handleNextClick,
    handleBackClick,
  }

  return (
    <StepContext.Provider value={contextValue}>
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StepContext.Provider>
  )
}

export default StepContextProvider
