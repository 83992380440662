import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

interface CommunicationDialogProps {
  open: boolean
  onClose: () => void
  onSend: (method: string, contact: string, message: string) => void
  invoiceLink: string
  invoiceNumber: string
  amount: number
  dueDate: string
  setMessage: Dispatch<SetStateAction<string>>
  message: string
}

const CommunicationDialog: React.FC<CommunicationDialogProps> = ({
  open,
  onClose,
  onSend,
  invoiceLink,
  invoiceNumber,
  amount,
  dueDate,
  setMessage,
  message,
}) => {
  const [method, setMethod] = useState<string>('email')
  const [contact, setContact] = useState<string>('')

  useEffect(() => {
    setMessage(
      `Merhaba,\n\nTRY ${amount} tutarındaki faturanız. \n\nFaturanızı çevrimiçi görüntüleyin:\n\n${invoiceLink}`,
    )
  }, [invoiceLink, invoiceNumber, amount, dueDate])

  const handleSend = () => {
    onSend(method, contact, message)
    onClose()
  }

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phoneNumber = e.target.value.trim()

    if (method === 'tel') {
      // Automatically prepend +90 to the phone number if it doesn't start with +
      if (phoneNumber && !phoneNumber.startsWith('+')) {
        phoneNumber = '+90' + phoneNumber
      }
    }

    setContact(phoneNumber)
  }
  const handleMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMethod(e.target.value)
    setContact('')
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>İletişim Yöntemi Seç</DialogTitle>
      <DialogContent>
        <RadioGroup value={method} onChange={(e) => handleMethodChange(e)}>
          <FormControlLabel value="email" control={<Radio />} label="Email" />
          <FormControlLabel
            value="whatsapp"
            control={<Radio />}
            label="WhatsApp"
          />
        </RadioGroup>
        <TextField
          autoFocus
          margin="dense"
          label={method === 'email' ? 'Email Adresi' : 'Telefon Numarası'}
          type={method === 'email' ? 'email' : 'tel'}
          fullWidth
          value={contact}
          onChange={handleContactChange}
        />
        <TextField
          margin="dense"
          label="Mesaj İçeriği"
          type="text"
          fullWidth
          multiline
          rows={8}
          value={message}
          defaultValue={'+90'}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleSend} color="primary">
          Gönder
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CommunicationDialog
