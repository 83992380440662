import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { useLazyGetLinkedAccountsQuery } from '../../api/paymentApi'
import { useEffect, useState } from 'react'
import {
  useLazyGetBakiyeByRefIdQuery,
  useLazyGetTransactionQuery,
} from '../../api/hesaplarApi'
import { Box } from '@mui/system'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { BankDetailsCard } from '../demoPage/hesap'
import { LineChart, lineElementClasses } from '@mui/x-charts'
import { DateRangeIcon } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import TransactionDataGrid from '../../components/TansactionDataGrid'
import TransactionDatePicker from '../../components/TransactionDatePicker'
import { formatAmount } from '../../utils/formatter'
import theme from '../../theme/theme'
import Paper from '../../components/Paper'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import FullWidthSkeleton from '../../components/FullWidthSkeleton'
import { useIsMobile, useIsTabletOrMobile } from '../../hooks/mediaQuery'
import MediaQuery from 'react-responsive'

export default function Dashboard() {
  const [balances, setBalances] = useState<Record<string, number>>({})
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRiza, setSelectedRiza] = useState('')
  const [selectedHesap, setSelectedHesap] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false)
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'))
  const [endDate, setEndDate] = useState<Dayjs>(dayjs())
  const [transactions, setTransactions] = useState<any[]>([])
  const [accounts, setAccounts] = useState<Record<string, any[]>>({})
  const [totalBalance, setTotalBalance] = useState(0)
  const [popoverAccount, setPopoverAccount] = useState<any | null>(null)
  const [selectedBankName, setSelectedBankName] = useState('')

  const isMobileOrTablet = useIsTabletOrMobile()
  const isMobile = useIsMobile()

  const uData = [
    4000, 3000, 2000, 2780, 1890, 2390, 3490, 2000, 1100, 4000, 2500, 5000,
  ]
  const xLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const isMoreDetailsPopUpOpen = Boolean(anchorEl)
  const accountDetailsPopoverId = isMoreDetailsPopUpOpen
    ? 'simple-popover'
    : undefined

  const [
    getLinkedAccounts,
    {
      isSuccess: isBankAccountFetchSuccess,
      isLoading: isBankAccountLoading,
      isError: isBankAccountLoadingError,
      data: linkedAccounts,
    },
  ] = useLazyGetLinkedAccountsQuery()

  const [getBakiye] = useLazyGetBakiyeByRefIdQuery()

  useEffect(() => {
    console.log(linkedAccounts)

    if (linkedAccounts) {
      let allAccounts: { [key: string]: any[] } = {}

      linkedAccounts.map((linkedAccount: any) => {
        const bankName = linkedAccount.bankName
        linkedAccount?.accounts?.forEach((account: any) => {
          // const bankName = account?.bankName
          if (!allAccounts[bankName]) {
            allAccounts[bankName] = []
          }
          allAccounts[bankName].push(account)

          const hspRef = account?.hspTml?.hspRef
          const rizaNo = account?.rizaNo

          getBakiye({ refId: hspRef, rizaNo: rizaNo })
            .unwrap()
            .then((bakiye) => {
              const { hspRef, bky } = bakiye
              const { krdHsp } = bky
              const balanceAmount = parseFloat(bky?.bkyTtr)

              setBalances((prevBalances) => ({
                ...prevBalances,
                [hspRef]:
                  krdHsp.krdDhlGstr === '1'
                    ? balanceAmount + parseFloat(krdHsp?.kulKrdTtr)
                    : balanceAmount,
              }))
            })
            .catch((err) => {
              console.log(err)
              setErrorMessage(err)
            })
        })
      })

      // When bank accounts load, set a default default account details
      // in order to fetch transactions instead of waiting for user to select an account
      // This will prevent an empty transaction screen on load
      const initialAccount = linkedAccounts[0]?.accounts[0]
      const hspRef = initialAccount?.hspTml?.hspRef
      const rizaNo = initialAccount?.rizaNo
      setSelectedHesap(hspRef)
      setSelectedRiza(rizaNo)

      setAccounts(allAccounts)
    }
  }, [linkedAccounts])

  useEffect(() => {
    getLinkedAccounts()
  }, [])

  const fetchTransaction = (srlmYon = '') => {
    const start = startDate.format('YYYY-MM-DD')
    const end = endDate.format('YYYY-MM-DD')
    if (selectedHesap && selectedRiza) {
      getTransactions({
        rizaNo: selectedRiza,
        hesapRef: selectedHesap,
        hesapIslemBslTrh: start,
        hesapIslemBtsTrh: end,
        srlmYon,
      })
        .unwrap()
        .then((transactions) => {
          console.log('Returned Transaction', transactions)

          if (transactions.length !== 0) {
            setTransactions(transactions?.isller)
          } else {
            setTransactions([])
          }
        })
        .catch((err: any) => {
          setErrorMessage(err)
        })
    }
  }

  const [getTransactions] = useLazyGetTransactionQuery()

  const handleApplyDates = () => {
    fetchTransaction()
    setIsDateRangePickerOpen(false)
    setStartDate(dayjs().subtract(7, 'day'))
    setEndDate(dayjs())
  }

  useEffect(() => {
    const totalBalance = Object.values(balances).reduce(
      (sum, balance) => sum + balance,
      0,
    )
    setTotalBalance(totalBalance)
  }, [balances])

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    account: any,
  ) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setPopoverAccount(account)
  }

  useEffect(() => {
    fetchTransaction()
  }, [selectedHesap, selectedRiza])

  const handleHesapClick = (hspNo: string, rizaNo: string) => {
    setSelectedRiza(rizaNo)
    if (selectedHesap === hspNo) {
      setSelectedHesap('')
    } else {
      setSelectedHesap(hspNo)
    }
  }

  return (
    <>
      <MediaQuery minWidth={1224}>
        <Grid container spacing={2} height="85vh">
          <Grid item xs={4.5} minHeight="100%">
            <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
              <>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Hesaplar
                </Typography>
                {isBankAccountLoading ? (
                  <FullWidthSkeleton />
                ) : (
                  <List>
                    {Object.entries(accounts).map(
                      ([bankName, bankAccounts]) => (
                        <Accordion key={bankName} sx={{ marginBottom: 1 }}>
                          <AccordionSummary
                            expandIcon={<GridExpandMoreIcon />}
                            aria-controls={`${bankName}-content`}
                            id={`${bankName}-header`}
                            sx={{ minHeight: 'unset' }}
                          >
                            <Box display="flex" alignItems="center">
                              <AccountBalanceIcon color="primary" />
                              <Typography variant="h6" ml={1}>
                                {bankName}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {bankAccounts.map((account: any) => {
                              const hspRef = account?.hspTml?.hspRef
                              const rizaNo = account?.rizaNo
                              const balance = balances[hspRef]
                              const trauncatedhspRef = hspRef.substring(0, 30)

                              return (
                                <ListItem
                                  key={hspRef}
                                  disablePadding
                                  sx={{ padding: 0, ml: 1.5 }}
                                >
                                  <ListItemButton
                                    onClick={() =>
                                      handleHesapClick(hspRef, rizaNo)
                                    }
                                    sx={{
                                      backgroundColor:
                                        hspRef === selectedHesap
                                          ? theme.palette.secondary.light
                                          : 'transparent',
                                      '&:hover': {
                                        backgroundColor:
                                          hspRef !== selectedHesap
                                            ? theme.palette.action.hover
                                            : theme.palette.secondary.light,
                                      },
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      width="100%"
                                      justifyContent="space-between"
                                    >
                                      <Box display="flex" alignItems="center">
                                        <CreditCardIcon color="action" />
                                        <ListItemText
                                          primary={
                                            <Typography
                                              fontWeight="bold"
                                              ml={1}
                                            >
                                              {trauncatedhspRef}
                                            </Typography>
                                          }
                                        />
                                      </Box>
                                      <Box display="flex" alignItems="center">
                                        <Typography ml={1}>
                                          ₺{formatAmount(balance)}
                                        </Typography>
                                        <Button
                                          sx={{ ml: 1 }}
                                          onClick={(event) => {
                                            event.stopPropagation() // Prevents the button click from triggering the ListItemButton click
                                            handlePopoverOpen(event, account)
                                          }}
                                        >
                                          <GridExpandMoreIcon
                                            sx={{ transform: 'rotate(-90deg)' }}
                                          />
                                        </Button>
                                      </Box>
                                    </Box>
                                  </ListItemButton>
                                </ListItem>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      ),
                    )}
                  </List>
                )}

                <Popover
                  id={accountDetailsPopoverId}
                  open={isMoreDetailsPopUpOpen}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ p: 1 }}>
                    <BankDetailsCard
                      account={popoverAccount}
                      balances={balances}
                    />
                  </Box>
                </Popover>
              </>
            </Paper>
          </Grid>
          <Grid item xs={7.5} height="100%">
            <Paper sx={{ height: 90, marginBottom: 2 }}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {isMobile ? 'Toplam' : 'Banka Hesaplarımın Toplamı:'}
              </Typography>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                {formatAmount(Number(totalBalance))}TL
              </Typography>
            </Paper>
            <Paper sx={{ width: '100%', height: 'calc(100% - 92px)' }}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                İşlemler
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              <Grid container width="100%">
                <Grid item xs={12} width="100%">
                  <Box width="100%" overflow="auto">
                    <LineChart
                      width={isMobileOrTablet ? 350 : 850}
                      height={300}
                      series={[
                        {
                          data: uData,
                          // label: 'uv',
                          area: true,
                          showMark: false,
                          // color: '#ff5722',
                        },
                      ]}
                      xAxis={[{ scaleType: 'point', data: xLabels }]}
                      sx={{
                        [`& .${lineElementClasses.root}`]: {
                          display: 'none',
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} mb={2}>
                  <Button
                    color="secondary"
                    variant={'outlined'}
                    startIcon={<DateRangeIcon />}
                    onClick={() => setIsDateRangePickerOpen(true)}
                    style={{
                      textTransform: 'none',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                    disabled={
                      selectedRiza === '' ||
                      selectedHesap === '' ||
                      selectedRiza === undefined ||
                      selectedHesap === undefined
                    }
                  >
                    İşlem Bilgileri
                  </Button>
                </Grid>
                <Grid item xs={12} width="100%" height="100%">
                  <TransactionDataGrid
                    transactions={transactions}
                    sx={{ height: '100%' }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <TransactionDatePicker
            open={isDateRangePickerOpen}
            onClose={() => setIsDateRangePickerOpen(false)}
            startDate={startDate}
            endDate={endDate}
            onStartDateInput={(date) => setStartDate(date)}
            onEndDateInput={(date) => setEndDate(date)}
            onSubmit={handleApplyDates}
          />
        </Grid>
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <Grid container>
          <Grid item xs={12}>
            <Paper sx={{ height: 90, marginBottom: 2 }}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {'Banka Toplam'}
              </Typography>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                {formatAmount(Number(totalBalance))}TL
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Bankalar</Typography>
            <Select
              fullWidth
              onChange={(e) => setSelectedBankName(e.target.value as string)}
            >
              {Object.entries(accounts).map(([bankName]) => (
                <MenuItem key={bankName} value={bankName}>
                  {bankName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box width="100%" overflow="auto">
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Hesaplar
              </Typography>
              <Paper elevation={3} sx={{ padding: 1, height: '70px' }}>
                <>
                  {isBankAccountLoading ? (
                    <FullWidthSkeleton />
                  ) : (
                    <List
                      sx={{ display: 'flex', width: '100%', overflow: 'auto' }}
                    >
                      {Object.entries(accounts).map(
                        ([bankName, bankAccounts]) => {
                          if (bankName === selectedBankName) {
                            return (
                              <>
                                <Box>
                                  {bankAccounts.map((account: any) => {
                                    const hspRef = account?.hspTml?.hspRef
                                    const rizaNo = account?.rizaNo
                                    const balance = balances[hspRef]
                                    const truncatedhsp =
                                      hspRef?.length > 10
                                        ? `${hspRef.substring(0, 10)}`
                                        : hspRef
                                    return (
                                      <ListItem
                                        key={hspRef}
                                        disablePadding
                                        sx={{
                                          padding: 0,
                                          ml: 1.5,
                                          display: 'flex',
                                        }}
                                      >
                                        <ListItemButton
                                          onClick={() =>
                                            handleHesapClick(hspRef, rizaNo)
                                          }
                                          sx={{
                                            backgroundColor:
                                              hspRef === selectedHesap
                                                ? theme.palette.secondary.light
                                                : 'transparent',
                                            '&:hover': {
                                              backgroundColor:
                                                hspRef !== selectedHesap
                                                  ? theme.palette.action.hover
                                                  : theme.palette.secondary
                                                      .light,
                                            },
                                          }}
                                        >
                                          <Box
                                            display="flex"
                                            width="100%"
                                            justifyContent="space-between"
                                          >
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <CreditCardIcon color="action" />
                                              <ListItemText
                                                primary={
                                                  <Typography
                                                    fontWeight="bold"
                                                    ml={1}
                                                  >
                                                    {truncatedhsp}
                                                  </Typography>
                                                }
                                              />
                                            </Box>
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              {/* <Typography ml={1}>
                                            ₺{balance}
                                          </Typography> */}
                                              <Button
                                                sx={{ ml: 1 }}
                                                onClick={(event) => {
                                                  event.stopPropagation() // Prevents the button click from triggering the ListItemButton click
                                                  handlePopoverOpen(
                                                    event,
                                                    account,
                                                  )
                                                }}
                                              >
                                                <GridExpandMoreIcon
                                                  sx={{
                                                    transform: 'rotate(-90deg)',
                                                  }}
                                                />
                                              </Button>
                                            </Box>
                                          </Box>
                                        </ListItemButton>
                                      </ListItem>
                                    )
                                  })}
                                </Box>
                              </>
                            )
                          }
                        },
                      )}
                    </List>
                  )}

                  <Popover
                    id={accountDetailsPopoverId}
                    open={isMoreDetailsPopUpOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Box sx={{ p: 1 }}>
                      <BankDetailsCard
                        account={popoverAccount}
                        balances={balances}
                      />
                    </Box>
                  </Popover>
                </>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                İşlemler
              </Typography>
              <Divider sx={{ marginBottom: 2 }} />
              <Grid container width="100%">
                <Grid item xs={12} width="100%">
                  <Box width="100%" overflow="auto">
                    <LineChart
                      width={500}
                      height={300}
                      series={[
                        {
                          data: uData,
                          // label: 'uv',
                          area: true,
                          showMark: false,
                          // color: '#ff5722',
                        },
                      ]}
                      xAxis={[{ scaleType: 'point', data: xLabels }]}
                      sx={{
                        [`& .${lineElementClasses.root}`]: {
                          display: 'none',
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} mb={2}>
                  <Button
                    color="secondary"
                    variant={'outlined'}
                    startIcon={<DateRangeIcon />}
                    onClick={() => setIsDateRangePickerOpen(true)}
                    style={{
                      textTransform: 'none',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                    disabled={
                      selectedRiza === '' ||
                      selectedHesap === '' ||
                      selectedRiza === undefined ||
                      selectedHesap === undefined
                    }
                  >
                    İşlem Bilgileri
                  </Button>
                </Grid>
                <Grid item xs={12} width="100%" height="100%">
                  <TransactionDataGrid
                    transactions={transactions}
                    sx={{ height: '100%' }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </MediaQuery>
    </>
  )
}
