import dayjs from 'dayjs'
import { Ihesapler } from './customTypes'
import { IOdemeAyrık, IOdemeHesap } from './odemeTypes'

export const hesapFormDefaultValues: Ihesapler = {
  katilimciBlg: {
    hhsKod: '4128',
    yosKod: '7031',
  },
  gkd: {
    yetYntm: 'Y',
    yonAdr:
      'https://devtest.fincloudy.com/redirect?drmKod=29f1739e-568e-416e-80de-535852be6dea',
  },
  hspBlg: {
    iznBlg: {
      erisimIzniSonTrh: dayjs().add(6, 'month').startOf('day'),
      hesapIslemBslZmn: dayjs().subtract(1, 'year').startOf('day'),
      hesapIslemBtsZmn: dayjs().add(1, 'year').startOf('day'),
      iznTur: ['01', '04', '03', '02', '05'],
    },
  },
  kmlk: {
    ohkTur: 'B',
    kmlkTur: 'K',
    kmlkVrs: '91264223762',
  },
  scenarioType: '',
  isAyrikAvailable: false,
}

export const odemeDefaultValues: IOdemeHesap = {
  rzBlg: {
    rizaNo: '',
    olusZmn: '',
    gnclZmn: '',
    rizaDrm: 'B',
  },
  katilimciBlg: {
    ...hesapFormDefaultValues.katilimciBlg,
  },
  gkd: {
    ...hesapFormDefaultValues.gkd,
  },
  odmBsltm: {
    kmlk: {
      kmlkVrs: '',
      kmlkTur: 'K',
      ohkTur: 'B',
    },
    islTtr: {
      prBrm: 'TRY',
      ttr: '56',
    },
    alc: {
      hspNo: 'TR320010009999901234567890',
      unv: 'Serkan Eren',
    },
    odmAyr: {
      odmAcklm: 'İıçöğüş ÇÖĞÜŞ',
      odmAmc: '04',
      odmKynk: 'O',
      refBlg: 'Y-2701852-202302',
    },
  },
  isyOdmBlg: {
    isyKtgKod: '5732',
    altIsyKtgKod: '5732',
    genelUyeIsyeriNo: '01234567',
  },
  scenarioType: '',
}

export const odemeAyrikDefaultValues: IOdemeAyrık = {
  ...odemeDefaultValues,
  gkd: {
    ...odemeDefaultValues.gkd,
    yetYntm: 'A',
    ayrikGkd: {
      ohkTanimTip: 'TCKN',
      ohkTanimDeger: '',
    },
  },
  odmBsltm: {
    kmlk: {
      kmlkVrs: '',

      kmlkTur: 'K',
      ohkTur: 'B',
    },
    islTtr: {
      prBrm: 'TRY',
      ttr: '',
    },
    alc: {
      hspNo: 'TR320010009999901234567890',
      unv: 'Serkan Eren',
    },
    gon: {
      hspNo: '',
      unv: '',
    },
    odmAyr: {
      odmAcklm: 'İıçöğüş ÇÖĞÜŞ',
      odmAmc: '04',
      odmKynk: 'O',
      refBlg: 'Y-2701852-202302',
    },
  },
}
