import { Autocomplete, TextField } from '@mui/material'
import { SyntheticEvent } from 'react'
import { useFormContext } from 'react-hook-form'

interface IyetkilendirmeItem {
  label: string
  value: string
}

export const yetkilendirmeItems: IyetkilendirmeItem[] = [
  {
    label: 'Ayrık GKD',
    value: 'A',
  },
  {
    label: 'Yönlendirme',
    value: 'Y',
  },
]

interface IYetkilendirmeProp {
  onSelect: (selectedValue: string) => void
  value: string
}

function Yetkilendirme({ onSelect, value }: IYetkilendirmeProp) {
  const { watch } = useFormContext()

  const handleYetkilendirmeValChange = (
    event: SyntheticEvent<Element, Event>,
    item: IyetkilendirmeItem | null,
  ) => {
    if (item) {
      onSelect(item.value)
    }
  }

  // Find the corresponding item from yetkilendirmeItems based on the provided value
  const selectedOption = yetkilendirmeItems.find((item) => item.value === value)

  return (
    <Autocomplete
      options={yetkilendirmeItems}
      getOptionLabel={(option) => option.label}
      onChange={handleYetkilendirmeValChange}
      value={selectedOption || null}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" 
        label="Yetkilendirme Türü" 
        />
      )}
      sx={{marginTop: 2, width: '90%'}}
    />
  )
}

export default Yetkilendirme
