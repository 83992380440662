import { Grid } from '@mui/material'
import PageTitle from './PageTitle'

interface PageHeaderProps {
  title: string
}

function SimpleHeader({ title }: PageHeaderProps) {
  return (
    <Grid
      container
      spacing={2}
      width="100%"
      marginBottom="20px"
      alignItems="center"
    >
      <Grid item md={6} sm={12}>
        <PageTitle title={title} />
      </Grid>
    </Grid>
  )
}

export default SimpleHeader
