import {
    AddBusiness,
    CurrencyLira,
    Description,
    Event,
  } from '@mui/icons-material'
  import { DataGrid, GridColDef, GridRenderCellParams, trTR } from '@mui/x-data-grid'
  import { formatAmount, formatDate } from '../utils/formatter'
import { SxProps, Theme } from '@mui/material'
  
  export default function TransactionDataGrid({
    transactions,
    sx
  }: {
    transactions: any,
    sx?: SxProps<Theme>
  }) {
    const columns: GridColDef[] = [
      {
        field: 'islGrckZaman',
        headerName: 'İşlem Zamanı',
        width: 250,
        renderHeader: () => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Event style={{ marginRight: 8 }} />
            İşlem Zamanı
          </div>
        ),
        renderCell: ({ row }: GridRenderCellParams<any, any>) => {
          return formatDate(row?.islGrckZaman)
        },
      },
      {
        field: 'krsTrf',
        width: 250,
        headerName: 'Kuruluşun Unvanı',
        renderCell: ({ row }: GridRenderCellParams<any, any>) => {
          return `${row?.krsTrf?.krsMskUnvan}`
        },
        renderHeader: () => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AddBusiness style={{ marginRight: 8 }} />
            Kuruluşun Unvanı
          </div>
        ),
      },
      {
        field: 'islAcklm',
        width: 500,
        headerName: 'İşlem Açıklama',
        renderHeader: () => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Description style={{ marginRight: 8 }} />
            İşlem Açıklama
          </div>
        ),
      },
      {
        field: 'islTtr',
        headerName: 'İşlem Tutarı',
        width: 250,
        renderHeader: () => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CurrencyLira style={{ marginRight: 8 }} />
            İşlem Tutarı
          </div>
        ),
        renderCell: ({ row }: GridRenderCellParams<any, any>) => {
          return `₺ ${formatAmount(row?.islTtr)}`
        },
      },
    ]
  
    return (
      <DataGrid
        rows={transactions}
        getRowId={(row) => row?.refNo}
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        pagination={true}
        pageSizeOptions={[]}
        sx={{
          minHeight: 200,
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#000080',
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid #e0e0e0',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#f5f5f5',
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#f1f1f1',
          },
          ...sx
        }}
      />
    )
  }
  