import StepContentRenderer from '../../../components/StepContentRenderer'
import { bireyselComponents } from '../bireysel/bireyselComponentList'
import { useFormContext } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { clearStorage } from '../../../utils/formUtils'
import { bireyselFormValues } from '../bireysel/BireyselStepperProvider'
import { useConnectHesapMutation } from '../../../api/hesaplarApi'
import { useNavigate } from 'react-router-dom'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { Ihesapler } from '../../../types/customTypes'
import ErrorSnackbar from '../../../components/ErrorSnackbar'

function BireyselStepper() {
  const [connectBireysel, { isSuccess, isLoading, isError, data, error }] =
    useConnectHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    unregister,
    formState: { errors },
    getValues,
  } = useFormContext<Ihesapler>()

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    unregister(['kmlk.krmKmlkTur', 'kmlk.krmKmlkVrs'])
  }, [])

  const onSubmit = (data: any) => {
    let formData = data
    const scenarioType = getValues('scenarioType')

    const izinTurArray = formData.hspBlg.iznBlg.iznTur

    const is04Or05Included =
      izinTurArray.includes('04') || izinTurArray.includes('05')

    if (!is04Or05Included) {
      delete formData.hspBlg.iznBlg.hesapIslemBslZmn
      delete formData.hspBlg.iznBlg.hesapIslemBtsZmn
    }

    connectBireysel({ formData, scenarioType })
    clearStorage(bireyselFormValues)
  }

  useEffect(() => {
    if (isSuccess && data) {
      setErrorMessage('')
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    }

    if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <>
      {showErrorAlert && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepContentRenderer
        components={bireyselComponents}
        onSubmit={onSubmit}
        isSubmitting={isLoading}
      />
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </>
  )
}

export default BireyselStepper
