import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import Hesab from '../../../components/odeme/Hesab'
import Iban from '../../../components/odeme/Iban'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import StepContentRenderer from '../../../components/StepContentRenderer'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import { useEffect, useState } from 'react'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { FormProvider, useForm } from 'react-hook-form'
import { IOdemeHesap } from '../../../types/odemeTypes'
import useFormPersist from 'react-hook-form-persist'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { clearStorage } from '../../../utils/formUtils'
import { odemeDefaultValues } from '../../../types/formDefaultValues'

const defaultOdemeHesap: IOdemeHesap = {
  ...odemeDefaultValues,

  odmBsltm: {
    ...odemeDefaultValues.odmBsltm,
    kmlk: {
      ...odemeDefaultValues.odmBsltm.kmlk,
      kmlkVrs: '77121323400',
    },
    islTtr: {
      ...odemeDefaultValues.odmBsltm.islTtr,
      ttr: '56',
    },
    gon: {
      hspNo: 'TR540412802298801818051243',
      unv: 'h4128 u77121323400',
    },
    odmAyr: {
      ...odemeDefaultValues.odmBsltm.odmAyr,
      odmAcklm: 'OBS Senaryo 1: B',
    },
  },
}

const ibanHesapFormValues = 'hesapBireyV2FormValues'

export const components = [
  {
    index: 0,
    label: 'Ödeme Hizmeti Kullanıcısı Türü Seçimi',
    component: <Iban />,
  },
  {
    index: 1,
    label: 'Alıcı Hesap Bilgileri',
    component: <Hesab />,
  },
  {
    index: 2,
    label: 'Ödeme Adım 3',
    component: <OdemeStep_2 />,
  },
  {
    index: 3,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 4,
    label: 'Senaryo Tipi Seç',
    component: (
      <Box height="454px">
        <Scenarios />
      </Box>
    ), //Box is applied in other to have consistent height with other stepper component
    //which will in turn give a consistent height to the next and previous button
  },
]

function OdemeBireysel() {
  const [odemeBireysel, { isSuccess, isLoading, isError, error, data }] =
    useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [defaultValues] = useState<IOdemeHesap>(() => {
    const storedValues = window.sessionStorage.getItem(ibanHesapFormValues)
    if (storedValues) {
      // Parse the stored values
      const parsedValues: IOdemeHesap = JSON.parse(storedValues)
      return parsedValues
    } else {
      return defaultOdemeHesap
    }
  })

  const handleSubmitButtonClick = (data: any) => {
    const scenarioType = getValues('scenarioType')
    odemeBireysel({ formData: data, scenarioType })
  }

  const formMethods = useForm<IOdemeHesap>({
    defaultValues: defaultValues,
  })

  const { watch, setValue, unregister, getValues } = formMethods

  useFormPersist(ibanHesapFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  useEffect(() => {
    unregister('odmBsltm.alc.kolas')
  }, [])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  useEffect(() => {
    if (isSuccess && data) {
      setErrorMessage('')
      const { hhsYonAdr } = data.gkd
      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
      clearStorage(ibanHesapFormValues)
    }

    if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepContentRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default OdemeBireysel
