import { ReactNode } from 'react'
import CustomStepper from '../pages/demoPage/odeme/Tedarikci/CustomStepper'
import { IChildren } from '../types/customTypes'
import { Box } from '@mui/system'
import { Button } from '@mui/material'

interface DialogStepperProps {
  activeStep: number
  errorStep: number
  children: ReactNode
  height?: string | number
  onBackButtonClick: () => void
  onNextButtonClick: () => void
  stepLabels: string[]
}

export default function DialogStepper({
  activeStep,
  errorStep,
  children,
  height = '500px',
  onNextButtonClick,
  onBackButtonClick,
  stepLabels = [''],
}: DialogStepperProps) {
  return (
    <Box width="100%">
      <CustomStepper
        activeStep={activeStep}
        steps={stepLabels}
        errorStep={errorStep}
      />
      <Box height={height} width="100%">
        {children}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          px: 3,
          justifyContent: 'space-between',
        }}
      >
        <Button
          color="secondary"
          disabled={activeStep === 0}
          onClick={onBackButtonClick}
          variant="contained"
          sx={{ width: 200 }}
        >
          Geri
        </Button>
        <Button
          color="secondary"
          onClick={onNextButtonClick}
          variant="contained"
          sx={{ width: 200 }}
        >
          {activeStep === stepLabels.length - 1 ? 'Onayla' : 'İleri'}
        </Button>
      </Box>
    </Box>
  )
}
