import TextField from '@mui/material/TextField'
import { Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'

import StepperCard from '../../../components/StepperCard'
import { StepperContainer } from '../../../components/StepperContainer'

export const Step_1 = () => {

  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={12}>
            <InputLabel>Karekod Referansi</InputLabel>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue="12345678901"
            />
          </Grid>
          <Grid item xs={6} sm={12}>
            <InputLabel>Karekod Üretici Kodu</InputLabel>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue="0800"
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export default Step_1
