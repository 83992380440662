import { Box, Button, Typography } from '@mui/material'
import { GridCheckCircleIcon } from '@mui/x-data-grid'
import DialogInput from './Dialog'
import { ErrorOutline } from '@mui/icons-material'

interface FeedbackAlertProps {
  open: boolean
  onClose: () => void
  message: string
  buttonText: string
  buttonAction: () => void
  linkPath: string
  variant: 'success' | 'error'
}

function FeedbackAlert({
  open,
  message,
  buttonText,
  linkPath,
  variant,
  buttonAction,
  onClose,
}: FeedbackAlertProps) {
  return (
    <DialogInput
      open={open}
      onClose={onClose}
      title=""
      content={
        <Box textAlign="center" minWidth="500px">
          {variant === 'success' && (
            <GridCheckCircleIcon sx={{ fontSize: '150px' }} color="success" />
          )}
          {variant === 'error' && (
            <ErrorOutline sx={{ fontSize: '100px', color: 'error.main' }} />
          )}

          <Typography variant="h5" gutterBottom mb={3}>
            {message}
          </Typography>
            <Button variant="contained" onClick={buttonAction}>
              <Typography variant="h6">{buttonText}</Typography>
            </Button>
        </Box>
      }
      noDefaultButtons
    />
  )
}

export default FeedbackAlert
