import React from 'react'
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
} from '@mui/material'

interface CompanyAddress {
  streetName: string
  citySubdivisionName: string
  cityName: string
  postalZone: string
  countryName: string
  createdAt: string
  updatedAt: string
  __v: number
}

interface CompanyProfileProps {
  companyPartyName: string
  companyFullName: string
  companySchemeId: string
  companySchemeType: string
  companyPhone: string
  companyEmail: string
  companyAddress: CompanyAddress
  kolasOption: string
  paymentFieldValue: string
  paymentType: string
}

const CompanyProfile: React.FC = () => {
  const companyData: CompanyProfileProps = {
    companyPartyName:
      'EDM BİLİŞİM SİSTEMLERİ VE DANIŞMANLIK HİZMETLERİ ANONİM ŞİRKETİ',
    companyFullName:
      'EDM BİLİŞİM SİSTEMLERİ VE DANIŞMANLIK HİZMETLERİ ANONİM ŞİRKETİ',
    companySchemeId: '3230512384',
    companySchemeType: 'VKN',
    companyPhone: '5851774673',
    companyEmail: 'tfewsrx@omjgcdbp.com',
    companyAddress: {
      streetName:
        'Mahalle/Semt:CUMHURİYET MAH. Cadde/Sokak:YENİ YOL 1 SK. BOMONTİ BUSİNE…',
      citySubdivisionName: 'Şişli',
      cityName: 'İstanbul',
      postalZone: '34000',
      countryName: 'TÜRKİYE',
      createdAt: '2024-06-06T09:21:30.733+00:00',
      updatedAt: '2024-06-06T09:36:03.918+00:00',
      __v: 0,
    },
    kolasOption: '',
    paymentFieldValue: 'TR570242103898352825584271',
    paymentType: 'IBAN',
  }

  const {
    companyPartyName,
    companyFullName,
    companySchemeId,
    companySchemeType,
    companyPhone,
    companyEmail,
    companyAddress,
    kolasOption,
    paymentFieldValue,
    paymentType,
  } = companyData

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography variant="h6">Kullanıcı Bilgileri</Typography>

          <Typography variant="body1" gutterBottom>
            Kullanıcı Adı: canan.kansu
          </Typography>
          <Typography variant="body1" gutterBottom>
            Kullanıcı Email: canan.kansu@edm.com
          </Typography>
          <Divider />
          <br />
          <Typography variant="h6">İşyeri Bilgileri</Typography>

          <Typography variant="body1" gutterBottom>
            {companyPartyName}
          </Typography>

          <Typography variant="body1">
            Vergi Kimlik No: {companySchemeId}
          </Typography>

          <Typography variant="body1">Telefon: {companyPhone}</Typography>
          <Typography variant="body1">E-posta: {companyEmail}</Typography>
          <Divider />

          <br />

          <Typography variant="h6">Adres</Typography>

          <Typography variant="body1">
            {companyAddress.streetName}, {companyAddress.citySubdivisionName},{' '}
            {companyAddress.cityName}, {companyAddress.postalZone},{' '}
            {companyAddress.countryName}
          </Typography>

          <Divider />
          <br />
          <Typography variant="h6">Ödeme Bilgileri</Typography>
          <Typography variant="body1">IBAN: {paymentFieldValue}</Typography>
        </CardContent>
      </Card>
    </Container>
  )
}

export default CompanyProfile
