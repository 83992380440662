import { Button, Dialog, DialogContent, Grid } from '@mui/material'
import { PreviewField, PreviewTitle } from '../../../components/Preview'
import { formatDate } from '../../../utils/dateFormatter'
import { Box } from '@mui/system'
import { getEnumKeyByValue } from '../../../utils/enumUtil'

interface PreviewProps {
  open: boolean
  data: any
  handleClose: () => void
  onConfirm: () => void
  isLoading: boolean
  showConfirmButton?: boolean
}
enum IzinTuru {
  'Temel Hesap Bilgisi' = '01',
  'Ayrıntılı Hesap Bilgisi' = '02',
  'Bakiye Bilgisi' = '03',
  'Temel İşlem' = '04',
  'Ayrıntılı İşlem Bilgisi' = '05',
  'Anlık Bakiye Bildirimi' = '06',
}
export default function AccountPreview({
  open,
  data,
  handleClose,
  onConfirm,
  isLoading,
  showConfirmButton,
}: PreviewProps) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogContent>
        <PreviewTitle>Hesap Bilgileri</PreviewTitle>
        <Grid container spacing={5}>
          <Grid item sm={12} md={6}>
            <PreviewField
              label="TCKN Kimlik Verisi"
              value={data.kmlk?.kmlkVrs}
            />
          </Grid>
          {data?.kmlk?.ohkTur === 'K' && (
            <Grid item xs={12} md={6}>
              <PreviewField
                label="VKN Kimlik Verisi"
                value={data?.kmlk?.krmKmlkVrs}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <PreviewField
              label="Erişimin Geçerli Olduğu Son Tarih"
              value={formatDate(data?.hspBlg?.iznBlg?.erisimIzniSonTrh)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewField
              label="İşlem Sorgulama Başlangiç Zamani"
              value={formatDate(data?.hspBlg?.iznBlg?.hesapIslemBslZmn)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewField
              label="İşlem Sorgulama Bitiş Zamani"
              value={formatDate(data?.hspBlg?.iznBlg?.hesapIslemBtsZmn)}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <PreviewField
              label="Verilen İzin Türleri"
              value={data?.hspBlg?.iznBlg?.iznTur?.map((iznTur: string) =>
                getEnumKeyByValue(IzinTuru, iznTur),
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 2 }}>
          <Button variant="contained" onClick={handleClose} sx={{ width: 250 }}>
            Kapat
          </Button>
          {showConfirmButton && (
            <Button
              variant="contained"
              onClick={onConfirm}
              sx={{ width: 250 }}
              disabled={isLoading}
            >
              Onayla
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
