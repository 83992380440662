import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { Grid, MenuItem } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { useState } from 'react'
import HesabAndName from '../IbanPage/Step_1'
import { BackButton, NextButton } from '../../../components/odeme/OdemeButton'
import StepperCard from '../../../components/StepperCard'
import { useStepperControlContext } from '../../../components/StepContextProvider'
import { StepperContainer } from '../../../components/StepperContainer'

interface Option {
  label: string
  value: string
}

export const Step_1 = () => {

  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={12}>
            <InputLabel>Karekod Referansı</InputLabel>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue="12345678901"
            />
          </Grid>
          <Grid item xs={6} sm={12}>
            <InputLabel>Karekod Üretici Kodu</InputLabel>
            <TextField
              required
              id="outlined-required"
              fullWidth
              defaultValue="0800"
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export default Step_1
