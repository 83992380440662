import { api } from './api'

const olaylarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOlayAbonelik: builder.query<any, void>({
      query: () => ({
        url: `https://devtest.fincloudy.com/api/v1/olay-abonelik`,
      }),
    }),
    updateOlayAbonelik: builder.mutation({
      query: ({ formData, olayAbonelikId }) => ({
        url: `https://devtest.fincloudy.com/api/v1/olay-abonelik/${olayAbonelikId}`,
        method: 'PUT',
        body: formData,
      }),
    }),
    deleteOlayAbonelik: builder.mutation({
      query: ({ olayAbonelikId }) => ({
        url: `https://devtest.fincloudy.com/api/v1/olay-abonelik/${olayAbonelikId}`,
        method: 'DELETE',
      }),
    }),
    createOlayAbonelik: builder.mutation({
      query: ({ formData }) => ({
        url: `https://devtest.fincloudy.com/api/v1/olay-abonelik`,
        method: 'POST',
        body: formData,
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const {
  useGetOlayAbonelikQuery,
  useUpdateOlayAbonelikMutation,
  useDeleteOlayAbonelikMutation,
  useCreateOlayAbonelikMutation,
} = olaylarApi
