import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import StepperRenderer from '../../../components/StepContentRenderer'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import Hesab from '../../../components/odeme/Hesab'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import { useEffect, useState } from 'react'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { IOdemeHesap } from '../../../types/odemeTypes'
import { FormProvider, useForm } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import ErrorSnackbar from '../../../components/ErrorSnackbar'

const tekSeferlikOdemeDefaultValues: IOdemeHesap = {
  katilimciBlg: {
    hhsKod: '4128',
    yosKod: '7031',
  },
  gkd: {
    yetYntm: 'Y',
    yonAdr: 'https://devtest.fincloudy.com/redirect?drmKod=test123',
  },
  odmBsltm: {
    kmlk: {
      ohkTur: 'B',
    },
    islTtr: {
      prBrm: 'TRY',
      ttr: '1',
    },
    alc: {
      hspNo: 'TR320010009999901234567890',
      unv: 'Serkan Eren',
    },
    odmAyr: {
      odmAcklm: 'Tek Seferlik Odeme',
      odmAmc: '04',
      odmKynk: 'O',
      refBlg: 'Y-2701852-202302',
    },
  },
  isyOdmBlg: {
    isyKtgKod: '5732',
    altIsyKtgKod: '5732',
    genelUyeIsyeriNo: '01234567',
  },
}

export const components = [
  {
    index: 0,
    label: 'Odeme Step',
    component: <OdemeStep_2 />,
  },
  {
    index: 1,
    label: 'Alıcı Hesap Bilgileri',
    component: <Hesab />,
  },
  {
    index: 2,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 3,
    label: 'Senaryo Tipi Seç',
    component: <Scenarios />,
  },
]

function TekSeferlikOdeme() {
  const [odemeHesap, { isSuccess, isLoading, isError, data, error }] =
    useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    }
  }, [isSuccess, data])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  const tekSeferlikOdemeFormValues = 'tekSeferlikOdeme'

  const [defaultValues] = useState<IOdemeHesap>(() => {
    const storedValues = window.sessionStorage.getItem(
      tekSeferlikOdemeFormValues,
    )
    if (storedValues) {
      // Parse the stored values
      const parsedValues: IOdemeHesap = JSON.parse(storedValues)
      return parsedValues
    } else {
      return tekSeferlikOdemeDefaultValues
    }
  })

  const formMethods = useForm<IOdemeHesap>({
    defaultValues: defaultValues,
    // resolver: zodResolver(hesaplerSchema),
  })

  const { watch, setValue, getValues } = formMethods

  useFormPersist(tekSeferlikOdemeFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const handleSubmitButtonClick = (data: any) => {
    const scenarioType = getValues('scenarioType')
    odemeHesap({ formData: data, scenarioType })
  }

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    } else if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 
         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepperRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default TekSeferlikOdeme
