import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
import SideNavBar from './Sidebar'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'

interface Props {
  children: ReactNode
}

const MainContent = styled.div<{ $isExpanded?: boolean }>`
  margin-left: ${(props) => (props.$isExpanded ? '400px' : '85px')};
  transition: margin-left 0.4s;
  width: '100%';
  

  @media (min-width: 768px) {
    margin-left: ${(props) => (props.$isExpanded ? '350px' : '50px')};
  }

  @media (max-width: 767px) {
    margin-left: ${(props) => (props.$isExpanded ? '300px' : '0')};
  }
  @media (min-width: 1080px) {
    margin-left: ${(props) => (props.$isExpanded ? '400px' : '85px')};
  }
`;

const ChildrenContainer = styled.div<{ $isExpanded?: boolean }>`
  margin: 50px;
  z-index: 2;

  @media (max-width: 767px) {
    margin: 10px 30px;
  }
`

const Layout = ({ children }: Props): JSX.Element => {
  const [isExpanded, setExpandState] = useState(true)
  const toggleSidebar = () => {
    setExpandState(!isExpanded)
  }
  return (
    <>
      <SideNavBar isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
      <Box>
        <MainContent $isExpanded={isExpanded}>
          <Navbar isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
          <ChildrenContainer $isExpanded={isExpanded}>{children}</ChildrenContainer>
        </MainContent>
      </Box>
    </>
  )
}

export default Layout
