import { api } from './api'

const invoiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<any, any>({
      query: (type: string) => ({
        url: `https://devtest.fincloudy.com/api/v1/invoices?type=${type}`,
      }),
    }),
    getInvoice: builder.query<any, any>({
      query: (id: string) => ({
        url: `https://devtest.fincloudy.com/api/v1/invoices/${id}`,
      }),
    }),
    getMusteriTedarikci: builder.query<any, any>({
      query: (type: string) => ({
        url: `https://devtest.fincloudy.com/api/v1/musteritedarikci`,
      }),
    }),

    updateMusteriTedarikci: builder.mutation({
      query: (updatedCustomer) => ({
        url: `https://devtest.fincloudy.com/api/v1/tedarikciOdemeUpdate`,
        method: 'PUT',
        body: updatedCustomer,
      }),
    }),

    sendPaymentLink: builder.mutation({
      query: ({ formData }) => ({
        url: `https://devtest.fincloudy.com/api/v1/invoiceMail`,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
})

export const {
  useLazyGetInvoicesQuery,
  useLazyGetInvoiceQuery,
  useLazyGetMusteriTedarikciQuery,
  useUpdateMusteriTedarikciMutation,
  useSendPaymentLinkMutation,
} = invoiceApi
