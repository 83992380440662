import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react'
const hesaplerLocalHostApi = 'http://localhost:3001/v1'
const liveApi = 'https://devtest.fincloudy.com/api/v1'
const localApi = 'https://8b25-176-88-152-18.ngrok-free.app/v1/'

const customBaseQuery =
  // retry(
  async (args: string | FetchArgs, api: any, extraOptions: any) => {
    const result = await fetchBaseQuery({
      baseUrl: '',
      credentials: 'include',
    })(args, api, extraOptions)

    // bail out of re-tries immediately if unauthorized or timeout
    // because we know successive re-retries would be redundant
    if (result.error?.status === 401 || result.error?.status === 504) {
      retry.fail(result.error)
    }

    return result
  }

export const api = createApi({
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({}),
})
