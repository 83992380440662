import React, { useEffect, useState } from 'react'
import { Typography, Button, Container } from '@mui/material'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyGetPaymentResultByRizaNoQuery } from '../../api/odemeApi'

function AyrikGkdResult() {
  const navigate = useNavigate()
  const location = useLocation()

  const [errorCode, setErrorCode] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] =
    useState(false)
  const [isSweetAlertOpen, setIsSweetAlertOpen] = useState(false)

  const [
    getPaymentResultByRizaNo,
    {
      isSuccess: isGetPaymentResultSuccess,
      isError: isGetPaymentResultError,
      data: paymentResult,
      error: paymentResultError,
      isLoading: paymentResultLoading,
    },
  ] = useLazyGetPaymentResultByRizaNoQuery()

  useEffect(() => {
    let params = location.state
    console.log('params', params)
    // getPaymentResultByRizaNo()
  }, [])

  const loadingAlert = () => {
    Swal.fire({
      title: '',
      text: 'Ödeme sonucu bekleniyor...',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }
  const successAlert = () => {
    let successMessageText
    // if (errorCode === 'odeme') {
    //   successMessageText = 'Ödeme Tamamlandı!'
    // } else if (errorCode === 'hesap') {
    //   successMessageText = 'Hesap başarılı şekilde bağlandı'
    // } else {
    //   successMessageText = successMessage
    // }

    Swal.fire({
      title: 'Başarılı',
      icon: 'success',
      text: successMessageText,
      confirmButtonText: 'Anasayfaya Git',
      allowOutsideClick: false,
      footer: '<a href="#">FinCloudy Açık Bankacılık</a>',
      showDenyButton: true,
      denyButtonText: 'Detayları Gör',
      cancelButtonText: 'Anasayfaya Git',
      denyButtonColor: 'green',
      customClass: {
        confirmButton: 'custom-confirm-button-class',
      },
    }).then(async (val) => {
      if (val.isConfirmed) {
        window.location.href = `/`
      } else if (val.isDenied) {
      }
    })
  }

  const errorAlert = () => {
    let errorMessageText
    // if (errorCode === 'odeme') {
    //   errorMessageText = 'Ödeme gerçekleştirilirken hata oluştu.'
    // } else if (errorCode === 'hesap') {
    //   errorMessageText = 'Hesap bağlanırken hata oluştu.'
    // } else {
    //   errorMessageText = 'Bilinmeyen bir hata oluştu.'
    // }

    Swal.fire({
      icon: 'error',
      title: 'HATA!',
      text: errorMessageText,
      confirmButtonText: 'Tekrar Gönder',
      showCancelButton: true,
      showDenyButton: true,
      denyButtonText: 'Detayları Gör',
      cancelButtonText: 'Anasayfaya Git',
      denyButtonColor: 'green',
      allowOutsideClick: false,
      footer: '<a href="#">FinCloudy Açık Bankacılık</a>',
    }).then(async (val) => {
      if (val.isConfirmed) {
        window.location.reload()
      } else if (val.dismiss === Swal.DismissReason.cancel) {
        navigate('/')
      } else if (val.isDenied) {
      }
    })
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        Ödeme Sonucu Bekleniyor
      </Typography>
      <Typography variant="body1" paragraph>
        Mobil bildirim alıp oradan ödemenize onay verin. Daha sonra bu sayfaya
        geri gelip ödeme tamamlandığını görün.
      </Typography>
    </Container>
  )
}

export default AyrikGkdResult
