import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { Ihesapler } from '../../../types/customTypes'
import { hesapFormDefaultValues } from '../../../types/formDefaultValues'
import useFormPersist from 'react-hook-form-persist'
import AutoComplete from '../../../components/CustomAutoComplete'
import DialogStepper from '../../../components/DialogStepper'
import HesapDatePickerComponent from '../../../components/HesapDatePicker'
import { IzinTuru } from '../../../enum/hesapEnums'
import dayjs, { Dayjs } from 'dayjs'
import {
  useConnectHesapMutation,
  useLazyHesapRizaSorgulaQuery,
} from '../../../api/hesaplarApi'
import { connectAccountSchema } from '../../../validation/connectAccount.validation'
import { zodResolver } from '@hookform/resolvers/zod'
import AccountPreview from './AccountPreview'
import PaymentResultDialog from '../../../components/PaymentResultDialog'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { ayrikChoices } from '../../demoPage/odeme/ParaTransferi/Payment'
import { useLazyGetPaymentResultByRizaNoQuery } from '../../../api/odemeApi'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { getErrorMessageInfo } from '../../../utils/error'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

// Set default timezone to UTC+3
const defaultTimeZone = 'Europe/Istanbul'
dayjs.tz.setDefault(defaultTimeZone)

interface ConnectNewAccountProps {
  open: boolean
  handleClose: () => void
}

const izinTuru = [
  { name: 'Temel Hesap Bilgisi', value: IzinTuru.TemelHesapBilgisi },
  { name: 'Ayrıntılı Hesap Bilgisi', value: IzinTuru.AyrintiliHesapBilgisi },
  { name: 'Bakiye Bilgisi', value: IzinTuru.BakiyeBilgisi },
  { name: 'Temel İşlem', value: IzinTuru.TemelIslem },
  { name: 'Ayrıntılı İşlem Bİlgisi', value: IzinTuru.AyritiliIslemBilgisi },
  { name: 'Anlık Bakiye Bildirimi', value: IzinTuru.AnlikBakiyeBildirimi },
]

const fieldStepMapping: { [key: string]: number } = {
  'kmlk.ohkTur': 0,
  'kmlk.kmlkVrs': 0,
  'kmlk.krmKmlkVrs': 0,
  'hspBlg.iznBlg.iznTur': 1,
  'hspBlg.iznBlg.erisimIzniSonTrh': 1,
  'hspBlg.iznBlg.hesapIslemBslZmn': 1,
  'hspBlg.iznBlg.hesapIslemBtsZmn': 1,
}

export default function ConnectNewAccount({
  open,
  handleClose,
}: ConnectNewAccountProps) {
  const [activeStep, setActiveStep] = useState(0)
  const [errorStep, setErrorStep] = useState(-1)
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [showPaymentResultDialog, setShowPaymentResultDialog] = useState(false)
  const [isPreviewDialogOpen, setisPreviewDialogOpen] = useState(false)
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [rizaNo, setRizaNo] = useState<string>('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const newHesapFormValues = 'newHesap'

  const getSavedData = useCallback(() => {
    let data = sessionStorage.getItem(newHesapFormValues)
    if (data) {
      // Parse it to a JavaScript object
      try {
        data = JSON.parse(data)
      } catch (err) {
        console.log(err)
      }
      return data as unknown as Ihesapler
    } else {
      return hesapFormDefaultValues
    }
  }, [])

  const formMethods = useForm<Ihesapler>({
    defaultValues: getSavedData(),
    resolver: zodResolver(connectAccountSchema),
  })

  const {
    watch,
    setValue,
    register,
    unregister,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = formMethods

  useFormPersist(newHesapFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const iznTurValue = watch('hspBlg.iznBlg.iznTur')
  const is04Or05Selected =
    iznTurValue.includes(IzinTuru.TemelIslem) ||
    iznTurValue.includes(IzinTuru.AyritiliIslemBilgisi)

  const dateSelection = [
    {
      label: 'Erişimin Geçerli Olduğu Son Tarih',
      value: dayjs(watch('hspBlg.iznBlg.erisimIzniSonTrh')),
      isDisabled: false,
      onChange: (date: Dayjs) => {
        setValue('hspBlg.iznBlg.erisimIzniSonTrh', date)
      },
      error: !!errors.hspBlg?.iznBlg?.erisimIzniSonTrh,
      helperText: errors.hspBlg?.iznBlg?.erisimIzniSonTrh?.message,
    },
    {
      label: 'Işlem Sorgulama Başlangiç Zamani',
      value: dayjs(watch('hspBlg.iznBlg.hesapIslemBslZmn')),
      isDisabled: !is04Or05Selected,
      onChange: (date: Dayjs) => {
        setValue('hspBlg.iznBlg.hesapIslemBslZmn', date)
      },
      error: !!errors.hspBlg?.iznBlg?.hesapIslemBslZmn,
      helperText: errors.hspBlg?.iznBlg?.hesapIslemBslZmn?.message,
    },
    {
      label: 'Işlem Sorgulama Bitiş Zamani',
      value: dayjs(watch('hspBlg.iznBlg.hesapIslemBtsZmn')),
      isDisabled: !is04Or05Selected,
      onChange: (date: Dayjs) => {
        setValue('hspBlg.iznBlg.hesapIslemBtsZmn', date)
      },
      error: !!errors.hspBlg?.iznBlg?.hesapIslemBtsZmn,
      helperText: errors.hspBlg?.iznBlg?.hesapIslemBtsZmn?.message,
    },
  ]

  const handleAccountTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setValue('kmlk.ohkTur', value)
    if (value === 'B') {
      unregister('kmlk.krmKmlkTur')
    } else if (value === 'K') {
      register('kmlk.krmKmlkTur')
      setValue('kmlk.krmKmlkTur', 'V')
    }
  }

  const steps = ['Hesap Bilgisi', 'Hesap şeç']

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handlePreviewConfirmation()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSelectionChange = (
    event: SelectChangeEvent<typeof iznTurValue>,
  ) => {
    const value = event.target.value
    let newValue = Array.isArray(value) ? value : [value]

    if (!newValue.includes(IzinTuru.TemelHesapBilgisi)) {
      newValue.push(IzinTuru.TemelHesapBilgisi)
    }

    if (
      newValue.includes(IzinTuru.AyritiliIslemBilgisi) &&
      !newValue.includes(IzinTuru.TemelIslem)
    ) {
      newValue.push(IzinTuru.TemelIslem)
    }

    setValue('hspBlg.iznBlg.iznTur', newValue)
    clearErrors('hspBlg.iznBlg.iznTur')
  }

  const handleSubmitClick = () => {
    // handleSubmit(handleSubmission)()
  }

  const handleSubmission = (data: Ihesapler) => {
    // handlePreviewConfirmation()
    // setisPreviewDialogOpen(true)
    setshowRedirectConfirmationDialog(true)
  }

  const getErrorStep = (errors: FieldErrors) => {
    let errorStep = -1

    const findErrorStep = (errorsObj: any, path = '') => {
      for (const key in errorsObj) {
        const newPath = path ? `${path}.${key}` : key
        if (errorsObj[key].message) {
          const step = fieldStepMapping[newPath]
          if (step !== undefined) {
            errorStep = step
            break
          }
        } else if (typeof errorsObj[key] === 'object') {
          findErrorStep(errorsObj[key], newPath)
        }
      }
    }

    findErrorStep(errors)
    return errorStep
  }

  useEffect(() => {
    setErrorStep(getErrorStep(errors))
  }, [errors])

  const [
    connectHesap,
    {
      isSuccess: isHesapConnectionSuccess,
      isError: isHesapConnectionError,
      isLoading: isHesapConnectionLoading,
      error: hesapConnectionError,
      data: connectedHesap,
    },
  ] = useConnectHesapMutation()

  useEffect(() => {
    if (isHesapConnectionSuccess && connectedHesap) {
      // setisPreviewDialogOpen(false)
      const { hhsYonAdr } = connectedHesap.gkd
      console.log(connectedHesap)

      // if (watch('isAyrikAvailable')) {
      //   setShowPaymentResultDialog(true)
      // } else if (hhsYonAdr) {
      //   // setshowRedirectConfirmationDialog(true)
      // }
      setRizaNo(connectedHesap?.rzBlg?.rizaNo)
      setisPreviewDialogOpen(true)
    }

    if (isHesapConnectionError && hesapConnectionError) {
      const err = getErrorMessageInfo(hesapConnectionError)
      setisPreviewDialogOpen(false)

      setErrorMessage(err ?? 'Internal server error')
      setshowErrorAlert(true)
    }
  }, [isHesapConnectionSuccess, isHesapConnectionError, hesapConnectionError])

  const handlePaymentPrevSubmit = () => {
    const { hhsYonAdr } = connectedHesap.gkd

    if (watch('isAyrikAvailable')) {
      setShowPaymentResultDialog(true)
    } else if (hhsYonAdr) {
      setshowRedirectConfirmationDialog(true)
    }
  }
  const handleDialogOpen = () => {
    if (isHesapConnectionSuccess && connectedHesap) {
      // setisPreviewDialogOpen(false)
      const { hhsYonAdr } = connectedHesap.gkd
      console.log(connectedHesap)

      if (watch('isAyrikAvailable')) {
        setShowPaymentResultDialog(true)
      } else if (hhsYonAdr) {
        setisPreviewDialogOpen(true)
      }
      setRizaNo(connectedHesap?.rzBlg?.rizaNo)
    }
  }
  const handleRedirectConfirmClick = () => {
    console.log('connected', connectHesap)
    window.open(connectedHesap?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  const handlePreviewConfirmation = () => {
    const isAyrikAvailable = getValues('isAyrikAvailable')
    const ohkTur = getValues('kmlk.ohkTur')

    if (ohkTur === 'B') {
      unregister('kmlk.krmKmlkTur')
      unregister('kmlk.krmKmlkVrs')
    }

    if (isAyrikAvailable) {
      setValue('gkd.yetYntm', 'A')
    } else {
      unregister('gkd.ayrikGkd')
      setValue('gkd.yetYntm', 'Y')
    }
    const formValues = getValues()
    console.log('1formValues', formValues)
    // Convert date fields to UTC+3
    const adjustedFormValues = {
      ...formValues,
      hspBlg: {
        ...formValues.hspBlg,
        iznBlg: {
          ...formValues.hspBlg.iznBlg,
          erisimIzniSonTrh: dayjs
            .tz(formValues.hspBlg.iznBlg.erisimIzniSonTrh, 'Europe/Istanbul')
            .add(2, 'day')
            .startOf('day')
            .format(),
          hesapIslemBslZmn: dayjs
            .tz(formValues.hspBlg.iznBlg.hesapIslemBslZmn, 'Europe/Istanbul')
            .add(1, 'day')

            .startOf('day')
            .format(),
          hesapIslemBtsZmn: dayjs
            .tz(formValues.hspBlg.iznBlg.hesapIslemBtsZmn, 'Europe/Istanbul')
            .add(2, 'day')
            .startOf('day')
            .format(),
        },
      },
    }
    if (!is04Or05Selected) {
      if (adjustedFormValues?.hspBlg?.iznBlg?.hesapIslemBtsZmn) {
        delete (adjustedFormValues.hspBlg.iznBlg as { hesapIslemBtsZmn?: any })
          .hesapIslemBtsZmn
      }
      if (adjustedFormValues?.hspBlg?.iznBlg?.hesapIslemBslZmn) {
        delete (adjustedFormValues.hspBlg.iznBlg as { hesapIslemBslZmn?: any })
          .hesapIslemBslZmn
      }
    }

    console.log('adjustedFormValues', adjustedFormValues)

    connectHesap({ formData: adjustedFormValues })
  }

  useEffect(() => {
    if (isHesapConnectionSuccess && connectedHesap) setisPreviewDialogOpen(true)
  }, [isHesapConnectionSuccess, connectedHesap])
  const [
    getPaymentResultByRizaNo,
    {
      isSuccess: isGetPaymentResultSuccess,
      isError: isGetPaymentResultError,
      data: paymentResult,
      error: paymentResultError,
    },
  ] = useLazyGetPaymentResultByRizaNoQuery()
  const [
    getHesapSorgula,
    {
      isSuccess: isGetHesapRizaSorgulaSuccess,
      isError: isGetHesapRizaSorgulaError,
      isLoading: isGetHesapRizaSorgulaLoading,
      error: rizaSorgulaError,
      data: rizaSorgula,
    },
  ] = useLazyHesapRizaSorgulaQuery()

  const getHesapRizaSorgula = (rizaNo: string) => {
    getHesapSorgula(rizaNo)
  }

  useEffect(() => {
    let intervalId: any
    console.log(connectedHesap)

    if (
      connectedHesap?.gkd.yetYntm === 'A' ||
      (rizaSorgula?.gkd.yetYntm === 'A' && rizaNo)
    ) {
      const checkHesapResult = () => {
        if (rizaSorgula?.rzBlg?.rizaDrm !== 'Y') {
          getHesapRizaSorgula(rizaNo)
        } else {
          clearInterval(intervalId)
          setIsPaymentDone(true)
        }
      }
      checkHesapResult()
      intervalId = setInterval(checkHesapResult, 10000)
      return () => clearInterval(intervalId)
    }
  }, [
    rizaNo,
    connectedHesap,
    rizaSorgula,
    // getPaymentResultByRizaNo,
  ])

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle variant="h6" fontWeight="bold">
        Yeni Hesap Bağla
      </DialogTitle>
      <DialogContent content="500px" sx={{ m: 2 }}>
        <DialogStepper
          activeStep={activeStep}
          stepLabels={steps}
          errorStep={errorStep}
          onNextButtonClick={handleNext}
          onBackButtonClick={handleBack}
        >
          <Grid container spacing={2} mt={1}>
            {activeStep === 0 && (
              <>
                <Grid item sm={12} md={6}>
                  <RadioGroup
                    onChange={handleAccountTypeChange}
                    value={watch('kmlk.ohkTur')}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="B"
                      label="Bireysel"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value="K"
                      label="Kurumsal"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register('isAyrikAvailable')}
                        checked={watch('isAyrikAvailable')}
                        onChange={(event) => {
                          setValue('isAyrikAvailable', event.target.checked)

                          console.log(event.target.checked)
                        }}
                      />
                    }
                    label="Mobil Uygulama var"
                  />
                </Grid>
                {watch('isAyrikAvailable') && (
                  <>
                    <Grid item xs={6} md={6}>
                      <InputLabel>Kimlik Doğrulama Metodu</InputLabel>
                      <Select
                        value={watch('gkd.ayrikGkd.ohkTanimTip')}
                        fullWidth
                        onChange={(e) => {
                          console.log('onchange', e.target.value)
                          setValue('gkd.ayrikGkd.ohkTanimTip', e.target.value)
                        }}
                        label="Kimlik Doğrulama Metodu"
                        sx={{ mt: 2 }}
                      >
                        {ayrikChoices.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <InputLabel id="odemeAmaci">
                        Kimlik Doğrulama Değeri
                      </InputLabel>
                      <TextField
                        margin="normal"
                        {...register('gkd.ayrikGkd.ohkTanimDeger')}
                        onChange={(e) => {
                          setValue('gkd.ayrikGkd.ohkTanimDeger', e.target.value)
                          clearErrors('gkd.ayrikGkd.ohkTanimDeger')
                        }}
                        fullWidth
                        error={!!errors?.gkd?.ayrikGkd?.ohkTanimDeger}
                        helperText={
                          errors?.gkd?.ayrikGkd?.ohkTanimDeger?.message
                        }
                      />
                    </Grid>
                  </>
                )}{' '}
                <Grid item xs={12} md={6}>
                  <InputLabel>TCKN Kimlik Verisi</InputLabel>
                  <AutoComplete
                    onInput={(value) => {
                      setValue('kmlk.kmlkVrs', value)

                      clearErrors('kmlk.kmlkVrs')
                    }}
                    options={['51637017718']}
                    value={watch('kmlk.kmlkVrs')}
                    error={!!errors?.kmlk?.kmlkVrs}
                    helperText={errors?.kmlk?.kmlkVrs?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {watch('kmlk.ohkTur') === 'K' && (
                    <>
                      <InputLabel>VKN Kimlik Verisi</InputLabel>
                      <AutoComplete
                        onInput={(value) => {
                          setValue('kmlk.krmKmlkVrs', value)
                          clearErrors('kmlk.krmKmlkVrs')
                        }}
                        options={['2153996351']}
                        value={watch('kmlk.krmKmlkVrs')}
                        error={!!errors?.kmlk?.krmKmlkVrs}
                        helperText={errors?.kmlk?.krmKmlkVrs?.message}
                      />
                    </>
                  )}
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <Grid
                container
                alignContent="center"
                justifyContent="center"
                spacing={4}
              >
                <Grid item md={6} sm={12}>
                  <InputLabel>
                    {' '}
                    <Typography variant="h6" fontWeight="bold">
                      İzin Türü
                    </Typography>
                  </InputLabel>
                  <Select
                    multiple
                    renderValue={(selected: any) => {
                      const selectedNames = selected.map((value: string) => {
                        const selectedOption = izinTuru.find(
                          (option) => option.value === value,
                        )
                        return selectedOption ? selectedOption.name : ''
                      })
                      return selectedNames.join(', ')
                    }}
                    value={iznTurValue}
                    onChange={handleSelectionChange}
                    fullWidth
                    error={!!errors?.hspBlg?.iznBlg?.iznTur}
                  >
                    {izinTuru.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={iznTurValue.indexOf(option.value) > -1}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {dateSelection.map(
                  (
                    { value, label, onChange, isDisabled, error, helperText },
                    index,
                  ) => (
                    <Grid key={index} item md={6} sm={12}>
                      <HesapDatePickerComponent
                        isDisabled={isDisabled}
                        label={label}
                        handleDateInput={onChange}
                        value={value}
                        error={error}
                        helperText={helperText}
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            )}
            <AccountPreview
              open={isPreviewDialogOpen}
              data={getValues()}
              handleClose={() => setisPreviewDialogOpen(false)}
              onConfirm={handlePaymentPrevSubmit}
              isLoading={isHesapConnectionLoading}
              showConfirmButton={true}
            />
            <PaymentResultDialog
              openDialog={showPaymentResultDialog}
              onClose={() => setShowPaymentResultDialog(false)}
              onConfirm={handlePaymentPrevSubmit}
              isPaymentDone={isPaymentDone}
              text="Lütfen hesap erişim sonucunun gelmesini bekleyiniz, ekranı kapatmayınız."
              textLong="Mobil cihazınıza bir bildirim geldiğinde, hesap erişim iznini
              onaylayabilirsiniz. Erişim iznini onayladıktan sonra, bu sayfaya geri
              dönerek durumunu görüp, ekranı kapatabilirsiniz."
              textSuccess="Hesap erişim verme işleminiz başarılı bir şekilde gerçekleştirildi."
            />
            <RedirectPrompt
              openDialog={showRedirectConfirmationDialog}
              redirectUrl={`${connectedHesap?.gkd?.hhsYonAdr} `}
              onClose={() => setshowRedirectConfirmationDialog(false)}
              onConfirm={handleRedirectConfirmClick}
              text="Hesap Ekleme Onayı İçin Bankanıza Yönlendirileceksiniz"
            />
            <ErrorSnackbar
              open={showErrorAlert}
              onClose={() => setshowErrorAlert(false)}
              errorMessage={errorMessage}
            />
          </Grid>
        </DialogStepper>
      </DialogContent>
    </Dialog>
  )
}
