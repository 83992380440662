import Card from './Card'
import { Grid, InputLabel, TextField, Typography } from '@mui/material'
import { StepperContainer } from './StepperContainer'
import { useFormContext } from 'react-hook-form'
import { Ihesapler } from '../types/customTypes'
import AutoComplete from './CustomAutoComplete'
import Yetkilendirme from './Yetkilendirme'

function HesapSec() {
  const { register, setValue, watch } = useFormContext<Ihesapler>()
  const options = ['4128']

  const handleSelectionChange = (value: string) => {
    setValue('katilimciBlg.hhsKod', value)
  }

  const handleYetkilendirmeSelection = (value: string) => {
    setValue('gkd.yetYntm', value)
  }

  return (
    <StepperContainer>
      <Card width="90%" p={5} pl={10} alignItems="center" height="200px">
        <Grid container alignContent="center" justifyContent="center">
          <Grid item md={4} xs={12}>
            <InputLabel>
              <Typography variant="h6" fontWeight="bold">
                Yetkilendirme Türü
              </Typography>
            </InputLabel>
            <Yetkilendirme
              onSelect={handleYetkilendirmeSelection}
              value={watch('gkd.yetYntm')}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <InputLabel>
              <Typography variant="h6" fontWeight="bold">
                Ödeme Hizmeti Sağlayıcısı Kodu
              </Typography>
            </InputLabel>
            <TextField
              sx={{ width: '90%', marginTop: 2 }}
              {...register('katilimciBlg.yosKod')}
            />
          </Grid>
          <Grid item md={4} sm={12}>
            <InputLabel>
              <Typography variant="h6" fontWeight="bold">
                Hesap Hizmeti Sağlayıcısı
              </Typography>
            </InputLabel>
            <AutoComplete
              onInput={handleSelectionChange}
              options={options}
              value={watch('katilimciBlg.hhsKod')}
            />
          </Grid>
        </Grid>
      </Card>
    </StepperContainer>
  )
}

export default HesapSec
