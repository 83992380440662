import { Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import StepperCard from '../StepperCard'
import { StepperContainer } from '../StepperContainer'
import { IOdemeHesap } from '../../types/odemeTypes'
import { useFormContext } from 'react-hook-form'
import AutoComplete from '../CustomAutoComplete'

interface props {
  Kurmusal?: JSX.Element
}
export const BireyselStep = ({ Kurmusal }: props) => {
  const { setValue, watch } = useFormContext<IOdemeHesap>()

  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {Kurmusal}
          </Grid>

          <Grid item xs={6}>
            <InputLabel>Gönderen Unvan</InputLabel>
            <AutoComplete
              onInput={(value) => setValue('odmBsltm.gon.unv', value)}
              options={[]}
              value={watch('odmBsltm.gon.unv')}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Gönderen Hesap Numarasi</InputLabel>
            <AutoComplete
              onInput={(value) => setValue('odmBsltm.gon.hspNo', value)}
              options={[]}
              value={watch('odmBsltm.gon.hspNo')}
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export default BireyselStep
