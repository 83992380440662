import { z } from 'zod'

const required = 'Zorunlu'

const hesaplerSchema = z
  .object({
    katilimciBlg: z
      .object({
        hhsKod: z.string().min(1, required),
        yosKod: z.string().min(1, required),
      })
      .required(),
    gkd: z
      .object({
        yetYntm: z.string(),
        yonAdr: z.string(),
      })
      .required(),
    kmlk: z
      .object({
        kmlkTur: z.string().min(1, required),
        kmlkVrs: z.string().min(1, required),
        krmKmlkTur: z.string().optional(),
        krmKmlkVrs: z.string().optional(),
        ohkTur: z.string().min(1, required),
      }),
    hspBlg: z
      .object({
        iznBlg: z.object({
          iznTur: z.array(z.string()).min(1, required),
          erisimIzniSonTrh: z.coerce
            .date()
            .refine((value) => value !== undefined, {
              message: 'erisimIzniSonTrh is required',
            }),
          hesapIslemBslZmn: z.coerce
            .date()
            .refine((value) => value !== undefined, {
              message: 'hesapIslemBslZmn is required',
            }),
          hesapIslemBtsZmn: z.coerce
            .date()
            .refine((value) => value !== undefined, {
              message: 'hesapIslemBtsZmn is required',
            }),
        }),
      })
      .required(),
  })
  .refine(
    (data) => {
      const { ohkTur, krmKmlkVrs } = data.kmlk
      if (ohkTur === 'K') {
        if (krmKmlkVrs === null || krmKmlkVrs === '') {
          return false
        }
      }
      return true
    },
    {
      message: "krmKmlkVrs cannot be empty when ohkTur is 'K'",
      path: ['kmlk.krmKmlkVrs'],
    },
  )

export default hesaplerSchema
