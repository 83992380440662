import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import theme from './theme/theme'

import HomePage from './pages/HomePage'
import IbanPage from './pages/odemeService/IbanPage'

import Bireysal from './pages/hesaplar/bireysel'
import Kurumsal from './pages/hesaplar/kurumsal'
import KolasCorporate from './pages/odemeService/kolasCorporate'
import KolasIndividual from './pages/odemeService/kolasIndividual'
import HesapKurumsal from './pages/odemeService/hesapKurumsal'
import TekSeferlikOdeme from './pages/odemeService/TekSeferlikOdeme/TekSeferlikOdeme'
import KarekodBireysel from './pages/odemeService/karekodBireysel'
import KarekodOdeme from './pages/odemeService/karekodOdeme'
import RedirectPage from './pages/redirect'
import AuthPage from './components/AuthPage'
import LogIn from './pages/authentication/Login'
import Müşteri from './pages/demoPage/odeme/Musteriden'
import Tedarikçi from './pages/demoPage/odeme/Tedarikci'
import RizaNoIptalEt from './pages/hesaplar/rizaNoIptalEt/RizaNoIptalEt'
import KolasTel from './pages/odemeService/kolasTelNo'
import HesapAyrikBireysel from './pages/odemeService/ayrikHesapBireysel'
import HesapAyriKurumsal from './pages/odemeService/ayrikHesapKurumsal'
import AyrikTekSeferlikOdeme from './pages/odemeService/ayrikTekSeferlikOdeme'
import AyrikKolasKurumsal from './pages/odemeService/ayrikKolasKurumsal'
import AyrikKolasBireysel from './pages/odemeService/ayrikKolasBireysel'
import OdemeBireysel from './pages/odemeService/hesapBireyselv2'
import AyrikBireysel from './pages/hesaplar/ayrikBireysel'
import AyrikKurumsal from './pages/hesaplar/ayrikKurumsal'
import OlayOlustur from './pages/olayOlustur'
import MusteriTedarikci from './pages/demoPage/odeme/MusteriTedarikci'
import ViewInvoiceDetails from './pages/demoPage/odeme/ViewInvoiceDetails'
import ParaTransferi from './pages/demoPage/odeme/ParaTransferi'
import AyrikGkdResult from './pages/redirect/AyrikGkdResult'
import BankAccounts from './pages/demoPage/hesap'
import CompanyProfile from './pages/demoPage/companyProfile'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/login" element={<LogIn />} />
            <Route path="/redirect" element={<RedirectPage />} />
            <Route path="/view-invoice" element={<ViewInvoiceDetails />} />

            <Route element={<AuthPage />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/accountInfo" element={<p />} />
              <Route path="/bireysel" element={<Bireysal />} />
              <Route path="/kurumsal" element={<Kurumsal />} />
              <Route path="/işyeri-bilgileri" element={<CompanyProfile />} />

              <Route path="/ayrik-bireysel" element={<AyrikBireysel />} />
              <Route path="/ayrik-kurumsal" element={<AyrikKurumsal />} />
              <Route path="/hesaplar" element={<BankAccounts />} />
              <Route path="/riza-no-iptal-et" element={<RizaNoIptalEt />} />
              <Route path="/individual-account" element={<OdemeBireysel />} />

              {/* New Routes for ÖDEME SERViSLERi sub-items */}

              <Route path="/iban" element={<IbanPage />} />

              <Route path="/bireysel-ayrik" element={<HesapAyrikBireysel />} />
              <Route path="/kurumsal-ayrik" element={<HesapAyriKurumsal />} />

              <Route path="/account-corporate" element={<HesapKurumsal />} />
              <Route path="/kolas-individual" element={<KolasIndividual />} />
              <Route path="/kolas-telno" element={<KolasTel />} />
              <Route path="/kolas-corporate" element={<KolasCorporate />} />
              <Route
                path="/ayrik-kolas-kurumsal"
                element={<AyrikKolasKurumsal />}
              />
              <Route
                path="/ayrik-kolas-bireysel"
                element={<AyrikKolasBireysel />}
              />
              <Route
                path="/ayrik-tek-seferlik"
                element={<AyrikTekSeferlikOdeme />}
              />
              <Route path="/TekSeferlikOdeme" element={<TekSeferlikOdeme />} />

              <Route path="/karekodBireysel" element={<KarekodBireysel />} />
              <Route path="/karekodOdeme" element={<KarekodOdeme />} />
              <Route path="/olay-olustur" element={<OlayOlustur />} />

              <Route path="/Müşteri" element={<Müşteri />} />
              <Route path="/Tedarikçi" element={<Tedarikçi />} />
              <Route path="/musteri-tedarikci" element={<MusteriTedarikci />} />
              {/* <Route path="/para-transferi" element={<ParaTransferi />} /> */}
              <Route path="/para-transferi" element={<ParaTransferi />} />
              <Route path="/payment-result" element={<AyrikGkdResult />} />

              <Route
                path="/viewInvoiceDetails"
                element={<ViewInvoiceDetails />}
              />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
