import { Typography } from '@mui/material'
import DialogInput from './Dialog'

interface DeleteConfirmationProps {
  open: boolean
  handleClose: () => void
  promptMessage: string
  onConfirm: () => void
}

export default function DeleteConfirmation({
  open,
  handleClose,
  promptMessage,
  onConfirm,
}: DeleteConfirmationProps) {
  return (
    <DialogInput
      open={open}
      title="Onayı Sil"
      onClose={handleClose}
      content={<Typography>{promptMessage}</Typography>}
      onConfirm={onConfirm}
    />
  )
}
