import React from 'react'
import { Paper, PaperProps } from '@mui/material'
import { SxProps, Theme } from '@mui/system'

interface CustomPaperProps extends PaperProps {
  sx?: SxProps<Theme>
}

const CustomPaper: React.FC<CustomPaperProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, height: '100%', ...sx }} {...props}>
      {children}
    </Paper>
  )
}

export default CustomPaper
