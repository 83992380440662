import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const getErrorMessageInfo = (
  error: FetchBaseQueryError | SerializedError,
  fallbackErrorText?: string,
) => {
  let err
  if (error && 'data' in error) {
    const errorData: any = error.data
    const { errorCode, httpCode, moreInformationTr, timestamp, httpMessage } =
      errorData

    err = `
        
       ${moreInformationTr} 
         `
  } else {
    err = fallbackErrorText ?? 'Internal server error. Try again'
  }
  return err
}
