import { api } from './api'

const paymentApi = api.injectEndpoints({
  endpoints: (builder) => {
    const sessionId = sessionStorage.getItem('sessionId')
    return {
      getLinkedAccounts: builder.query<any, void>({
        query: () => {
          const headers: Record<string, string> = {}
          if (sessionId) {
            headers['session_id'] = sessionId
          }
          return {
            url: '/connected-hesaplar',
            headers,
          }
        },
      }),
      getLinkedAccountsByBankName: builder.query<any, string>({
        query: (bankName) => ({
          url: `/connected-hesaplar/banks/${bankName}`,
        }),
      }),
    }
  },
})

export const {
  useGetLinkedAccountsQuery,
  useLazyGetLinkedAccountsQuery,
  useLazyGetLinkedAccountsByBankNameQuery,
} = paymentApi
