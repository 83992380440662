import { FormControl, FormLabel, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'

interface HesapDatePickerComponentProps {
  label: string
  handleDateInput: (date: Dayjs) => void
  value: Dayjs
  isDisabled: boolean
  error?: boolean,
  helperText?: string
}

function HesapDatePickerComponent({
  label,
  handleDateInput,
  value,
  isDisabled = false,
  error,
  helperText
}: HesapDatePickerComponentProps) {
  const onDateChange = (date: Dayjs | null) => {
    if (date) {
      const midnightDate = date.startOf('day')
      handleDateInput(midnightDate)
    }
  }

  return (
    <FormControl fullWidth>
      <FormLabel>
        <Typography variant="h6" fontWeight="bold">
          {label}
        </Typography>
      </FormLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={onDateChange}
          readOnly={isDisabled}
          slotProps={{
            textField: {
              error: error, 
              helperText: helperText, 
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  )
}

export default HesapDatePickerComponent
