import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ListItemText } from '@mui/material'
import styled from 'styled-components'
import ScenarioListItems from './sidebarListItems/ScenarioListItems'
import DemoListItems from './sidebarListItems/DemoListItems' // Corrected import
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const SideNavBarContainer = styled.div<{ $isExpanded?: boolean }>`
  width: ${(props) => (props.$isExpanded ? '400px' : '85px')};
  height: 100%;
  position: fixed;
  transition: width 0.4s;
  background: #1e6796;
  color: #f5ecec;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: ${(props) => (props.$isExpanded ? '310px' : '0px')};
  }
`

const Brand = styled.div`
  display: flex;
  gap: 5px;
`

const BrandImage = styled.img<{ $isExpanded?: boolean }>`
  width: 60px;
  height: 60px;
  margin: 10px 10px 10px 0px;

  @media (max-width: 600px) {
    /* width: 0px; */
    width: ${(props) => (props.$isExpanded ? '60px' : '0px')};
    height: ${(props) => (props.$isExpanded ? '60px' : '0px')};
  }
`

const MenuContainer = styled.div`
  margin-left: 5px;
  height: 100vh;

  @media (max-width: 600px) {
    /* margin: 10px 10px 10px 0px; */
  }
`

const MenuItem = styled.div<{ $isExpanded?: boolean; $isSelected?: boolean }>`
  height: 57px;
  width: ${(props) => (props.$isExpanded ? '390px' : '70px')};
  display: flex;
  align-items: center;
  color: #f5ecec;
  text-decoration: none;
  margin: auto 0px;
  gap: 5px;
  border-radius: 10px;
  transition: background-color 0.4s;
  background-color: ${(props) => props.$isSelected && '#0ac775'};

  &:hover {
    background-color: #0ac775;
  }

  @media (max-width: 600px) {
    width: ${(props) => (props.$isExpanded ? '300px' : '0')};
    /* margin: 10px 10px 10px 0px; */
  }
`

const MenuItemIcon = styled.div<{ $isExpanded?: boolean }>`
  width: 20px;
  padding: 0 20px;

  @media (max-width: 600px) {
    display: ${(props) => (props.$isExpanded ? 'block' : 'none')};
  }
`

const SubMenu = styled.div`
  margin-left: 55px;
`

const SubMenuItem = styled.div<{ $isSelected?: boolean }>`
  height: 57px;
  display: flex;
  align-items: center;
  color: #f5ecec;
  margin-right: 6px;
  padding-left: 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.4s;
  background-color: ${(props) => props.$isSelected && '#0ac775'};

  &:hover {
    background-color: #0ac775;
  }
`

const DropdownIcon = styled.div`
  margin-left: auto;
  padding-right: 10px;
`

const DashboardToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #2a8cb7;
  }
`

interface NavbarProps {
  toggleSidebar: () => void
  isExpanded: boolean
}

const SideNavBar = ({ isExpanded }: NavbarProps) => {
  const [openDropdowns, setOpenDropdowns] = useState<number[]>([])
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  )
  const [selectedSubItemIndices, setSelectedSubItemIndices] = useState<{
    [key: number]: number | null
  }>({})

  const [currentDashboard, setCurrentDashboard] = useState<'Senaryo' | 'Demo'>(
    (sessionStorage.getItem('currentDashboard') as 'Senaryo' | 'Demo') ??
      'Senaryo',
  )
  const [isMobileView, setIsMobileView] = useState(false)

  const handleToggleDropdown = (index: number) => {
    setOpenDropdowns((prevOpenDropdowns) =>
      prevOpenDropdowns.includes(index)
        ? prevOpenDropdowns.filter((item) => item !== index)
        : [...prevOpenDropdowns, index],
    )
  }

  const handleMenuItemClick = (index: number) => {
    setSelectedSubItemIndices({})
    setSelectedItemIndex(index)
  }

  const handleSubMenuItemClick = (parentIndex: number, subIndex: number) => {
    setSelectedItemIndex(null)
    const clearedIndices = { ...selectedSubItemIndices }
    Object.keys(clearedIndices).forEach((key) => {
      clearedIndices[parseInt(key)] = null
    })
    setSelectedSubItemIndices({
      ...clearedIndices,
      [parentIndex]: subIndex,
    })
  }

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth < 600) // Example threshold for mobile view
    }
    window.addEventListener('resize', handleResize)
    handleResize() // Initial check
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const dashboardItems = DemoListItems

  return (
    <SideNavBarContainer $isExpanded={isExpanded}>
      {/* {!isMobileView && ( */}
      <Brand>
        <BrandImage
          $isExpanded={isExpanded}
          src={
            'https://www.fincloudy.com/wp-content/uploads/2024/01/fincloudy-logo.svg'
          }
        />
        {isExpanded && <h2 style={{ color: 'white' }}>Fincloudy</h2>}
      </Brand>
      {/* )} */}

      <MenuContainer>
        {dashboardItems.map(({ text, icon, link, subItems }, index) => (
          <div key={text}>
            {subItems ? (
              <div>
                <MenuItem
                  $isExpanded={isExpanded}
                  onClick={() => handleToggleDropdown(index)}
                  $isSelected={index === selectedItemIndex}
                >
                  <MenuItemIcon $isExpanded={isExpanded}>{icon}</MenuItemIcon>
                  {isExpanded && <ListItemText primary={text} />}
                  {subItems && isExpanded && (
                    <DropdownIcon>
                      {openDropdowns.includes(index) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </DropdownIcon>
                  )}
                </MenuItem>
                {openDropdowns.includes(index) && isExpanded && (
                  <SubMenu>
                    {subItems.map(({ text, link }, subIndex) => (
                      <Link to={link} key={text}>
                        <SubMenuItem
                          $isSelected={
                            selectedSubItemIndices[index] === subIndex
                          }
                          onClick={() =>
                            handleSubMenuItemClick(index, subIndex)
                          }
                        >
                          <ListItemText primary={text} />
                        </SubMenuItem>
                      </Link>
                    ))}
                  </SubMenu>
                )}
              </div>
            ) : (
              <Link to={link} key={text}>
                <MenuItem
                  $isExpanded={isExpanded}
                  onClick={() => handleMenuItemClick(index)}
                  $isSelected={index === selectedItemIndex}
                >
                  <MenuItemIcon $isExpanded={isExpanded}>{icon}</MenuItemIcon>
                  {isExpanded && <ListItemText primary={text} />}
                </MenuItem>
              </Link>
            )}
          </div>
        ))}
      </MenuContainer>
    </SideNavBarContainer>
  )
}

export default SideNavBar
