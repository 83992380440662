import React from 'react'
import { Grid, Typography, Box, IconButton } from '@mui/material'
import PageTitle from './PageTitle'

interface PageHeaderProps {
  title: string
  firstTitle: string
  secondTitle?: string
  firstButton: React.ReactNode
  secondButton?: React.ReactNode
  onFirstButtonClick?: () => void // Callback for first button click
  onSecondButtonClick?: () => void // Callback for second button click
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  firstTitle,
  secondTitle,
  firstButton,
  secondButton,
  onFirstButtonClick,
  onSecondButtonClick,
}) => {
  const hasSecondContent = secondTitle && secondButton

  return (
    <Grid
      container
      spacing={2}
      width="100%"
      marginBottom="20px"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <PageTitle title={title} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent={!hasSecondContent ? 'flex-end' : 'space-between'}
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          width="100%"
          justifyContent={!hasSecondContent ? 'flex-end' : 'flex-start'}
        >
          <Typography
            variant="body1"
            component="div"
            sx={{
              backgroundImage: 'linear-gradient(135deg, #3f51b5, #2196f3)',
              color: '#fff',
              padding: '8px',
              borderRadius: '8px',
              marginRight: { xs: '0', sm: '8px' },
              marginBottom: { xs: '8px', sm: '0' },
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: { xs: '100%', sm: 'auto' },
              fontSize: '20px',
              fontWeight: 'bold',
            }}
            onClick={onFirstButtonClick} // Attach click handler directly to Typography if needed
          >
            {firstTitle}
            {firstButton}
          </Typography>
          {hasSecondContent && (
            <Typography
              variant="body1"
              component="div"
              sx={{
                backgroundImage: 'linear-gradient(135deg, #ff9800, #ff5722)',
                color: '#fff',
                padding: '8px',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: { xs: '100%', sm: 'auto' },
              }}
              onClick={onSecondButtonClick} // Attach click handler directly to Typography if needed
            >
              {secondTitle}
              {secondButton}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default PageHeader
