import {
  Button,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  useGetRizaNoQuery,
  useLazyHesapRizaIptalQuery,
} from '../../../api/hesaplarApi'
import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import Card from '../../../components/Card'
import DeleteConfirmation from '../../../components/DeleteConfirmation'
import FeedbackAlert from '../../../components/FeedbackAlert'

interface FeedbackState {
  variant: 'success' | 'error'
  message: string
  isOpen: boolean
}

export default function RizaNoIptalEt() {
  const [rizaNo, setRizaNo] = useState('')
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false)
  const [feedback, setFeedback] = useState<FeedbackState>({
    variant: 'success',
    message: '',
    isOpen: false,
  })
  const [
    deleteConsentNumber,
    {
      isSuccess: isConsentNumberDeleteSuccess,
      isError: isConsentNumberDeleteError,
      error: consentNumberDeleteError,
      data: deletedConsentNumber,
    },
  ] = useLazyHesapRizaIptalQuery()

  const { data: rizaNos } = useGetRizaNoQuery()

  const handleDeleteConfirmation = () => {
    deleteConsentNumber(rizaNo)
  }

  useEffect(() => {
    if (isConsentNumberDeleteSuccess) {
      setShowConfirmationPrompt(false)
      setFeedback({
        ...feedback,
        variant: 'success',
        message: 'Rıza no başarılı bir şekilde silindi.',
        isOpen: true,
      })
    } else if (isConsentNumberDeleteError && consentNumberDeleteError) {
      let err
      if (consentNumberDeleteError && 'data' in consentNumberDeleteError) {
        const errorData: any = consentNumberDeleteError.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setFeedback({ ...feedback, message: err, isOpen: true, variant: 'error' })
    }
    setShowConfirmationPrompt(false)
  }, [
    isConsentNumberDeleteSuccess,
    isConsentNumberDeleteError,
    consentNumberDeleteError,
  ])

  const closeFeedbackDialog = () => {
    setFeedback({ ...feedback, isOpen: false })
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold">
        Riza No Iptal Et
      </Typography>
      <Card width="50%" margin="auto" mt={10}>
        <Select
          sx={{ width: '90%' }}
          value={rizaNo}
          onChange={(e) => setRizaNo(e.target.value)}
        >
          {!rizaNos?.length && (
            <MenuItem value="">
              <ListItemText primary="No riza Number" />
            </MenuItem>
          )}
          {rizaNos?.map((rizaNo: string, index: number) => (
            <MenuItem key={index} value={rizaNo}>
              <ListItemText primary={rizaNo} />
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          sx={{
            width: '20%',
            marginTop: 2,
            marginRight: 5,
            alignSelf: 'flex-end',
            height: '40px',
          }}
          disabled={!rizaNo}
          onClick={() => setShowConfirmationPrompt(true)}
        >
          Submit
        </Button>
      </Card>
      {showConfirmationPrompt && (
        <DeleteConfirmation
          open={showConfirmationPrompt}
          handleClose={() => setShowConfirmationPrompt(false)}
          promptMessage="Seçilen rıza numarası silenecektir. Onaylıyor musunuz?"
          onConfirm={handleDeleteConfirmation}
        />
      )}
      <FeedbackAlert
        open={feedback.isOpen}
        message={feedback.message}
        buttonText="Kapat"
        linkPath="/riza-no-iptal-et"
        variant={feedback.variant}
        onClose={closeFeedbackDialog}
        buttonAction={closeFeedbackDialog}
      />
    </Box>
  )
}
