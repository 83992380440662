import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import Iban from '../../../components/odeme/Iban'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import { Grid, InputLabel } from '@mui/material'
import { useStepperControlContext } from '../../../components/StepContextProvider'
import StepContentRenderer from '../../../components/StepContentRenderer'
import { StepperContainer } from '../../../components/StepperContainer'
import StepperCard from '../../../components/StepperCard'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import { useEffect, useState } from 'react'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import { IOdemeAyrık } from '../../../types/odemeTypes'
import AutoComplete from '../../../components/CustomAutoComplete'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { odemeAyrikDefaultValues } from '../../../types/formDefaultValues'

const kolasBireyselFormValues = 'kolasBireyselAyrik'

const kolasIndividualDefault: IOdemeAyrık = {
  ...odemeAyrikDefaultValues,
  gkd: {
    ...odemeAyrikDefaultValues.gkd,
    ayrikGkd: {
      ...odemeAyrikDefaultValues.gkd.ayrikGkd,
      ohkTanimDeger: '98460870226',
    },
  },
  odmBsltm: {
    ...odemeAyrikDefaultValues.odmBsltm,
    islTtr: {
      ...odemeAyrikDefaultValues.odmBsltm.islTtr,
      ttr: '0.09',
    },
    kmlk: {
      ...odemeAyrikDefaultValues.odmBsltm.kmlk,
      kmlkVrs: '98460870226',
      kmlkTur: 'K',
    },
    alc: {
      kolas: {
        kolasDgr: '56402004528',
        kolasTur: 'K',
      },
    },
    gon: {
      hspNo: 'TR800412803414221510441663',
      unv: 'h4128 u77920186268',
    },
    odmAyr: {
      ...odemeAyrikDefaultValues.odmBsltm.odmAyr,
      odmAcklm: 'Ayrık Kolas Bireysel',
      refBlg: 'Y-2701852-202011',
    },
  },
}

const KolasTCKN = () => {
  const { handleNextClick, handleBackClick } = useStepperControlContext()
  const { setValue, watch, register } = useFormContext<IOdemeAyrık>()
  const onNextButtonClick = () => {
    handleNextClick()
  }
  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Alıcı TCKN</InputLabel>
            <AutoComplete
              onInput={(value) =>
                setValue('odmBsltm.alc.kolas.kolasDgr', value)
              }
              options={[]}
              value={watch('odmBsltm.alc.kolas.kolasDgr')}
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export const components = [
  {
    index: 0,
    label: 'Ödeme Hizmeti Kullanıcısı Türü Seçimi',
    component: <Iban />,
  },
  {
    index: 1,
    label: 'Kolas TCKN',
    component: <KolasTCKN />,
  },
  {
    index: 2,
    label: 'Ödeme Adım 3',
    component: <OdemeStep_2 />,
  },
  {
    index: 3,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 4,
    label: 'Senaryo Tipi Seç',
    component: <Scenarios />,
  },
]

function AyrikKolasBireysel() {
  const [odemeBireysel, { isSuccess, isLoading, isError, error, data }] =
    useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  const [defaultValues] = useState<IOdemeAyrık>(() => {
    const storedValues = window.sessionStorage.getItem(kolasBireyselFormValues)
    if (storedValues) {
      // Parse the stored values
      const parsedValues: IOdemeAyrık = JSON.parse(storedValues)
      return parsedValues
    } else {
      return kolasIndividualDefault
    }
  })
  const formMethods = useForm<IOdemeAyrık>({
    defaultValues: defaultValues,
    // resolver: zodResolver(hesaplerSchema),
  })
  const { watch, setValue, getValues } = formMethods

  useFormPersist(kolasBireyselFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const handleSubmitButtonClick = (data: any) => {
    const scenarioType = getValues('scenarioType')
    odemeBireysel({ formData: data, scenarioType })
  }

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    } else if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepContentRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default AyrikKolasBireysel
