import { GridRowId } from '@mui/x-data-grid'
import { api } from './api'

interface SendEmailRequest {
  userIds: GridRowId[]
}

interface SendEmailResponse {
  success: boolean
  message?: string
}

const sendEmailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation<SendEmailResponse, SendEmailRequest>({
      query: ({ userIds }) => ({
        url: `http://localhost:3001/users/sendEmail`,
        method: 'POST',
        body: { userIds }, // Wrap userIds in an object if needed
      }),
      extraOptions: { maxRetries: 0 },

    }),
  }),
})

export const { useSendEmailMutation } = sendEmailApi
