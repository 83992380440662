import { createSlice } from '@reduxjs/toolkit'
import { getCookie } from '../utils/getCookie'

interface IUserState {
  access_token: string
  refresh_token: string
  session_id: string
}

const initialState: IUserState = {
  access_token: '',
  refresh_token: '',
  session_id: '',
}

export const authenticationSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logOut: () => initialState,
    setCredentials: (state, action) => {
      console.log('in set credential')

      const { access_token, refresh_token, session_id } = action.payload
      document.cookie = `accessToken=${access_token}; path=/; max-age=3600`
      document.cookie = `refreshToken=${refresh_token}; path=/; max-age=7200`
      sessionStorage.setItem('sessionId', session_id)
      sessionStorage.setItem('session', 'session')
    },
    setUserInfo: (state, action) => {
      const { id, email, name, surname, team_id } = action.payload
      const userInfo = { id, email, name, surname, team_id }
      document.cookie = `userInfo=${userInfo}; path=/; max-age=3600`
    },
  },
})

export default authenticationSlice.reducer
export const { logOut, setCredentials, setUserInfo } =
  authenticationSlice.actions
export const selectAccessToken = () => getCookie('accessToken')
export const selectRefreshToken = () => getCookie('refreshToken')
export const selectSessionId = () => getCookie('sessionId')
