import { Typography } from '@mui/material'
import { IChildren } from '../types/customTypes'

export function PreviewField({
  label,
  value,
}: {
  label: string
  value: string | string[] | undefined
  props?: any
}) {
  return (
    <Typography variant="body1" fontSize="18px">
      <strong>{label}:</strong>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'   }}>
        {Array.isArray(value) ? (
          value.map((item, index) => (
            <span key={index} style={{ display: 'block' }}>
              • {item}
            </span>
          ))
        ) : (
          <span>{value}</span>
        )}
      </div>
    </Typography>
  )
}

export function PreviewTitle({ children }: IChildren) {
  return (
    <Typography variant="h6" gutterBottom fontWeight="bold" mt={3}>
      {children}
    </Typography>
  )
}
