import { api } from './api'

const odemeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    odemeHesap: builder.mutation({
      query: ({ formData }) => ({
        url: `https://devtest.fincloudy.com/api/v1/odeme-emri-rizasi`,
        method: 'POST',
        body: formData,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    getPaymentResultByRizaNo: builder.query({
      query: ({ rizaNo }) => ({
        url: `https://devtest.fincloudy.com/api/v1/odeme-emri-rizasi/${rizaNo}`,
        method: 'GET',
      }),
    }),

    getAllOdeme: builder.query({
      query: () => ({
        url: `https://devtest.fincloudy.com/api/v1/odeme-emirleri`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useOdemeHesapMutation,
  useLazyGetPaymentResultByRizaNoQuery,
  useLazyGetAllOdemeQuery,
} = odemeApi
