import { Container, Grid, Typography, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useLocation } from 'react-router-dom'
import { trTR } from '@mui/x-data-grid/locales'
import { useEffect, useState } from 'react'
import { useLazyGetInvoiceQuery } from '../../../api/invoiceApi'

interface InvoiceDetailItemProps {
  label: string
  value: any
}
interface TitleProps {
  title: string
}
function InvoiceDetailItem({ label, value }: InvoiceDetailItemProps) {
  if (!value) {
    return null
  }

  return (
    <Grid container alignItems="flex-end">
      <Typography
        component="span"
        sx={{
          fontWeight: 'bold',
          paddingRight: '10px',
          marginTop: '2px',
          fontSize: '15px',
        }}
      >
        {label}:
      </Typography>
      <Typography component="span">{value}</Typography>
    </Grid>
  )
}

function Title({ title }: TitleProps) {
  return (
    <Typography variant="h6" gutterBottom style={{ color: '#0047AB' }}>
      {title}
    </Typography>
  )
}
enum InvoiceStatusTypes {
  AWAITING_PAYMENT = 0,
  PAID,
  CANCELLED,
}
export interface IInvoiceLine {
  id: string
  quantity: string
  lineExtensionAmount: string
  lineTaxTotal: string
  lineTaxSubTotal: string
  lineTaxPercentage: string
  productName: string
  unitPrice: string
  lineTotal: string
  lineSubtotal: string
}
export interface IInvoice {
  edm_id: string
  sender?: string
  receiver?: string
  supplier?: string
  customer?: string
  customer_id?: string

  supplier_id: string

  issue_date?: string
  payable_amount?: string
  payable_amount_currencyid?: string
  from?: string
  to?: string
  profileid?: string
  status?: string
  status_description?: string
  gib_status_code?: string
  gib_status_description?: string
  response_code?: string
  response_description?: string
  filename?: string
  hash?: string
  cdate?: string
  internetsales?: string
  earchive?: string
  export_gtb_refno?: string
  export_gtb_gcb_tescilno?: string
  invoice_type?: string
  invoice_send_type?: string
  earchive_report_senddate?: string
  earchive_report_status?: string
  earchive_report_statusdesc?: string
  cancel_earchive_report_senddate?: string
  cancel_earchive_report_statusdesc?: string
  cancel_earchive_report_status?: string
  reason_message?: string
  taxExclusiveAmount?: string
  taxInclusiveAmount?: string
  taxAmount?: string
  invoiceLines: IInvoiceLine[]
  fincloudy_status: InvoiceStatusTypes
  customer_info: any[]
  supplier_info: any[]
}
function ViewInvoiceDetails() {
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>()
  const [getInvoice, { isSuccess, data, isError, isLoading }] =
    useLazyGetInvoiceQuery()

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const params = Object.fromEntries(urlParams.entries())

    console.log('params', params)
    getInvoice(params.id)
  }, [])
  console.log('isLoading', isLoading)
  useEffect(() => {
    console.log('data', data)
    if (data && data.length > 0) {
      setSelectedInvoice(data[0])
    }
  }, [data])
  console.log('selectedInvoice', selectedInvoice)
  const invoiceLines = selectedInvoice?.invoiceLines
  const supplier = selectedInvoice?.supplier_info[0]
  const customer = selectedInvoice?.customer_info[0]

  const columns = [
    { field: 'productName', headerName: 'Mal Hizmet', width: 300 },
    { field: 'quantity', headerName: 'Miktar', width: 200 },
    { field: 'lineSubtotal', headerName: 'Birim Fiyat', width: 150 },
    { field: 'lineTaxPercentage', headerName: 'KDV Oranı', width: 150 },
    { field: 'lineTaxTotal', headerName: 'KDV Tutarı', width: 150 },
    {
      field: 'lineTotal',
      headerName: 'Mal Hizmet Tutarı',
      width: 200,
    },
  ]

  const formatAddress = (address: any) => {
    if (!address) return null

    const parts = [
      address.streetName,
      address.citySubdivisionName,
      address.cityName,
      address.postalZone,
      address.countryName,
    ]

    return parts.filter((part) => part).join(', ')
  }

  const handlePayNow = () => {
    alert('Şimdi Öde butonuna tıklandı')
  }

  return (
    <Container style={{ marginTop: '125px' }}>
      {selectedInvoice && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Title title="Fatura Bilgileri" />

            <InvoiceDetailItem
              label="Fatura No"
              value={selectedInvoice?.edm_id}
            />
            <InvoiceDetailItem
              label="Fatura Tarihi"
              value={selectedInvoice?.issue_date}
            />
            <InvoiceDetailItem
              label="Senaryo"
              value={selectedInvoice?.profileid}
            />
            <InvoiceDetailItem
              label="Fatura Tipi"
              value={selectedInvoice?.invoice_type}
            />
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: '20px',
                textTransform: 'none',
                backgroundColor: 'green',
                fontSize: '24px',
              }}
              onClick={handlePayNow}
            >
              Şimdi Öde
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Title title="Müşteri Detayları" />

            <InvoiceDetailItem
              label="Müşteri Adı"
              value={customer?.companyPartyName}
            />
            <InvoiceDetailItem label="VKN" value={customer?.companySchemeId} />
            <InvoiceDetailItem label="Telefon" value={customer?.companyPhone} />
            <InvoiceDetailItem label="E-Posta" value={customer?.companyEmail} />
            <InvoiceDetailItem
              label="Müşteri Adresi"
              value={formatAddress(customer?.companyAddress)}
            />
          </Grid>

          <Grid item xs={8}>
            <Title title="Tedarikçi Detayları" />

            <InvoiceDetailItem
              label="Tedarikçi Adı"
              value={supplier?.companyPartyName}
            />
            <InvoiceDetailItem label="VKN" value={supplier?.companySchemeId} />
            <InvoiceDetailItem label="Telefon" value={supplier?.companyPhone} />
            <InvoiceDetailItem label="E-Posta" value={supplier?.companyEmail} />
            <InvoiceDetailItem
              label="Tedarikçi Adresi"
              value={formatAddress(supplier?.companyAddress)}
            />
          </Grid>
        </Grid>
      )}
      {selectedInvoice && (
        <div
          style={{
            height: 400,
            width: '100%',
            marginTop: '15px',
            marginBottom: '25px',
          }}
        >
          <DataGrid
            rows={invoiceLines!}
            columns={columns}
            getRowId={(row) => row.quantity}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              boxShadow: 1,
            }}
          />
        </div>
      )}
    </Container>
  )
}

export default ViewInvoiceDetails
