import { Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import StepperCard from '../StepperCard'
import { StepperContainer } from '../StepperContainer'
import AutoComplete from '../CustomAutoComplete'
import { useFormContext } from 'react-hook-form'
import { IOdemeHesap } from '../../types/odemeTypes'

export const Hesap = () => {
  const { setValue, watch } = useFormContext<IOdemeHesap>()

  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>Alıcı Hesap Numarasi</InputLabel>
            <AutoComplete
              onInput={(value) => setValue('odmBsltm.alc.hspNo', value)}
              options={[]}
              value={watch('odmBsltm.alc.hspNo')}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Alıcı Ünvani</InputLabel>
            <AutoComplete
              onInput={(value) => setValue('odmBsltm.alc.unv', value)}
              options={[]}
              value={watch('odmBsltm.alc.unv')}
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export default Hesap
