import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material'
import AutoComplete from '../../../../components/CustomAutoComplete'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { amaciItems } from '../../../../components/odeme/OdemeAraci'
import { useForm } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'

import { paymentSchema } from '../../../../validation/paymentValidation'
import { ZodError } from 'zod'
import { useLazyGetLinkedAccountsQuery } from '../../../../api/paymentApi'
import CustomStepper from './CustomStepper'
import { getErrorMessageInfo } from '../../../../utils/error'
import ErrorSnackbar from '../../../../components/ErrorSnackbar'
import PaymentPreviewDialog from '../PaymentPreview'
import {
  useLazyGetPaymentResultByRizaNoQuery,
  useOdemeHesapMutation,
} from '../../../../api/odemeApi'
import RedirectPrompt from '../../../../components/RedirectPrompt'
import PaymentResultDialog from '../../../../components/PaymentResultDialog'

interface StepperDialogProps {
  open: boolean
  isPreviewDialogOpen: boolean
  openPreviewDialog: () => void
  closePreviewDialog: () => void
  handleClose: () => void
  onSubmission: (paymentData: IPayment) => void
  paymentDataToAutofill: { supplier: string; payable_amount: number }
}

const steps = ['Gönderen Bilgileri', 'Ödeme Bilgileri', 'Onay']
export const ayrikChoices = [
  {
    label: 'TCKN',
    value: 'TCKN',
  },
  {
    label: 'GSM',
    value: 'GSM',
  },
]

export default function PaymentForm({
  open,
  handleClose,
  isPreviewDialogOpen,
  openPreviewDialog,
  closePreviewDialog,
}: StepperDialogProps) {
  const [activeStep, setActiveStep] = useState(0)
  const [hesaplar, setHesaplar] = useState<string[]>([])
  const [accounts, setAccounts] = useState<[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [isMobilAppAvailable, setIsMobilAppAvailable] = useState<boolean>(false)
  const [rizaNo, setRizaNo] = useState<string>('')
  const [showPaymentResultDialog, setShowPaymentResultDialog] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [selectedAyrik, setSelectedAyrik] = useState('')

  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const handleTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (value === 'hesapNumarasi' || value === 'karekod' || value === 'kolas')
      setValue('paymentMethod', value)
  }

  useEffect(() => {
    setValue('gkd.ayrikGkd.ohkTanimTip', 'TCKN')
  }, [])
  const [
    getLinkedAccount,
    {
      isSuccess: isGetLinkedAccountSuccess,
      isError: isGetLinkedAccountError,
      data: linkedAccounts,
      error: linkedAccountError,
    },
  ] = useLazyGetLinkedAccountsQuery()
  const [
    submitPayment,
    {
      isSuccess: isPaymentSubmitSuccess,
      isError: isPaymentSubmitError,
      error: paymentError,
      isLoading: isPaymentSubmitLoading,
      data: paymentSubmitResult,
    },
  ] = useOdemeHesapMutation()

  const [
    getPaymentResultByRizaNo,
    {
      isSuccess: isGetPaymentResultSuccess,
      isError: isGetPaymentResultError,
      data: paymentResult,
      error: paymentResultError,
    },
  ] = useLazyGetPaymentResultByRizaNoQuery()
  const handleRedirectConfirmClick = () => {
    window.open(paymentSubmitResult?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  useEffect(() => {
    if (isMobilAppAvailable) {
      setValue('gkd.yetYntm', 'A')
      setValue('gkd.ayrikGkd.ohkTanimTip', 'TCKN')
    } else {
      setValue('gkd.yetYntm', 'Y')
      setValue('gkd.ayrikGkd.ohkTanimTip', '')

      setValue('gkd.ayrikGkd.ohkTanimDeger', '')
    }
  }, [isMobilAppAvailable])

  useEffect(() => {
    if (isPaymentSubmitError && paymentError) {
      closePreviewDialog()
      const errMessage = getErrorMessageInfo(paymentError)
      setErrorMessage(errMessage)
      setShowErrorAlert(true)
    } else if (
      isPaymentSubmitSuccess &&
      !isPaymentSubmitError &&
      paymentSubmitResult
    ) {
      setErrorMessage('')

      openPreviewDialog()
    }
  }, [
    isPaymentSubmitSuccess,
    isPaymentSubmitError,
    isPaymentSubmitLoading,
    paymentSubmitResult,
    paymentError,
  ])

  useEffect(() => {
    if (paymentSubmitResult) {
      setRizaNo(paymentSubmitResult?.rzBlg?.rizaNo)
    }
  }, [paymentSubmitResult])

  useEffect(() => {
    let intervalId: any
    if (
      paymentSubmitResult?.gkd.yetYntm === 'A' ||
      (paymentResult?.gkd.yetYntm === 'A' && rizaNo)
    ) {
      const checkPaymentResult = () => {
        if (paymentResult?.rzBlg?.rizaDrm !== 'E') {
          getPaymentResultByRizaNo({ rizaNo })
        } else {
          clearInterval(intervalId)
          setIsPaymentDone(true)
        }
      }

      checkPaymentResult()

      intervalId = setInterval(checkPaymentResult, 60000)
      return () => clearInterval(intervalId)
    }
  }, [
    rizaNo,
    paymentSubmitResult,
    paymentResult?.rzBlg?.rizaDrm,
    getPaymentResultByRizaNo,
  ])

  useEffect(() => {
    if (errorMessage) {
      setShowErrorAlert(true)
    }
  }, [errorMessage])

  const [hesap, setHesap] = useState('')
  const [errorStep, setErrorStep] = useState(-1)

  const paymentForm = 'transferMoney'

  const getSavedData = useCallback(() => {
    let data = sessionStorage.getItem(paymentForm)
    if (data) {
      // Parse it to a JavaScript object
      try {
        data = JSON.parse(data)
      } catch (err) {
        console.log(err)
      }
      return data as unknown as IPayment
    } else {
      return defaultValues
    }
  }, [defaultValues])

  const formMethods = useForm<IPayment>({
    defaultValues: getSavedData(),
  })

  const {
    watch,
    setValue,
    register,
    unregister,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = formMethods

  useFormPersist(paymentForm, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const paymentMethod = watch('paymentMethod')

  const onSubmit = async () => {
    try {
      const paymentFormData = getValues()
      console.log('first payment', paymentFormData)

      paymentSchema.parse(paymentFormData)
      if (paymentMethod === 'hesapNumarasi') {
        delete paymentFormData.odmBsltm.alc.kolas
        unregister('odmBsltm.kkod')
      } else if (paymentMethod === 'karekod') {
        delete paymentFormData.odmBsltm.alc.kolas
      } else if (paymentMethod === 'kolas') {
        delete paymentFormData.odmBsltm.kkod
        delete paymentFormData.odmBsltm.alc.hspNo
        delete paymentFormData.odmBsltm.alc.unv
      }
      if (!isMobilAppAvailable) {
        paymentFormData.gkd.yetYntm = 'Y'
        delete paymentFormData.gkd.ayrikGkd
      }

      if (!paymentFormData.odmBsltm.gon?.unv) {
        delete paymentFormData?.odmBsltm.kmlk?.kmlkVrs
        delete paymentFormData?.odmBsltm.kmlk?.kmlkTur
        delete paymentFormData?.odmBsltm.kmlk?.krmKmlkTur
        delete paymentFormData?.odmBsltm.kmlk?.krmKmlkVrs

        delete paymentFormData.odmBsltm.gon
      } else {
        if (paymentFormData?.odmBsltm?.kmlk?.kmlkVrs !== '') {
          if (paymentFormData.odmBsltm.kmlk) {
            paymentFormData.odmBsltm.kmlk.kmlkTur = 'K'
          }
        }

        if (
          paymentFormData?.odmBsltm?.kmlk?.krmKmlkVrs !== '' &&
          paymentFormData?.odmBsltm?.kmlk?.ohkTur === 'K'
        ) {
          if (paymentFormData.odmBsltm.kmlk) {
            paymentFormData.odmBsltm.kmlk.krmKmlkTur = 'V'
          }
        }
      }

      await submitPayment({ formData: paymentFormData })
      setErrorStep(-1)
    } catch (error) {
      if (error instanceof ZodError) {
        console.log('Validate error', error.errors)
        setErrorStep(formFieldToStepMapping[error.errors[0].path.join('.')])

        error.errors.forEach((zodError) => {
          if (zodError.path.length > 0) {
            setError(zodError.path.join('.') as keyof IPayment, {
              type: 'manual',
              message: zodError.message,
            })
          }
        })
      } else {
        console.error('error:', error)
      }
    }
  }

  const handleAccountTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    console.log('in change', value)
    setValue('odmBsltm.kmlk.ohkTur', value)
    if (value === 'B') {
      unregister('odmBsltm.kmlk.krmKmlkTur')
      setValue('odmBsltm.kmlk.krmKmlkTur', 'K')
    } else if (value === 'K') {
      register('odmBsltm.kmlk.krmKmlkTur')
      setValue('odmBsltm.kmlk.krmKmlkTur', 'V')
    }
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onSubmit()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleInputTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (value === 'autoFill' || value === 'userFill') {
      setValue('inputChoice', value)
    }
  }

  const inputChoice = watch('inputChoice')

  useEffect(() => {
    if (inputChoice === 'autoFill' && !linkedAccounts) {
      getLinkedAccount()
    }
  }, [inputChoice])

  useEffect(() => {
    if (isGetLinkedAccountSuccess && linkedAccounts) {
      const accounts = linkedAccounts[0]?.accounts
      setAccounts(accounts)

      // Use a Set to ensure unique values
      const uniqueHesaplar = new Set(hesaplar)

      accounts?.forEach((account: any) => {
        if (account?.hspTml?.hspNo) {
          uniqueHesaplar.add(account.hspTml.hspNo)
        }
      })

      setHesaplar(Array.from(uniqueHesaplar))
    } else if (isGetLinkedAccountError) {
      let errMsg = ''
      if (linkedAccountError) {
        errMsg = getErrorMessageInfo(
          linkedAccountError,
          'Bağlı hesaplar getirilirken hata oluştu',
        )
      }
      setErrorMessage(errMsg)
    }
  }, [
    isGetLinkedAccountSuccess,
    linkedAccounts,
    isGetLinkedAccountError,
    linkedAccountError,
  ])

  const autoFillSenderInfo = () => {
    const foundAccount = accounts.find(
      (account: IAccount) => account.hspTml.hspNo === hesap,
    )

    if (foundAccount) {
      const {
        hspTml: {
          hspNo,
          hspShb,
          rizaDetails: { kmlkVrs, krmKmlkVrs, ohkTur },
        },
      } = foundAccount

      setValue('odmBsltm.gon.unv', hspShb)
      setValue('odmBsltm.gon.hspNo', hspNo)
      setValue('odmBsltm.kmlk.kmlkVrs', kmlkVrs)
      setValue('odmBsltm.kmlk.ohkTur', ohkTur)
      setValue('gkd.ayrikGkd.ohkTanimDeger', kmlkVrs)

      ohkTur === 'K' && setValue('odmBsltm.kmlk.krmKmlkVrs', krmKmlkVrs)
    }
  }

  useEffect(() => {
    autoFillSenderInfo()
  }, [hesap])

  const handleSubmit = () => {
    if (isPaymentSubmitSuccess && paymentSubmitResult) {
      setErrorMessage('')
      const { hhsYonAdr, yetYntm } = paymentSubmitResult.gkd

      if (yetYntm === 'Y' && hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      } else if (yetYntm === 'A') setShowPaymentResultDialog(true)
    }
  }

  const kolasTuru = [
    { label: 'Telefon numarası', value: 'T' },
    { label: 'E-posta', value: 'E' },
    { label: 'TCKN', value: 'K' },
    { label: 'VKN', value: 'V' },
    { label: 'YKN', value: 'Y' },
    { label: 'Pasaport Numarası', value: 'P' },
  ]

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle variant="h6" fontWeight="bold">
          Para Transferi
        </DialogTitle>
        <DialogContent content="500px" sx={{ m: 2 }}>
          <Box
            height="770px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <CustomStepper
                activeStep={activeStep}
                steps={steps}
                errorStep={errorStep}
              />
              {activeStep === 0 && (
                <Grid container spacing={2}>
                  <Grid item sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isMobilAppAvailable}
                          onChange={(event) =>
                            setIsMobilAppAvailable(event?.target.checked)
                          }
                        />
                      }
                      label="Mobil Uygulama Var"
                    />
                  </Grid>
                  {isMobilAppAvailable && (
                    <>
                      <Grid item xs={6} md={6}>
                        <InputLabel>Kimlik Doğrulama Metodu</InputLabel>
                        <Select
                          value={selectedAyrik}
                          fullWidth
                          onChange={(e) => {
                            console.log('onchange', e.target.value)
                            setValue('gkd.ayrikGkd.ohkTanimTip', e.target.value)
                            setSelectedAyrik(e.target.value)
                          }}
                          label="Kimlik Doğrulama Metodu"
                          sx={{ mt: 2 }}
                        >
                          {ayrikChoices.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <InputLabel id="odemeAmaci">
                          Kimlik Doğrulama Değeri
                        </InputLabel>
                        <TextField
                          margin="normal"
                          {...register('gkd.ayrikGkd.ohkTanimDeger')}
                          onChange={(e) => {
                            setValue(
                              'gkd.ayrikGkd.ohkTanimDeger',
                              e.target.value,
                            )
                            clearErrors('gkd.ayrikGkd.ohkTanimDeger')
                          }}
                          fullWidth
                          error={!!errors?.gkd?.ayrikGkd?.ohkTanimDeger}
                          helperText={
                            errors?.gkd?.ayrikGkd?.ohkTanimDeger?.message
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item sm={6} mt={2}>
                    <RadioGroup
                      onChange={handleInputTypeChange}
                      value={watch('inputChoice')}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="userFill"
                        label="Hesap bilgileri kendin doldur"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Bağlı olan hesaplardan seç"
                        value="autoFill"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    {watch('inputChoice') === 'autoFill' && (
                      <>
                        <InputLabel>Bağlı olan hesaplar</InputLabel>
                        <AutoComplete
                          onInput={(value) => {
                            setHesap(value)
                          }}
                          options={hesaplar}
                          value={hesap}
                        />
                      </>
                    )}
                    {watch('inputChoice') === 'userFill' && (
                      <>
                        <InputLabel>HHS Seç</InputLabel>
                        <AutoComplete
                          onInput={(value) => {
                            setHesap(value)
                          }}
                          options={hesaplar}
                          value={'FinCloudy'}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item sm={12}>
                    <RadioGroup
                      onChange={handleAccountTypeChange}
                      value={watch('odmBsltm.kmlk.ohkTur')}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="B"
                        label="Bireysel"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="K"
                        label="Kurumsal"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>TCKN Kimlik Verisi</InputLabel>
                    <AutoComplete
                      onInput={(value) => {
                        setValue('odmBsltm.kmlk.kmlkVrs', value)
                        clearErrors('odmBsltm.kmlk.kmlkVrs')
                      }}
                      options={['51637017718']}
                      value={watch('odmBsltm.kmlk.kmlkVrs')}
                      error={!!errors?.odmBsltm?.kmlk?.kmlkVrs}
                      helperText={errors?.odmBsltm?.kmlk?.kmlkVrs?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {watch('odmBsltm.kmlk.ohkTur') === 'K' && (
                      <>
                        <InputLabel>VKN Kimlik Verisi</InputLabel>
                        <AutoComplete
                          onInput={(value) => {
                            setValue('odmBsltm.kmlk.krmKmlkVrs', value)
                            clearErrors('odmBsltm.kmlk.krmKmlkVrs')
                          }}
                          options={['2153996351']}
                          value={watch('odmBsltm.kmlk.krmKmlkVrs')}
                          error={!!errors?.odmBsltm?.kmlk?.krmKmlkVrs}
                          helperText={
                            errors?.odmBsltm?.kmlk?.krmKmlkVrs?.message
                          }
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Gönderen Unvan</InputLabel>
                    <AutoComplete
                      onInput={(value) => {
                        setValue('odmBsltm.gon.unv', value)
                        clearErrors('odmBsltm.gon.unv')
                      }}
                      options={[]}
                      value={watch('odmBsltm.gon.unv')}
                      error={!!errors?.odmBsltm?.gon?.unv}
                      helperText={errors?.odmBsltm?.gon?.unv?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>Gönderen Hesap Numarasi</InputLabel>
                    <AutoComplete
                      onInput={(value) => {
                        clearErrors('odmBsltm.gon.hspNo')
                        setValue('odmBsltm.gon.hspNo', value)
                      }}
                      options={[]}
                      value={watch('odmBsltm.gon.hspNo')}
                      error={!!errors?.odmBsltm?.gon?.hspNo}
                      helperText={errors?.odmBsltm?.gon?.hspNo?.message}
                    />
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Box height={500} width="100%">
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} mb={5}>
                      <InputLabel id="odemeAmaci">Ödeme Amacı*</InputLabel>
                      <Select
                        id="odemeAmaci"
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        value={watch('odmBsltm.odmAyr.odmAmc')}
                        onChange={(e) => {
                          setValue('odmBsltm.odmAyr.odmAmc', e.target.value)
                          clearErrors('odmBsltm.odmAyr.odmAmc')
                        }}
                        error={!!errors?.odmBsltm?.odmAyr?.odmAmc}
                      >
                        {amaciItems.length === 0 ? (
                          <MenuItem key="">No items available</MenuItem>
                        ) : (
                          [
                            <MenuItem key="select" value="">
                              Select an item
                            </MenuItem>,
                            ...amaciItems.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            )),
                          ]
                        )}
                      </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <InputLabel id="odemeAmaci">Ödeme Acıklaması*</InputLabel>

                      <TextField
                        margin="normal"
                        {...register('odmBsltm.odmAyr.odmAcklm')}
                        onChange={(e) => {
                          setValue('odmBsltm.odmAyr.odmAcklm', e.target.value)
                          clearErrors('odmBsltm.odmAyr.odmAcklm')
                        }}
                        fullWidth
                        error={!!errors?.odmBsltm?.odmAyr?.odmAcklm}
                        helperText={errors?.odmBsltm?.odmAyr?.odmAcklm?.message}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <InputLabel id="odemeAmaci">İşlem Tutarı *</InputLabel>

                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography fontSize="25px">₺</Typography>
                            </InputAdornment>
                          ),
                        }}
                        {...register('odmBsltm.islTtr.ttr', {
                          valueAsNumber: true,
                        })}
                        onChange={() => clearErrors('odmBsltm.islTtr.ttr')}
                        error={!!errors?.odmBsltm?.islTtr?.ttr}
                        helperText={errors?.odmBsltm?.islTtr?.ttr?.message}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <InputLabel id="referans">Referans Bilgisi *</InputLabel>
                      <TextField
                        sx={{ marginTop: 2 }}
                        fullWidth
                        value={watch('odmBsltm.odmAyr.refBlg')}
                        onChange={(e) =>
                          setValue('odmBsltm.odmAyr.refBlg', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeStep === 2 && (
                <Grid container spacing={2} height={500}>
                  <Grid item sm={12} mb={1}>
                    <RadioGroup
                      onChange={handleTypeChange}
                      value={watch('paymentMethod')}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="hesapNumarasi"
                        label="Hesap Numarasi"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="karekod"
                        label="Karekod"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="kolas"
                        label="Kolas"
                      />
                    </RadioGroup>
                  </Grid>
                  {paymentMethod === 'hesapNumarasi' && (
                    <Grid container spacing={2} height={500}>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Alıcı Hesap Numarasi</InputLabel>
                        <AutoComplete
                          onInput={(value) => {
                            setValue('odmBsltm.alc.hspNo', value)
                            clearErrors('odmBsltm.alc.hspNo')
                          }}
                          {...register('odmBsltm.alc.hspNo')}
                          options={[]}
                          value={watch('odmBsltm.alc.hspNo')}
                          error={!!errors?.odmBsltm?.alc?.hspNo}
                          helperText={errors?.odmBsltm?.alc?.hspNo?.message}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Alıcı Ünvani</InputLabel>
                        <AutoComplete
                          onInput={(value) => {
                            clearErrors('odmBsltm.alc.unv')
                            setValue('odmBsltm.alc.unv', value)
                          }}
                          {...register('odmBsltm.alc.unv')}
                          options={[]}
                          value={watch('odmBsltm.alc.unv')}
                          error={!!errors?.odmBsltm?.alc?.unv}
                          helperText={errors?.odmBsltm?.alc?.unv?.message}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {paymentMethod === 'karekod' && (
                    <Box height={500} width="100%">
                      <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                          <InputLabel>Alıcı Hesap Numarasi</InputLabel>
                          <AutoComplete
                            onInput={(value) => {
                              setValue('odmBsltm.alc.hspNo', value)
                              clearErrors('odmBsltm.alc.hspNo')
                            }}
                            {...register('odmBsltm.alc.hspNo')}
                            options={[]}
                            value={watch('odmBsltm.alc.hspNo')}
                            error={!!errors?.odmBsltm?.alc?.hspNo}
                            helperText={errors?.odmBsltm?.alc?.hspNo?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel>Alıcı Ünvani</InputLabel>
                          <AutoComplete
                            onInput={(value) => {
                              setValue('odmBsltm.alc.unv', value)
                              clearErrors('odmBsltm.alc.unv')
                            }}
                            {...register('odmBsltm.alc.unv')}
                            options={[]}
                            value={watch('odmBsltm.alc.unv')}
                            error={!!errors?.odmBsltm?.alc?.unv}
                            helperText={errors?.odmBsltm?.alc?.unv?.message}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <InputLabel>Karekod Akiş Türü</InputLabel>
                          <Select
                            value={watch('odmBsltm.kkod.aksTur')}
                            fullWidth
                            onChange={(e) => {
                              setValue('odmBsltm.kkod.aksTur', e.target.value)
                              clearErrors('odmBsltm.alc.unv')
                            }}
                            label="Karekod Akiş Türü"
                            sx={{ mt: 2 }}
                          >
                            {karekodAkisTuru.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <InputLabel>Karekod Referansi</InputLabel>
                          <TextField
                            margin="normal"
                            fullWidth
                            value={watch('odmBsltm.kkod.kkodRef')}
                            {...register('odmBsltm.kkod.kkodRef')}
                            onChange={(e) => {
                              setValue('odmBsltm.kkod.kkodRef', e.target.value)
                              clearErrors('odmBsltm.kkod.kkodRef')
                            }}
                            error={!!errors?.odmBsltm?.kkod?.kkodRef}
                            helperText={
                              errors?.odmBsltm?.kkod?.kkodRef?.message
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <InputLabel>Karekod Üretici Kodu</InputLabel>
                          <TextField
                            value={watch('odmBsltm.kkod.kkodUrtcKod')}
                            margin="normal"
                            fullWidth
                            {...register('odmBsltm.kkod.kkodUrtcKod')}
                            onChange={(e) => {
                              setValue(
                                'odmBsltm.kkod.kkodUrtcKod',
                                e.target.value,
                              )
                              clearErrors('odmBsltm.kkod.kkodUrtcKod')
                            }}
                            error={!!errors?.odmBsltm?.kkod?.kkodUrtcKod}
                            helperText={
                              errors?.odmBsltm?.kkod?.kkodUrtcKod?.message
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {paymentMethod === 'kolas' && (
                    <Grid container spacing={2} height={500}>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Kolas Türü</InputLabel>
                        <Select
                          value={watch('odmBsltm.alc.kolas.kolasTur') || ''}
                          {...register('odmBsltm.alc.kolas.kolasTur')}
                          onChange={(e) => {
                            setValue(
                              'odmBsltm.alc.kolas.kolasTur',
                              e.target.value,
                            )
                            clearErrors('odmBsltm.alc.kolas.kolasTur')
                          }}
                          fullWidth
                          sx={{ mt: 2 }}
                        >
                          {kolasTuru.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Kolas Değeri</InputLabel>
                        <AutoComplete
                          value={watch('odmBsltm.alc.kolas.kolasDgr')}
                          onInput={(value) => {
                            setValue('odmBsltm.alc.kolas.kolasDgr', value)
                            clearErrors('odmBsltm.alc.kolas.kolasDgr')
                          }}
                          {...register('odmBsltm.alc.kolas.kolasDgr')}
                          options={[]}
                          error={!!errors?.odmBsltm?.alc?.kolas?.kolasDgr}
                          helperText={
                            errors?.odmBsltm?.alc?.kolas?.kolasDgr?.message
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                color="secondary"
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                sx={{ width: 200 }}
                style={{ textTransform: 'none' }}
              >
                Geri
              </Button>
              <Button
                color="secondary"
                onClick={handleNext}
                variant="contained"
                sx={{ width: 200 }}
                style={{ textTransform: 'none' }}
              >
                {activeStep === steps.length - 1
                  ? 'Ödeme Özetini Gör'
                  : 'İleri'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <PaymentPreviewDialog
        open={isPreviewDialogOpen}
        data={paymentSubmitResult}
        handleClose={closePreviewDialog}
        onConfirm={handleSubmit}
      />
      <RedirectPrompt
        openDialog={showRedirectConfirmationDialog}
        redirectUrl={`${paymentSubmitResult?.gkd?.hhsYonAdr} `}
        onClose={() => setshowRedirectConfirmationDialog(false)}
        onConfirm={handleRedirectConfirmClick}
      />
      <ErrorSnackbar
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
        errorMessage={errorMessage}
      />
      <PaymentResultDialog
        openDialog={showPaymentResultDialog}
        onClose={() => setShowPaymentResultDialog(false)}
        onConfirm={() => setShowPaymentResultDialog(false)}
        isPaymentDone={isPaymentDone}
        text={
          'Lütfen ödeme sonucunun gelmesini bekleyiniz, ekranı kapatmayınız.'
        }
        textLong={
          'Mobil cihazınıza bir bildirim geldiğinde, ödemenizi onaylayabilirsiniz. Ödemeyi onayladıktan sonra, bu sayfaya geri dönerek ödemenin durumunu görüp, ekranı kapatabilirsiniz.'
        }
        textSuccess={
          'Ödeme yapma işleminiz başarılı bir şekilde gerçekleştirildi.'
        }
        title="Ödeme Sonucu"
      />
    </>
  )
}

export interface IPayment {
  paymentMethod: 'hesapNumarasi' | 'karekod' | 'kolas'
  inputChoice: 'autoFill' | 'userFill'
  isTransferMoney: boolean
  katilimciBlg: {
    hhsKod: string
    yosKod: string
  }
  gkd: {
    yetYntm: string
    yonAdr: string
    ayrikGkd?: {
      ohkTanimTip: string
      ohkTanimDeger: string
    }
  }
  odmBsltm: {
    kmlk?: {
      kmlkVrs?: string
      krmKmlkVrs?: string
      krmKmlkTur?: string
      kmlkTur?: string
      ohkTur: string
    }
    islTtr: {
      prBrm: string
      ttr: number
    }
    alc: {
      unv?: string
      hspNo?: string
      kolas?: {
        kolasTur?: string
        kolasDgr?: string
        kolasRefNo?: number
      }
    }
    kkod?: {
      aksTur: string
      kkodRef: string
      kkodUrtcKod: string
    }
    gon?: {
      hspNo: string
      unv: string
    }
    odmAyr: {
      odmAcklm: string
      odmAmc: string
      odmKynk: string
      refBlg: string
    }
  }
  isyOdmBlg: {
    isyKtgKod: string
    altIsyKtgKod: string
    genelUyeIsyeriNo: string
  }
}

const karekodAkisTuru = [
  {
    label: 'FAST katılmcısından statik doğrulamalı işyeri ödemesi',
    value: '02',
  },
  {
    label: 'Kişiden kişiye ödemeler',
    value: '03',
  },
]

const defaultValues: IPayment = {
  paymentMethod: 'hesapNumarasi',
  inputChoice: 'userFill',
  isTransferMoney: true,
  katilimciBlg: {
    hhsKod: '4128',
    yosKod: '7031',
  },
  gkd: {
    yetYntm: 'A',
    yonAdr: 'https://devtest.fincloudy.com/redirect?drmKod=test123',
    ayrikGkd: {
      ohkTanimTip: 'TCKN',
      ohkTanimDeger: '95628648154',
    },
  },

  odmBsltm: {
    kmlk: {
      kmlkVrs: '',
      kmlkTur: '',
      ohkTur: 'B',
    },
    islTtr: {
      prBrm: 'TRY',
      ttr: 20,
    },
    alc: {
      hspNo: 'TR080412804135213006041661',
      unv: 'h4201u38756502196',
    },
    gon: {
      hspNo: '',
      unv: '',
    },
    odmAyr: {
      odmAcklm: 'İıçöğüş ÇÖĞÜŞ',
      odmAmc: '04',
      odmKynk: 'O',
      refBlg: 'Y-2701852-202302',
    },
  },
  isyOdmBlg: {
    isyKtgKod: '5732',
    altIsyKtgKod: '5732',
    genelUyeIsyeriNo: '01234567',
  },
}

const formFieldToStepMapping: { [key: string]: number } = {
  // Step 1: Gönderen Bilgileri
  'odmBsltm.kmlk.kmlkVrs': 0,
  'odmBsltm.kmlk.krmKmlkVrs': 0,
  'odmBsltm.gon.unv': 0,
  'odmBsltm.gon.hspNo': 0,

  // Step 2: Ödeme Bilgileri
  'odmBsltm.odmAyr.odmAmc': 1,
  'odmBsltm.odmAyr.odmAcklm': 1,
  'odmBsltm.islTtr.ttr': 1,

  // Step 3: Onay
  paymentMethod: 2,
  'odmBsltm.alc.hspNo': 2,
  'odmBsltm.alc.unv': 2,
  'odmBsltm.kkod.aksTur': 2,
  'odmBsltm.kkod.kkodRef': 2,
  'odmBsltm.kkod.kkodUrtcKod': 2,
  'odmBsltm.alc.kolas.kolasTur': 2,
  'odmBsltm.alc.kolas.kolasDgr': 2,
}

interface IAccount {
  rizaNo: string
  hspTml: {
    hspRef: string
    hspNo: string
    kisaAd: string
    prBrm: string
    hspTur: string
    hspTip: string
    hspDrm: string
    hspShb: string
    rizaDetails: {
      kmlkTur: string
      kmlkVrs: string
      ohkTur: string
    }
  }
  hspDty: {
    hspAclsTrh: string
  }
}
