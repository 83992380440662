import { IzinTuru } from "../enum/hesapEnums";

export const izinTuruEnumMap = [
    { name: 'Temel Hesap Bilgisi', value: IzinTuru.TemelHesapBilgisi },
    { name: 'Ayrıntılı Hesap Bilgisi', value: IzinTuru.AyrintiliHesapBilgisi },
    { name: 'Bakiye Bilgisi', value: IzinTuru.BakiyeBilgisi },
    { name: 'Temel İşlem', value: IzinTuru.TemelIslem },
    { name: 'Ayrıntılı İşlem Bİlgisi', value: IzinTuru.AyritiliIslemBilgisi },
    { name: 'Anlık Bakiye Bildirimi', value: IzinTuru.AnlikBakiyeBildirimi },
  ]
  