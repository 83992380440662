export function formatDate(dateTimeString: string): string {
  const date = new Date(dateTimeString)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months start from 0
  const year = date.getFullYear()

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const formattedDate = `${day}.${month}.${year}` // Use backticks for template literals
  const formattedTime = `${hours}:${minutes}` // Use backticks for template literals

  return `${formattedDate} ${formattedTime}` // Use backticks for template literals
}

export const formatAmount = (amount: number) => {
  var currency_symbol = '₺'

  var formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  })

  return formattedOutput.format(amount).replace(currency_symbol, '')
}
