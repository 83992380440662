export function getEnumKeyByValue<T extends object>(
  enumObj: T,
  value: string,
): string | undefined {
  for (const [key, enumValue] of Object.entries(enumObj)) {
    if (enumValue === value) {
      return key
    }
  }
  return undefined // Return undefined if the value does not match any enum key
}
