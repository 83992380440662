import { useForm } from 'react-hook-form'
import { IChildren } from '../../../types/customTypes'
import StepContextProvider from '../../../components/StepContextProvider'
import { Ihesapler } from '../../../types/customTypes'
import { hesapFormDefaultValues } from '../../../types/formDefaultValues'
import { useState } from 'react'
import dayjs from 'dayjs'
import { zodResolver } from '@hookform/resolvers/zod'
import hesaplerSchema from '../../../validation/hesaplar.validation'
import useFormPersist from 'react-hook-form-persist'

const kurumsalvalue = {
  ...hesapFormDefaultValues,
  kmlk: {
    ...hesapFormDefaultValues.kmlk,
    ohkTur: 'K',
    krmKmlkVrs: '7475042976',
  },
}

export const kurumsalFormValues = 'kurumsalFormValues'

function Hesap1StepperProvider({ children }: IChildren) {
  const [defaultValues] = useState<Ihesapler>(() => {
    const storedValues = window.sessionStorage.getItem(kurumsalFormValues)
    if (storedValues) {
      const parsedValues: Ihesapler = JSON.parse(storedValues)

      parsedValues.hspBlg.iznBlg.erisimIzniSonTrh = dayjs(
        parsedValues.hspBlg.iznBlg.erisimIzniSonTrh,
      )
      parsedValues.hspBlg.iznBlg.hesapIslemBslZmn = dayjs(
        parsedValues.hspBlg.iznBlg.hesapIslemBslZmn,
      )
      parsedValues.hspBlg.iznBlg.hesapIslemBtsZmn = dayjs(
        parsedValues.hspBlg.iznBlg.hesapIslemBtsZmn,
      )

      return parsedValues
    } else {
      return kurumsalvalue
    }
  })
  const formMethods = useForm<Ihesapler>({
    defaultValues: defaultValues,
    resolver: zodResolver(hesaplerSchema),
  })

  const { watch, setValue } = formMethods

  useFormPersist(kurumsalFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })
  return (
    <StepContextProvider formMethods={formMethods}>
      {children}
    </StepContextProvider>
  )
}

export default Hesap1StepperProvider
