import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import OdemeStep_1 from '../../../components/odeme/KurmusalStep'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import Hesab from '../../../components/odeme/Hesab'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import StepContentRenderer from '../../../components/StepContentRenderer'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import { useEffect, useState } from 'react'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { FormProvider, useForm } from 'react-hook-form'
import { IOdemeHesap } from '../../../types/odemeTypes'
import useFormPersist from 'react-hook-form-persist'
import { clearStorage } from '../../../utils/formUtils'
import { kurumsalFormValues } from '../../hesaplar/kurumsal/KurumsalStepperProvider'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { odemeDefaultValues } from '../../../types/formDefaultValues'

const odemeKurumsalDefaultValues: IOdemeHesap = {
  ...odemeDefaultValues,
  rzBlg: {
    ...odemeDefaultValues.rzBlg,
    rizaNo: '7ef9734b68a44fe882a742be5729c04c',
    olusZmn: '2024-05-02T11:26:42+03:00',
    gnclZmn: '2024-05-02T11:26:42+03:00',
    rizaDrm: 'B',
  },
  gkd: {
    ...odemeDefaultValues.gkd,
    yetTmmZmn: '2024-05-02T11:31:42+03:00',
    hhsYonAdr:
      'https://tripsit.bkm.com.tr/boss/ab/hhs-simulator-ui-v11/4128/odeme-emri-rizasi/7ef9734b68a44fe882a742be5729c04c/goruntule',
  },
  odmBsltm: {
    ...odemeDefaultValues.odmBsltm,
    islTtr: {
      prBrm: 'TRY',
      ttr: '1',
    },
    kmlk: {
      kmlkVrs: '53049965090',
      krmKmlkTur: 'V',
      krmKmlkVrs: '7475042976',
      ohkTur: 'K',
    },
    gon: {
      unv: 'h4128 u53049965090',
      hspNo: 'TR470412802824940497254341',
    },
    odmAyr: {
      ...odemeDefaultValues.odmBsltm.odmAyr,
      refBlg: 'Y-2701852-202011',
      ohkMsj: 'ÖHK Mesajı',
      odmStm: 'F',
      bekOdmZmn: '2024-05-02T11:36:42+03:00',
    },
  },
}

export const odemeHesapKurumsalFormValue = 'odemeHesapKurumsal'

export const components = [
  {
    index: 0,
    label: 'Ödeme Hizmeti Kullanıcısı Türü Seçimi',
    component: <OdemeStep_1 />,
  },
  {
    index: 1,
    label: 'Alıcı Hesap Bilgileri',
    component: <Hesab />,
  },
  {
    index: 2,
    label: 'Ödeme Adım 3',
    component: <OdemeStep_2 />,
  },
  {
    index: 3,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 4,
    label: 'Senaryo Tipi Seç',
    component: <Scenarios />,
  },
]

function HesabKurumsal() {
  const [
    odemeBireysel,
    { isSuccess, isLoading, isError, error, data: odemeKurumsal },
  ] = useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)

  const [defaultValues] = useState<IOdemeHesap>(() => {
    const storedValues = window.sessionStorage.getItem(
      odemeHesapKurumsalFormValue,
    )
    if (storedValues) {
      // Parse the stored values
      const parsedValues: IOdemeHesap = JSON.parse(storedValues)
      return parsedValues
    } else {
      return odemeKurumsalDefaultValues
    }
  })

  const formMethods = useForm<IOdemeHesap>({
    defaultValues: defaultValues,
    // resolver: zodResolver(hesaplerSchema),
  })

  const { watch, setValue, unregister, getValues } = formMethods
  const handleSubmitButtonClick = (data: IOdemeHesap) => {
    const scenarioType = getValues('scenarioType')
    odemeBireysel({ formData: data, scenarioType })
  }

  useFormPersist(odemeHesapKurumsalFormValue, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  useEffect(() => {
    if (isSuccess && odemeKurumsal) {
      const { hhsYonAdr } = odemeKurumsal.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
      clearStorage(kurumsalFormValues)
    }

    if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
           errorCode: ${errorCode}
           http code: ${httpCode}
           httpMessage: ${httpMessage} 
          errorMessage: ${moreInformationTr} 
          timeStamp: ${timestamp} 
  
           `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, odemeKurumsal, isError, error])

  const handleRedirectConfirmClick = () => {
    window.open(odemeKurumsal?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  useEffect(() => {
    setshowErrorAlert(true)
  }, [errorMessage])

  useEffect(() => {
    unregister('odmBsltm.alc.kolas')
  }, [])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepContentRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${odemeKurumsal?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default HesabKurumsal
