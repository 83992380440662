export enum IzinTuru {
  TemelHesapBilgisi = '01',
  AyrintiliHesapBilgisi = '02',
  BakiyeBilgisi = '03',
  TemelIslem = '04',
  AyritiliIslemBilgisi = '05',
  AnlikBakiyeBildirimi = '06',
}

export enum RizaDurumu {
  'Yetki Bekleniyor' = 'B',
  'Yetkilendirildi' = 'Y',
  'Yetki Kullanildi' = 'K',
  'Yetki OdemeEmrineDonustu' = 'E',
  'Yetki Sonlandirildi' = 'S',
}

export enum OhkTur {
  Bireysel = 'B',
  Kurumsal = 'K',
}

export enum KimlikTuru {
  TCKN = 'K',
  MNO = 'M',
  YKN = 'Y',
  PNO = 'P',
}
