import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'

export default function FullWidthSkeleton() {
  return (
    <Box sx={{ width: '100%', mt: 2, height: '280px' }} alignContent="center">
      <Skeleton height={50} />  
      <Skeleton animation="wave" height={50} />
      <Skeleton animation={false} height={50} />
    </Box>
  )
}
