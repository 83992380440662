import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#233142',
    },
    secondary: {
      main: '#019656',
      dark: '#016c4e',
    },
    common: {
      white: '#f5ecec',
    },
    error: {
      main: '#ff1744',
    },
    mode: 'light',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        ...{
          root: {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: '#1976d2',
          },
          '&.Mui-completed': {
            color: '#0d47a1',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
    fontSize: 12,
    // h1: {
    //   '@media (max-width:600px)': {
    //     fontSize: '3rem', 
    //   },
    // },
    // h2: {
    //   '@media (max-width:600px)': {
    //     fontSize: '2.5rem',
    //   },
    // },
    // h3: {
    //   '@media (max-width:600px)': {
    //     fontSize: '2rem',
    //   },
    // },
    // h4: {
    //   '@media (max-width:600px)': {
    //     fontSize: '1.75rem',
    //   },
    // },
    // h5: {
    //   '@media (max-width:600px)': {
    //     fontSize: '1.5rem', 
    //   },
    // },
    //   h6: {
    //     '@media (max-width:600px)': {
    //       fontSize: '1.25rem', 
    //     },
    //   },
    // body1: {
    //   '@media (max-width:600px)': {
    //     fontSize: '0.875rem', // Slightly increased for better readability on small screens
    //   },
    // },
  },
  
})

export default theme
