import React, { useState, ChangeEvent } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material'
import {
  useLazyGetMusteriTedarikciQuery,
  useUpdateMusteriTedarikciMutation,
} from '../../../../api/invoiceApi'
import { GridRowId } from '@mui/x-data-grid'

interface PaymentAccountDialogProps {
  open: boolean
  onClose: () => void
  selectedRowIds: GridRowId[]
}

const PaymentAccountDialog: React.FC<PaymentAccountDialogProps> = ({
  open,
  onClose,
  selectedRowIds,
}) => {
  const [paymentType, setPaymentType] = useState<string>('')
  const [kolasOption, setKolasOption] = useState<string>('')
  const [paymentFieldValue, setpaymentFieldValue] = useState<string>('')
  const [updateMusteriTedarikci] = useUpdateMusteriTedarikciMutation()
  const [getMusteriTedarikci] = useLazyGetMusteriTedarikciQuery()

  const handlePaymentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value)
    setpaymentFieldValue('')
    setKolasOption('')
  }

  const handleKolasOptionChange = (event: SelectChangeEvent<string>) => {
    setKolasOption(event.target.value)
  }

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setpaymentFieldValue(event.target.value)
  }

  const handleSubmit = async () => {
    // Form submit işlemleri burada yapılabilir
    console.log({ paymentType, kolasOption, paymentFieldValue, selectedRowIds })
    let dataToSubmit = {
      id: selectedRowIds[0],
      paymentType,
      kolasOption,
      paymentFieldValue,
    }
    await updateMusteriTedarikci(dataToSubmit)
    await getMusteriTedarikci('IN')
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tedarikçiniz için ödeme hesabı ekleyin</DialogTitle>
      <DialogContent>
        <RadioGroup value={paymentType} onChange={handlePaymentTypeChange}>
          <FormControlLabel value="IBAN" control={<Radio />} label="IBAN" />
          <FormControlLabel value="KOLAS" control={<Radio />} label="KOLAS" />
        </RadioGroup>

        {paymentType === 'KOLAS' && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="kolas-select-label">Seçenek</InputLabel>
            <Select
              labelId="kolas-select-label"
              value={kolasOption}
              onChange={handleKolasOptionChange}
            >
              <MenuItem value="telefon">Telefon</MenuItem>
              <MenuItem value="gsm">GSM</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
        )}

        {(paymentType === 'IBAN' || kolasOption) && (
          <TextField
            fullWidth
            margin="normal"
            label={paymentType === 'IBAN' ? 'IBAN' : kolasOption}
            value={paymentFieldValue}
            onChange={handleFieldChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          İptal
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentAccountDialog
