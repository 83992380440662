export const scenarios = [
    { value: 'normalAkis', label: 'Normal akış oluştur.' },
    { value: 'bosJWSHeader', label: 'Boş JWS Header senaryosu oluştur.' },
    {
      value: 'hataliPrivateKey',
      label: 'Hatalı private key ile JWS Header senaryosu oluştur.',
    },
    {
      value: 'hataliPayload',
      label: 'Hatalı payload ile JWS Header senaryosu oluştur.',
    },
    {
      value: 'hataliExpDate',
      label: 'Hatalı exp date ile JWS Header senaryosu oluştur.',
    },
    {
      value: 'yanlisFormat',
      label: 'Yanlış format ile JWS Header senaryosu oluştur.',
    },
  ]