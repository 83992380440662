import { Divider, TextField } from '@mui/material'
import { Box } from '@mui/system'
import KurumsalStepper from './KurumsalStepper'
import KurumsalProvider from './KurumsalStepperProvider'
import { NextButton } from '../../../components/StepButtons'
import StepperCard from '../../../components/Card'

function AyrikKurumsal() {
  const onSorgulaClick = () => {
    //API Call here
  }

  return (
    <Box>
      <KurumsalProvider>
        <KurumsalStepper />
      </KurumsalProvider>
      <Divider sx={{ marginTop: 10 }} />
      <Box width={500} margin="auto" mt={10}>
        <StepperCard>
          <TextField label="Rıza numarası" />
          <NextButton
            handleNextClick={onSorgulaClick}
            isDisabled={false}
            buttonLabel="Sorgula"
          />
        </StepperCard>
      </Box>
    </Box>
  )
}

export default AyrikKurumsal
