import * as React from 'react'
import TextField from '@mui/material/TextField'
import { Grid, InputAdornment, MenuItem, Typography } from '@mui/material'
import StepperCard from '../StepperCard'
import { useStepperControlContext } from '../StepContextProvider'
import { StepperContainer } from '../StepperContainer'
import { useFormContext } from 'react-hook-form'
import { IOdemeHesap } from '../../types/odemeTypes'
import Yetkilendirme from '../Yetkilendirme'

interface Option {
  label: string
  value: string
}

const items: Option[] = [
  {
    label: '4128',
    value: '4128',
  },
]

export const OdemeStep_2 = () => {
  const { handleNextClick } = useStepperControlContext()

  const { register, setValue, watch } = useFormContext<IOdemeHesap>()

  const handleHHSChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string
    setValue('katilimciBlg.hhsKod', selectedValue)
  }

  const handleYetkilendirmeValChange = (value: string) => {
    setValue('gkd.yetYntm', value)
  }

  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ marginTop: '0.5rem' }}>
            <Yetkilendirme
              onSelect={handleYetkilendirmeValChange}
              value={watch('gkd.yetYntm')}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: '0.5rem' }}>
            <TextField
              select
              label="HHS SEÇ *"
              variant="outlined"
              fullWidth
              margin="normal"
              value={watch('katilimciBlg.hhsKod')}
              onChange={handleHHSChange}
            >
              {items.length === 0 ? (
                <MenuItem key="">No items available</MenuItem>
              ) : (
                [
                  <MenuItem key="select" value="">
                    Select an item
                  </MenuItem>,
                  ...items.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                ]
              )}
            </TextField>
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="Yos kodu *"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register('katilimciBlg.yosKod')}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="İşlem Tutarı *"
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontSize="25px">₺</Typography>
                  </InputAdornment>
                ),
              }}
              {...register('odmBsltm.islTtr.ttr')}
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export default OdemeStep_2
