import React from 'react'
import { Snackbar, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'

interface SuccessSnackbarProps {
  open: boolean
  onClose: () => void
  successMessage: string
}

const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({
  open,
  onClose,
  successMessage,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <Alert onClose={onClose} severity="success" variant="filled">
        <Typography fontSize="20px">{successMessage}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default SuccessSnackbar
