import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorState {
  errorMessage: string;
  showErrorAlert: boolean;
}

const initialState: ErrorState = {
  errorMessage: '',
  showErrorAlert: false,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage;
      state.showErrorAlert = true;
    },
    clearError: (state) => {
      state.errorMessage = '';
      state.showErrorAlert = false;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;
export default errorSlice.reducer;
