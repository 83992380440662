import { useForm } from 'react-hook-form'
import { IChildren } from '../../../types/customTypes'
import StepContextProvider from '../../../components/StepContextProvider'
import { Ihesapler } from '../../../types/customTypes'
import { hesapFormDefaultValues } from '../../../types/formDefaultValues'
import hesaplerSchema from '../../../validation/hesaplar.validation'
import { zodResolver } from '@hookform/resolvers/zod'
import useFormPersist from 'react-hook-form-persist'
import { useState } from 'react'
import dayjs from 'dayjs'

export const bireyselFormValues = 'bireyselFormValues'

function HesapStepperProvider({ children }: IChildren) {
  const [defaultValues] = useState<Ihesapler>(() => {
    const storedValues = window.sessionStorage.getItem(bireyselFormValues)
    if (storedValues) {
      // Parse the stored values
      const parsedValues: Ihesapler = JSON.parse(storedValues)

      // Convert date strings to Dayjs objects
      parsedValues.hspBlg.iznBlg.erisimIzniSonTrh = dayjs(
        parsedValues.hspBlg.iznBlg.erisimIzniSonTrh,
      )
      parsedValues.hspBlg.iznBlg.hesapIslemBslZmn = dayjs(
        parsedValues.hspBlg.iznBlg.hesapIslemBslZmn,
      )
      parsedValues.hspBlg.iznBlg.hesapIslemBtsZmn = dayjs(
        parsedValues.hspBlg.iznBlg.hesapIslemBtsZmn,
      )

      return parsedValues
    } else {
      return hesapFormDefaultValues
    }
  })
  const formMethods = useForm<Ihesapler>({
    defaultValues: defaultValues,
    resolver: zodResolver(hesaplerSchema),
  })

  const { watch, setValue } = formMethods

  useFormPersist(bireyselFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  return (
    <StepContextProvider formMethods={formMethods}>
      {children}
    </StepContextProvider>
  )
}

export default HesapStepperProvider
