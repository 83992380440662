import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import styled from 'styled-components'
import { Box, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ActionColWithViewButton from '../../../../components/ActionColWithViewButton'
import { GridCellParams, GridRowId } from '@mui/x-data-grid'
import SimpleHeader from '../../../../components/SimpleHeader'
import InvoiceTable from '../../../../components/InvoiceTable'
import { IInvoiceTable } from '../../../../types/customTypes'
import PaymentIcon from '@mui/icons-material/Payment'
import {
  AccountBalance,
  Delete,
  Download,
  Visibility,
} from '@mui/icons-material'
import { useSendEmailMutation } from '../../../../api/sendEmailApi'
import { invoices } from '../../../../mocks/invoices'
import MailIcon from '@mui/icons-material/Mail'

import {
  useLazyGetInvoicesQuery,
  useSendPaymentLinkMutation,
} from '../../../../api/invoiceApi'
import { useOdemeHesapMutation } from '../../../../api/odemeApi'
import FeedbackAlert from '../../../../components/FeedbackAlert'
import { getErrorMessageInfo } from '../../../../utils/error'
import RedirectPrompt from '../../../../components/RedirectPrompt'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import ErrorSnackbar from '../../../../components/ErrorSnackbar'
import ViewInvoiceDetails from '../ViewInvoiceDetails'
import ViewInvoiceDetailsDialog from '../ViewInvoiceDetailsDialog'
import CommunicationDialog from './CommunicationDialog'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PageHeader from '../../../../components/PageHeader'

export const IconButtonStyled = styled(IconButton)`
  &&& {
    color: #333;
  }
`

const InvoiceManagement: React.FC = () => {
  const navigate = useNavigate()
  const [
    sendPaymentLink,
    {
      isSuccess: isEmailSubmitSuccess,
      isError: isEmailSubmitError,
      error: isEmailError,
      isLoading: isEmailSubmitLoading,
      data: emailData,
    },
  ] = useSendPaymentLinkMutation()
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false)
  const [isPreviewDialogOpen, setisPreviewDialogOpen] = useState(false)
  const [isFeedbackDialogOpen, setisFeedbackDialogOpen] = useState(false)
  const [feedbackVariant, setFeedbackVariant] = useState<'success' | 'error'>(
    'success',
  )
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  const [odemeActiveRow, setOdemeActiveRow] = useState('')

  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [feedbackMessage, setfeedbackMessage] = useState('')
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([])
  const [selectedRowId, setSelectedRowId] = useState<string>('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<any | null>(null)
  const [message, setMessage] = useState<string>('')

  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false)
  const handleDownloadButtonClick = () => {
    setIsDownloadDialogOpen(true)
  }

  const closeDownloadDialog = () => {
    setIsDownloadDialogOpen(false)
  }

  const handleDownloadConfirmation = (startDate: Date, endDate: Date) => {
    // Implement  download logic here using startDate and endDate
    console.log('Downloading invoices from', startDate, 'to', endDate)
    setIsDownloadDialogOpen(false)
  }
  const handleSendEmailOrWhatsApp = (row: any) => {
    setSelectedInvoice(row)
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleSend = async (method: string, contact: string) => {
    if (method === 'email') {
      try {
        let messageBody = {
          text: message,
          link: `${window.location.origin}/view-invoice?id=${selectedInvoice.id}`,
        }
        const body = {
          data: messageBody,
          receiver: contact,
        }
        await sendPaymentLink({ formData: body })
        console.log('Email sent successfully')
      } catch (error) {
        console.error('Error sending email:', error)
      }
    } else {
      const whatsappUrl = `https://wa.me/${contact}?text=${encodeURIComponent(message)}`
      window.open(whatsappUrl, '_blank')
    }
  }

  const [getInvoices, { isSuccess, data, isError }] = useLazyGetInvoicesQuery()
  const [openAddPaymentInfoDialog, setOpenPaymentInfoDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [paymentDataToAutoFill, setPaymentDataToAutoFill] = useState({
    supplier: '',
    payable_amount: 0,
  })
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false)
  const [
    submitPayment,
    {
      isSuccess: isPaymentSubmitSuccess,
      isError: isPaymentSubmitError,
      error: paymentError,
      isLoading: isPaymentSubmitLoading,
      data: paymentData,
    },
  ] = useOdemeHesapMutation()

  const handlePaymentInfoOpen = () => {
    setOpenPaymentInfoDialog(true)
  }
  console.log('selectedworid', selectedRowIds)
  const handlePaymentInfoClose = () => {
    setOpenPaymentInfoDialog(false)
  }

  useEffect(() => {
    getInvoices('IN')
  }, [])

  const handleDownload = async (invoiceDocId: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to download the file?',
    )
    if (confirmed) {
      const url = `invoiceURLcomeshere/${invoiceDocId}`
      window.open(url, '_blank')
      const response = await fetch(url)
      const blob = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = 'xxx.pdf'
      downloadLink.click()
    }
  }

  // Function to handle row selection change
  const handleSelectionModelChange = (newSelectionModel: GridRowId[]) => {
    setSelectedRowIds(newSelectionModel)
  }

  const handleDeleteClick = (id: string) => {
    //API call to delete
  }
  const handleRequestFactoring = () => {
    //API call to request factoring for selectedRowIds
  }

  const handleViewDetailsClick = (rowId: string) => {
    setShowInvoiceDetails(true)

    setSelectedRowId(rowId)
  }
  useEffect(() => {
    if (
      paymentDataToAutoFill.supplier ||
      paymentDataToAutoFill.payable_amount
    ) {
      setIsPaymentDialogOpen(true)
    }
  }, [paymentDataToAutoFill])

  const handlePaymentButtonClick = (row: any) => {
    if (row !== odemeActiveRow) {
      setOdemeActiveRow(row)
      sessionStorage.removeItem('paymentForm')
    }
    const { supplier, payable_amount } = row
    // Set the payment data from the selected row
    setPaymentDataToAutoFill({
      supplier,
      payable_amount,
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'supplier',
      type: 'string',
      headerName: 'Müşteri',
      width: 600,
    },

    {
      field: 'sender',
      type: 'string',
      headerName: 'Tedarikçi VKN',
      width: 250,
    },

    {
      field: 'payable_amount',
      headerName: 'Tutar',
      type: 'number',
      width: 100,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'issue_date',
      headerName: 'Fatura Tarihi',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) =>
        new Date(params.value as string).toLocaleDateString('tr-TR'),
    },
    // {
    //   field: 'erp_status',
    //   type: 'string',
    //   headerName: 'ERP Statü',
    //   width: 300,
    // },
    {
      headerAlign: 'left',
      align: 'left',
      field: 'actions',
      headerName: 'Aksiyon',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {/* <Icon
              to="/viewInvoiceDetails"
              state={{
                salesInvoiceDetails: data,
                selectedInvoiceId: params.row.id,
              }}
              color="primary"
              title="Fatura Detaylarını Gör"
            > */}{' '}
            <IconButton onClick={() => handleViewDetailsClick(params.row.id)}>
              <Visibility />
            </IconButton>
            {/* Render delete button */}
            <IconButton
              color="error"
              title="Delete Invoice"
              onClick={() => handleDeleteClick(params.row._id)}
            >
              <Delete />
            </IconButton>
            {/* <IconButton
              color="info"
              title="Odeme"
              onClick={() => handlePaymentButtonClick(params.row)}
            >
              <PaymentIcon />
            </IconButton> */}
            <IconButton
              color="warning"
              title="Send Email or WhatsApp"
              onClick={() => handleSendEmailOrWhatsApp(params.row)}
            >
              <MailIcon />
            </IconButton>
            <IconButton title="İndir">
              <Download />
            </IconButton>
            {/* 
              <IconButton
                color="warning"
                title="Odeme"
                // onClick={() => handlePaymentButtonClick(params.row)}
              >
                <AccountBalance />
              </IconButton> */}
          </>
        )
      },
    },
  ]

  const [Invoices, setInvoices] = useState<IInvoiceTable[]>([])

  useEffect(() => {
    if (invoices) {
      const tableStructure: IInvoiceTable[] = invoices.map((invoice) => ({
        id: invoice.invoice_number,
        status: invoice.status,
        due_date: invoice.due_date.toString(),
        issued_date: invoice.issued_date.toString(),
        invoice_number: invoice.invoice_number,
        customerName: invoice.customer_info.fullName,
        customerEmail: invoice.customer_info.email,
        supplierName: invoice.supplier_info.fullName,
        supplierEmail: invoice.supplier_info.email,
        amount_to_be_paid: invoice.amount_to_be_paid,
      }))

      setInvoices(tableStructure)
    }
  }, [invoices])

  useEffect(() => {
    if (isPaymentSubmitSuccess && paymentData) {
      setErrorMessage('')
      const { hhsYonAdr } = paymentData.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    }

    if (isPaymentSubmitError && paymentError) {
      setisPreviewDialogOpen(false)
      const errMessage = getErrorMessageInfo(paymentError)
      setErrorMessage(errMessage)
      setshowErrorAlert(true)
    }
  }, [
    isPaymentSubmitSuccess,
    isPaymentSubmitError,
    isPaymentSubmitLoading,
    paymentData,
    paymentError,
  ])

  const handleRedirectConfirmClick = () => {
    window.open(paymentData?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  return (
    <Box>
      {/* <SimpleHeader title="Satış Faturaları" /> */}
      <PageHeader
        title="Satış Faturaları"
        firstTitle="Faturaları İndir"
        onFirstButtonClick={handleDownloadButtonClick}
        firstButton={
          <IconButton sx={{ color: '#fff' }} title="Faturaları İndir">
            <Download />
          </IconButton>
        }
      />

      <InvoiceTable
        rows={data}
        columns={columns}
        onSelectionModelChange={handleSelectionModelChange}
      />

      {/* {isPaymentSubmitLoading && <LoadingSpinner/>} */}

      <RedirectPrompt
        openDialog={showRedirectConfirmationDialog}
        redirectUrl={`${paymentData?.gkd?.hhsYonAdr} `}
        onClose={() => setshowRedirectConfirmationDialog(false)}
        onConfirm={handleRedirectConfirmClick}
      />
      {showInvoiceDetails && (
        <ViewInvoiceDetailsDialog
          openDialog={showInvoiceDetails}
          onClose={() => setShowInvoiceDetails(false)}
          data={{ selectedInvoiceId: selectedRowId, salesInvoiceDetails: data }}
        />
      )}
      <ViewInvoiceDetailsDialog
        openDialog={showInvoiceDetails}
        onClose={() => setShowInvoiceDetails(false)}
        data={{ selectedInvoiceId: selectedRowId, salesInvoiceDetails: data }}
      />
      <ErrorSnackbar
        open={showErrorAlert}
        onClose={() => setshowErrorAlert(false)}
        errorMessage={errorMessage}
      />
      {selectedInvoice && (
        <CommunicationDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onSend={handleSend}
          invoiceLink={`${window.location.origin}/view-invoice?id=${selectedInvoice.id}`}
          invoiceNumber={selectedInvoice.invoice_number}
          amount={selectedInvoice.payable_amount}
          dueDate={new Date(selectedInvoice.due_date).toLocaleDateString(
            'tr-TR',
          )}
          setMessage={setMessage}
          message={message}
        />
      )}
    </Box>
  )
}

export default InvoiceManagement
