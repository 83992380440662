import { Box } from '@mui/system'
import Card from '../../components/Card'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  useCreateOlayAbonelikMutation,
  useDeleteOlayAbonelikMutation,
  useGetOlayAbonelikQuery,
  useUpdateOlayAbonelikMutation,
} from '../../api/olaylarApi'
import ErrorSnackbar from '../../components/ErrorSnackbar'
import SuccessSnackbar from '../../components/SuccessSnackbar'

const dropDownOptions = [
  {
    label: 'ODEME_EMRI_RIZASI',
    value: 'ODEME_EMRI_RIZASI',
  },
  {
    label: 'HESAP_BILGISI_RIZASI',
    value: 'HESAP_BILGISI_RIZASI',
  },
  {
    label: 'ODEME_EMRI',
    value: 'ODEME_EMRI',
  },
  {
    label: 'COKLU_ISLEM_TALEBI',
    value: 'COKLU_ISLEM_TALEBI',
  },
  {
    label: 'BAKIYE',
    value: 'BAKIYE',
  },
]

export default function OlayOlustur() {
  const [kaynakGuncelCheckbox, setkaynakGuncelCheckbox] =
    useState<boolean>(false)
  const [abonelikBilgisi, setAbonelikBilgisi] = useState(null)

  const [kaynakGuncel, setkaynakGuncel] = useState<string[]>([])
  const [ayrikGkdCheckbox, setayrikGkdCheckbox] = useState<boolean>(false)
  const [ayrikGkd, setayrikGkd] = useState<string[]>([])
  const [ayrikGkdSizCheckbox, setayrikGkdSizCheckbox] = useState<boolean>(false)
  const [ayrikGkdSiz, setayrikGkdSiz] = useState<string[]>([])
  const [hhsKod, sethhsKod] = useState<string>('4128')
  const [olayAbonelikId, setOlayAbonelikId] = useState<string>('')

  const { data: abonelikData, refetch } = useGetOlayAbonelikQuery()

  const [updateOlayAbonelik] = useUpdateOlayAbonelikMutation()
  const [createOlayAbonelik] = useCreateOlayAbonelikMutation()
  const [deleteOlayAbonelik] = useDeleteOlayAbonelikMutation()
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const handleDeleteOlayAbonelik = () => {
    deleteOlayAbonelik({ olayAbonelikId: olayAbonelikId })
      .then(() => {
        setSuccessMessage('Olay Aboneliği Başarıyla Silindi')
        setShowSuccessAlert(true)
        refetch()
      })
      .catch((error) => {
        setErrorMessage(
          'Olay Aboneliği Silinirken Bir Hata Oluştu: ' + error.message,
        )
        setshowErrorAlert(true)
      })
  }

  const handleSubmit = () => {
    let result: { olayTipi: string; kaynakTipi: string }[] = []
    if (kaynakGuncelCheckbox && kaynakGuncel.length > 0) {
      kaynakGuncel.forEach((kaynakTipi) => {
        result.push({
          olayTipi: 'KAYNAK_GUNCELLENDI',
          kaynakTipi: kaynakTipi,
        })
      })
    }
    if (ayrikGkdCheckbox && ayrikGkd.length > 0) {
      ayrikGkd.forEach((kaynakTipi) => {
        result.push({
          olayTipi: 'AYRIK_GKD_BASARILI',
          kaynakTipi: kaynakTipi,
        })
      })
    }
    if (ayrikGkdSizCheckbox && ayrikGkdSiz.length > 0) {
      ayrikGkdSiz.forEach((kaynakTipi) => {
        result.push({
          olayTipi: 'AYRIK_GKD_BASARISIZ',
          kaynakTipi: kaynakTipi,
        })
      })
    }
    let data = {
      katilimciBlg: {
        hhsKod: hhsKod,
        yosKod: '7031',
      },
      abonelikTipleri: result,
      olayAbonelikNo: olayAbonelikId,
    }

    if (olayAbonelikId) {
      updateOlayAbonelik({ formData: data, olayAbonelikId })
        .then(() => {
          setSuccessMessage('Olay Aboneliği Başarıyla Güncellendi')
          setShowSuccessAlert(true)
          refetch()
        })
        .catch((error) => {
          setErrorMessage(
            'Olay Aboneliği Güncellenirken Bir Hata Oluştu: ' + error.message,
          )
          setshowErrorAlert(true)
        })
    } else {
      createOlayAbonelik({ formData: data })
        .then(() => {
          setSuccessMessage('Olay Aboneliği Başarıyla Oluşturuldu')
          setShowSuccessAlert(true)
          refetch()
        })
        .catch((error) => {
          setErrorMessage(
            'Olay Aboneliği Oluşturulurken Bir Hata Oluştu: ' + error.message,
          )
          setshowErrorAlert(true)
        })
    }
  }
  useEffect(() => {
    if (abonelikData) {
      setAbonelikBilgisi(abonelikData)
      const { abonelikTipleri } = abonelikData
      if (abonelikTipleri) {
        sethhsKod(abonelikData.katilimciBlg.hhsKod)
        setOlayAbonelikId(abonelikData.olayAbonelikNo)
        abonelikTipleri.forEach((abonelik: any) => {
          if (abonelik.olayTipi === 'KAYNAK_GUNCELLENDI') {
            setkaynakGuncelCheckbox(true)
            console.log('kaynakGuncelCheckbox', kaynakGuncelCheckbox)
            setkaynakGuncel((prevState) => [...prevState, abonelik.kaynakTipi])
          } else if (abonelik.olayTipi === 'AYRIK_GKD_BASARILI') {
            setayrikGkdCheckbox(true)
            setayrikGkd((prevState) => [...prevState, abonelik.kaynakTipi])
          } else if (abonelik.olayTipi === 'AYRIK_GKD_BASARISIZ') {
            setayrikGkdSizCheckbox(true)
            setayrikGkdSiz((prevState) => [...prevState, abonelik.kaynakTipi])
          }
        })
      }
    }
  }, [abonelikData])

  const handlekaynakGuncelChange = (
    event: SelectChangeEvent<typeof kaynakGuncel>,
  ) => {
    const {
      target: { value },
    } = event
    setkaynakGuncel(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const handleAyrikGkdChange = (
    event: SelectChangeEvent<typeof kaynakGuncel>,
  ) => {
    const {
      target: { value },
    } = event
    setayrikGkd(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const handleAyrikGkdSizChange = (
    event: SelectChangeEvent<typeof kaynakGuncel>,
  ) => {
    const {
      target: { value },
    } = event
    setayrikGkdSiz(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <Box>
      {showErrorAlert && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      {showSuccessAlert && (
        <SuccessSnackbar
          open={showSuccessAlert}
          onClose={() => setShowSuccessAlert(false)}
          successMessage={successMessage}
        />
      )}
      <Button
        onClick={handleDeleteOlayAbonelik}
        variant="contained"
        style={{
          backgroundColor: 'red',
          marginBottom: '10px',
          textTransform: 'none',
        }}
        size="large"
      >
        Olay Aboneliğini Sil
      </Button>
      <Typography>Olay Oluştur</Typography>
      <Card>
        <Grid container>
          <Grid item sm={12} md={6}>
            <InputLabel>
              <Typography variant="h5" fontWeight="bold" gutterBottom mb={4}>
                Olay Tipi Seçimi
              </Typography>
            </InputLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={kaynakGuncelCheckbox}
                  onChange={(event) =>
                    setkaynakGuncelCheckbox(event.target.checked)
                  }
                />
              }
              label="Kaynak Güncellendi"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <InputLabel>
              <Typography variant="h5" fontWeight="bold" gutterBottom mb={4}>
                Kaynak Tipi Seçimi
              </Typography>
            </InputLabel>
            <Select
              value={kaynakGuncel}
              onChange={handlekaynakGuncelChange}
              fullWidth
              multiple
              renderValue={(selected) => selected.join(', ')}
            >
              {dropDownOptions.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  <Checkbox checked={kaynakGuncel.indexOf(option.label) > -1} />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid container>
          <Grid item sm={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ayrikGkdCheckbox}
                  onChange={(event) =>
                    setayrikGkdCheckbox(event?.target.checked)
                  }
                />
              }
              label="Ayrık Gkd Başarılı"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Select
              value={ayrikGkd}
              onChange={handleAyrikGkdChange}
              fullWidth
              multiple
              renderValue={(selected) => selected.join(', ')}
            >
              {dropDownOptions.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  <Checkbox checked={ayrikGkd.indexOf(option.label) > -1} />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid container>
          <Grid item sm={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ayrikGkdSizCheckbox}
                  onChange={(event) =>
                    setayrikGkdSizCheckbox(event?.target.checked)
                  }
                />
              }
              label="Ayrık Gkd Başarısız"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Select
              value={ayrikGkdSiz}
              onChange={handleAyrikGkdSizChange}
              fullWidth
              multiple
              renderValue={(selected) => selected.join(', ')}
            >
              {dropDownOptions.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  <Checkbox checked={ayrikGkdSiz.indexOf(option.label) > -1} />
                  {/* <ListItemText primary={option.label} /> */}
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Card>
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="secondary"
        size="large"
        sx={{ mt: 3, width: '150px' }}
      >
        Güncelle
      </Button>
    </Box>
  )
}
