import { Button, Typography } from '@mui/material'
import DialogInput from './Dialog'
import { Box } from '@mui/system'

interface RedirectPromptProps {
  openDialog: boolean
  onClose: () => void
  onConfirm: () => void
  redirectUrl: string
  text?: string
}

export default function RedirectPrompt({
  openDialog,
  onClose,
  onConfirm,
  redirectUrl,
  text,
}: RedirectPromptProps) {
  return (
    <Box width="1000px">
      <DialogInput
        title="Yönlendirme Onayı"
        open={openDialog}
        content={
          <>
            {/* <Typography
              variant="h6"
              fontWeight="bold"
              gutterBottom
              marginBottom={5}
            >
              {redirectUrl}
            </Typography> */}
            <Typography variant="h6" marginBottom={3}>
              {text ??
                'Para Gönderim Onayı İçin Bankanıza Yönlendirileceksiniz'}
            </Typography>
            <Button
              variant="contained"
              onClick={onConfirm}
              style={{ marginRight: '10px', backgroundColor: '#284b75' }}
            >
              Vazgeç
            </Button>
            <Button variant="contained" onClick={onConfirm} color="secondary">
              Tamam
            </Button>
          </>
        }
        onConfirmText="Tamam"
        onCancelText="Yok"
        onClose={onClose}
        onConfirm={onConfirm}
        noDefaultButtons
      />
    </Box>
  )
}
