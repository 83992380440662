import { Navigate, Outlet } from 'react-router-dom'
import Layout from './Layout'

const AuthPage = () => {
  return (
    <>
      {sessionStorage.getItem('isLoggedIn') ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  )
}

export default AuthPage