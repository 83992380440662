import { Grid, InputLabel } from '@mui/material'
import HesabBireyselComponent from './BireyselStep'
import { useState } from 'react'
import AutoComplete from '../CustomAutoComplete'
import { useFormContext } from 'react-hook-form'
import { IOdemeHesap } from '../../types/odemeTypes'

interface Option {
  label: string
  value: string
}

const kurumsalTCKN = ['51637017718']
const kurumsalVK = ['2153996351']

const KurmusalComponent = () => {
  const { watch, setValue } = useFormContext<IOdemeHesap>()

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <InputLabel>TCKN Kimlik Verisi</InputLabel>
        <AutoComplete
          onInput={(value) => setValue('odmBsltm.kmlk.kmlkVrs', value)}
          options={kurumsalTCKN}
          value={watch('odmBsltm.kmlk.kmlkVrs')}
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel>VKN Kimlik Verisi</InputLabel>
        <AutoComplete
          onInput={(value) => setValue('odmBsltm.kmlk.krmKmlkVrs', value)}
          options={kurumsalVK}
          value={watch('odmBsltm.kmlk.krmKmlkVrs')}
        />
      </Grid>
    </Grid>
  )
}

export const KurmusalStep = () => {
  return <HesabBireyselComponent Kurmusal={<KurmusalComponent />} />
}

export default KurmusalStep
