import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Divider,
} from '@mui/material'
import { IChildren } from '../../../types/customTypes'

interface PaymentPreviewDialogProps {
  open: boolean
  data: any
  handleClose: () => void
  onConfirm: () => void
}

function Field({
  label,
  value,
}: {
  label: string
  value: string | undefined
  props?: any
}) {
  return (
    <Typography variant="body1" display="flex" gap={1} fontSize="18px">
      <strong>{label}:</strong> {value}
    </Typography>
  )
}

function Title({ children }: IChildren) {
  return (
    <Typography
      variant="h6"
      gutterBottom
      fontWeight="bold"
      mt={3}
      style={{ color: 'green' }}
    >
      {children}
    </Typography>
  )
}
function getOdemeAmcLabel(value: string): string {
  switch (value) {
    case '01':
      return 'Konut Kirası Ödemesi'
    case '02':
      return 'İş yeri Kirası Ödemesi'
    case '03':
      return 'Diğer Kira Ödemesi'
    case '04':
      return 'E-Ticaret Ödemesi: Elektronik ticaret işlem amaçlı aktarımlar'
    case '05':
      return 'Çalışan Ödemesi: Maaş, harcırah, prim gibi çalışan ödemeleri'
    case '06':
      return 'Ticari ödeme: Ticari işletmelerin birbirilerine kendi hesaplarına veya müşterilerine ödemeleri, borç, ithalat, ihracat, şirket satın alma, vb. kapsamında ödemeler'
    case '07':
      return 'Bireysel Ödeme: Özel amaçlı (aile bireylerine, hediye, bağış, borç, alışveriş vs.) ödemeler'
    case '08':
      return 'Yatırım: Mevduat, menkul kıymet, döviz, gayrı menkul, taşıt, varlık alımı, temettü ödeme, tahsilat vb. gibi yatırım amaçlı ödemeler'
    case '09':
      return 'Finansal: Kredi, depo, repo, türev, finansal varlık alım/satımı vb. ödemeler'
    case '10':
      return 'Eğitim ödemesi'
    case '11':
      return 'Aidat ödemesi'
    case '12':
      return 'Diğer'
    default:
      return 'Bilinmeyen'
  }
}
const getRizaDurumuText = (code: string | undefined) => {
  switch (code) {
    case 'B':
      return 'Yetki Bekleniyor – İlk rıza talebinde'
    case 'Y':
      return 'Yetkilendirildi – Başarılı GKD sonrası yetKod üretildiğinde'
    case 'K':
      return 'Yetki Kullanıldı – Erişim Belirteci alındığında'
    case 'E':
      return 'Yetki ödeme emrine dönüştü – ÖBHS için'
    case 'S':
      return 'Yetki Sonlandırıldı'
    case 'I':
      return 'Yetki İptal'
    default:
      return 'Bilinmeyen Rıza Durumu'
  }
}

const getKarekodAkisTuru = (code: string | undefined) => {
  switch (code) {
    case '01':
      return 'FAST katılımcısından dinamik doğrulamalı hizmet alınan işyeri ödemesi'
    case '02':
      return 'FAST katılmcısından statik doğrulamalı işyeri ödemesi'
    case '03':
      return 'Kişiden kişiye ödemeler'
    default:
      return 'Bilinmeyen Rıza Durumu'
  }
}

const getKolasLabel = (code: string | undefined) => {
  switch (code) {
    case 'T':
      return 'Telefon'
    case 'K':
      return 'TC Kimlik No'
    case 'V':
      return 'Veri Kimlik No'
    case 'E':
      return 'E-posta adresi'
    case 'P':
      return 'Pasaport No'
    case 'Y':
      return 'Yabancı Kimlik No'

    default:
      return 'Bilinmeyen Rıza Durumu'
  }
}
function formatDateTime(dateTimeString: string): string {
  const date = new Date(dateTimeString)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Aylar 0'dan başlar
  const year = date.getFullYear()

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const formattedDate = `${day}.${month}.${year}`
  const formattedTime = `${hours}:${minutes}`

  return `${formattedDate} ${formattedTime}`
}
const PaymentPreviewDialog = ({
  open,
  data,
  handleClose,
  onConfirm,
}: PaymentPreviewDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          {data?.odmBsltm?.alc?.hspNo || data?.odmBsltm?.alc?.unv ? (
            <>
              <Title>ALICI BİLGİLERİ</Title>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    label="Alıcı Ünvanı"
                    value={data?.odmBsltm?.alc?.unv}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Alıcı Hesap Numarası"
                    value={data?.odmBsltm?.alc?.hspNo}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Divider sx={{ borderWidth: 1 }} />
                </Grid>
              </Grid>
            </>
          ) : null}
          {data?.odmBsltm?.kkod && (
            <>
              <Title>KAREKOD BİLGİLERİ</Title>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    label="Karekod Akış Türü"
                    value={getKarekodAkisTuru(data?.odmBsltm?.kkod?.aksTur)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Karekod Referansı"
                    value={data?.odmBsltm?.kkod?.kkodRef}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Karekod Üretici Kodu"
                    value={data?.odmBsltm?.kkod?.kkodUrtcKod}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Divider sx={{ borderWidth: 1 }} />
                </Grid>
              </Grid>
            </>
          )}
          {data?.odmBsltm?.alc?.kolas && (
            <>
              <Title>KOLAS BİLGİLERİ</Title>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    label="Kolas Türü"
                    value={getKolasLabel(data?.odmBsltm?.alc?.kolas?.kolasTur)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Kolas Değeri"
                    value={data?.odmBsltm?.alc?.kolas?.kolasDgr}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Divider sx={{ borderWidth: 1 }} />
                </Grid>
              </Grid>
            </>
          )}{' '}
          <Title>GÖNDEREN BİLGİLERİ</Title>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field label="Gönderen Ünvanı" value={data?.odmBsltm?.gon?.unv} />
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Gönderen Hesap Numarasi"
                value={data?.odmBsltm?.gon?.hspNo}
              />
            </Grid>

            {data?.odmBsltm?.kmlk?.ohkTur === 'K' && (
              <Grid item xs={6}>
                <Field
                  label="VKN Kimlik Verisi"
                  value={data?.odmBsltm?.kmlk?.krmKmlkVrs}
                />
              </Grid>
            )}
            <Grid item sm={12}>
              <Divider sx={{ borderWidth: 1 }} />
            </Grid>
          </Grid>
          <Title>ÖDEME BİLGİLERİ</Title>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                label="İşlem Tutarı"
                value={String(data?.odmBsltm?.islTtr?.ttr)}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Ödeme Açıklaması"
                value={data?.odmBsltm?.odmAyr?.odmAcklm}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Ödeme Amacı"
                value={getOdemeAmcLabel(data?.odmBsltm?.odmAyr?.odmAmc)}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                label="İşlem Tarihi"
                value={formatDateTime(data?.rzBlg?.olusZmn)}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                label="Referans Numarası"
                value={data?.odmBsltm?.odmAyr?.refBlg}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <Field
                label="Durum"
                value={getRizaDurumuText(data?.rzBlg?.rizaDrm)}
              />
            </Grid> */}
            {/* <Grid item sm={12}>
              <Divider sx={{ borderWidth: 1 }} />
            </Grid> */}
          </Grid>
          <Grid container spacing={2}></Grid>
        </Box>
      </DialogContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          gap: 2,
          marginTop: '20px',
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={handleClose}
          sx={{ width: 250 }}
          style={{ backgroundColor: '#284b75' }}
        >
          Kapat
        </Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={onConfirm}
          sx={{ width: 250 }}
        >
          Onayla
        </Button>
      </Box>
    </Dialog>
  )
}
export default PaymentPreviewDialog
