import { z } from 'zod'

const required = 'Zorunlu'

function requiredFieldError(fieldName: string) {
  return {
    code: z.ZodIssueCode.custom,
    message: required,
    path: [fieldName],
  }
}

export const connectAccountSchema = z
  .object({
    katilimciBlg: z
      .object({
        hhsKod: z.string().min(1, required),
        yosKod: z.string().min(1, required),
      })
      .required(),
    gkd: z.object({
      yetYntm: z.string(),
      yonAdr: z.string(),
      ayrikGkd: z
        .object({
          ohkTanimTip: z.string().optional(),
          ohkTanimDeger: z.string().optional(),
        })
        .optional(),
    }),
    kmlk: z.object({
      kmlkTur: z.string().min(1, required),
      kmlkVrs: z.string().min(1, required),
      krmKmlkTur: z.string().optional(),
      krmKmlkVrs: z.string().optional(),
      ohkTur: z.string().min(1, required),
    }),
    hspBlg: z
      .object({
        iznBlg: z.object({
          iznTur: z.array(z.string()).min(1, required),
          erisimIzniSonTrh: z.coerce
            .date()
            .refine((value) => value !== undefined, {
              message: 'erisimIzniSonTrh is required',
            }),
          hesapIslemBslZmn: z.coerce
            .date()
            .refine((value) => value !== undefined, {
              message: 'hesapIslemBslZmn is required',
            }),
          hesapIslemBtsZmn: z.coerce
            .date()
            .refine((value) => value !== undefined, {
              message: 'hesapIslemBtsZmn is required',
            }),
        }),
      })
      .required(),
    isAyrikAvailable: z.boolean(),
  })
  .superRefine((data, refinementContext) => {
    const {
      kmlk,
      gkd: {
        ayrikGkd,
      },
    } = data
    const { ohkTur, krmKmlkVrs } = kmlk
    let errors = []

    if (data.isAyrikAvailable) {
      if (ayrikGkd?.ohkTanimTip === '' || ayrikGkd?.ohkTanimDeger === '') {
        errors.push(refinementContext.addIssue(requiredFieldError(required)))
      }
    }

    // Check if ohkTur is 'K' and krmKmlkVrs is null or empty
    if (ohkTur === 'K' && (krmKmlkVrs === null || krmKmlkVrs === '')) {
      errors.push(
        refinementContext.addIssue(
          requiredFieldError('kmlk.krmKmlkVrs'),
        ),
      )
    }
  })
