import { Button, Typography } from '@mui/material'
import DialogInput from './Dialog'
import { Box } from '@mui/system'
import { ErrorOutline } from '@mui/icons-material'
import { Link } from 'react-router-dom'

interface ErrorDialogProps {
  openDialog: boolean,
  errorMessage: string
}

function ErrorDialog({ openDialog, errorMessage }: ErrorDialogProps) {
  return (
    <DialogInput
      title=""
      open={openDialog}
      onClose={() => void 0}
      content={
        <Box textAlign="center">
          <ErrorOutline sx={{ fontSize: '100px', color: 'error.main' }} />
          <Typography variant="h5" gutterBottom mb={3}>
            Error: {errorMessage}
          </Typography>
          <Link to="/">
            <Button variant="contained">
            <Typography variant="body1">Go to homepage</Typography>
            </Button>
          </Link>
        </Box>
      }
      noDefaultButtons
    />
  )
}

export default ErrorDialog
