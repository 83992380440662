import { Box } from '@mui/system'
import DialogInput from './Dialog'
import { Done, HourglassBottom } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'

interface PaymentResultDialogProps {
  openDialog: boolean
  onClose: () => void
  onConfirm: () => void
  isPaymentDone: boolean
  text: string
  textLong: string
  textSuccess: string
  title?: string
}

export default function PaymentResultDialog({
  openDialog,
  onClose,
  onConfirm,
  isPaymentDone,
  text,
  textLong,
  textSuccess,
  title,
}: PaymentResultDialogProps) {
  return (
    <Box width="1000px">
      <DialogInput
        title={title ?? 'Hesap Erişim Sonucu'}
        open={openDialog}
        content={
          <>
            {!isPaymentDone && (
              <>
                <HourglassBottom
                  sx={{
                    color: '#FFD700',
                    fontSize: 60,
                    margin: 'auto',
                    display: 'block',
                  }}
                />

                <Typography
                  variant="body1"
                  paragraph
                  textAlign="center"
                  style={{ fontWeight: 'bold' }}
                >
                  {text}
                </Typography>
              </>
            )}

            {isPaymentDone && (
              <>
                <Done
                  sx={{
                    color: 'green',
                    fontSize: 100,
                    margin: 'auto',
                    display: 'block',
                  }}
                />

                <Typography
                  variant="body1"
                  paragraph
                  textAlign="center"
                  style={{ fontWeight: 'bold' }}
                >
                  {textSuccess}
                </Typography>
              </>
            )}
            <Typography variant="body1" paragraph textAlign="center">
              {textLong}
            </Typography>

            <Button variant="contained" onClick={onConfirm} color="secondary">
              Kapat
            </Button>
          </>
        }
        onConfirmText="Tamam"
        onCancelText="Yok"
        onClose={onClose}
        onConfirm={onConfirm}
        noDefaultButtons
      />
    </Box>
  )
}
