import { Box } from '@mui/system'
import HesapSec from '../../../components/BankSelection'
import HesapBilgisi from '../../../components/HesapBilgisi'
import Scenarios from '../../../components/Scanario'
import TCKNInput from './TCKNInput'

export const bireyselComponents = [
  {
    index: 0,
    label: 'TCKN',
    component: <TCKNInput />,
  },
  {
    index: 1,
    label: 'Hesap Bilgisi',
    component: <HesapBilgisi />,
  },
  {
    index: 2,
    label: 'Hesap şeç',
    component: <HesapSec />,
  },
  {
    index: 3,
    label: 'Senaryo Tipi Seç',
    component: (
      <Box height="420px">
        <Scenarios />,
      </Box>
    ),
  },
]
