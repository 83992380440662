import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import styled from 'styled-components'
import { Box, Button, IconButton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ActionColWithViewButton from '../../../../components/ActionColWithViewButton'
import { GridCellParams, GridRowId } from '@mui/x-data-grid'
import SimpleHeader from '../../../../components/SimpleHeader'
import InvoiceTable from '../../../../components/InvoiceTable'
import { IInvoiceTable } from '../../../../types/customTypes'
import PaymentIcon from '@mui/icons-material/Payment'
import { AccountBalance, Delete, Visibility } from '@mui/icons-material'
import { useSendEmailMutation } from '../../../../api/sendEmailApi'
import { invoices } from '../../../../mocks/invoices'
import PaymentForm, { IPayment } from './Payment'
// import StepperDialog from './StepperDialog'
import { useLazyGetInvoicesQuery } from '../../../../api/invoiceApi'
import PaymentAccountDialog from './PaymentAccount'
import {
  useLazyGetAllOdemeQuery,
  useOdemeHesapMutation,
} from '../../../../api/odemeApi'
import FeedbackAlert from '../../../../components/FeedbackAlert'
import { getErrorMessageInfo } from '../../../../utils/error'
import RedirectPrompt from '../../../../components/RedirectPrompt'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import ErrorSnackbar from '../../../../components/ErrorSnackbar'

export const IconButtonStyled = styled(IconButton)`
  &&& {
    color: #333;
  }
`

const ParaTransferi: React.FC = () => {
  const navigate = useNavigate()

  const [sendEmail] = useSendEmailMutation()
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false)
  const [isPreviewDialogOpen, setisPreviewDialogOpen] = useState(false)
  const [isFeedbackDialogOpen, setisFeedbackDialogOpen] = useState(false)
  const [feedbackVariant, setFeedbackVariant] = useState<'success' | 'error'>(
    'success',
  )
  const [showErrorAlert, setshowErrorAlert] = useState(false)
  const [odemeActiveRow, setOdemeActiveRow] = useState('')

  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const [feedbackMessage, setfeedbackMessage] = useState('')
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([])
  const [getInvoices, { isSuccess, data, isError }] = useLazyGetInvoicesQuery()
  const [openAddPaymentInfoDialog, setOpenPaymentInfoDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [paymentDataToAutoFill, setPaymentDataToAutoFill] = useState({
    supplier: '',
    payable_amount: 0,
  })
  const [
    submitPayment,
    {
      isSuccess: isPaymentSubmitSuccess,
      isError: isPaymentSubmitError,
      error: paymentError,
      isLoading: isPaymentSubmitLoading,
      data: paymentData,
    },
  ] = useOdemeHesapMutation()

  const [
    getAllOdemeEmri,
    {
      isSuccess: isGetAllOdemeEmriSuccess,
      isError: isGetAllOdemeEmriError,
      error: getAllOdemeError,
      isLoading: isetAllOdemeEmriLoading,
      data: allOdemeEmri,
    },
  ] = useLazyGetAllOdemeQuery()

  useEffect(() => {
    getAllOdemeEmri({})
  }, [])
  const handlePaymentInfoOpen = () => {
    setOpenPaymentInfoDialog(true)
  }

  const handlePaymentInfoClose = () => {
    setOpenPaymentInfoDialog(false)
  }

  const handleOpenPreviewDialog = () => {
    setisPreviewDialogOpen(true)
  }
  // useEffect(() => {
  //   getInvoices('IN')
  // }, [])

  // Function to handle row selection change
  const handleSelectionModelChange = (newSelectionModel: GridRowId[]) => {
    setSelectedRowIds(newSelectionModel)
  }

  const handleDeleteClick = (id: string) => {
    //API call to delete
  }
  const handleRequestFactoring = () => {
    //API call to request factoring for selectedRowIds
  }

  const handleSendEmails = async () => {
    try {
      const body = {
        userIds: selectedRowIds,
      }
      await sendEmail(body)
      console.log('Email sent successfully')
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  useEffect(() => {
    if (
      paymentDataToAutoFill.supplier ||
      paymentDataToAutoFill.payable_amount
    ) {
      setIsPaymentDialogOpen(true)
    }
  }, [paymentDataToAutoFill])

  const handlePaymentButtonClick = () => {
    setIsPaymentDialogOpen(true)
  }

  const columns: GridColDef[] = [
    {
      field: 'issue_date',
      headerName: 'İşlem Tarihi',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        new Date(params.row.rzBlg.gnclZmn as string).toLocaleDateString(
          'tr-TR',
        ),
    },
    {
      field: 'amount',
      headerName: 'Tutar',
      type: 'number',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => {
        return params.row.odmBsltm?.islTtr.ttr + ' TL' || ''
      },
    },

    {
      field: 'aciklama',
      headerName: 'Açıklama',
      width: 150,
      align: 'left',
      valueGetter: (params) => {
        return params.row.odmBsltm?.odmAyr.odmAcklm || ''
      },
    },

    {
      field: 'alici',
      headerName: 'Alıcı Ünvan',
      width: 250,
      align: 'left',
      valueGetter: (params) => {
        return params.row.odmBsltm?.alc?.unv || ''
      },
    },

    {
      field: 'aliciIban',
      headerName: 'Alıcı IBAN/HESAP No',
      width: 250,
      align: 'left',
      valueGetter: (params) => {
        return params.row.odmBsltm?.alc?.hspNo || ''
      },
    },

    {
      field: 'gonhspNo',
      headerName: 'Gönderen IBAN/HESAP No',
      width: 250,
      align: 'left',
      valueGetter: (params) => {
        return params.row.odmBsltm?.gon?.hspNo || ''
      },
    },

    {
      field: 'paraTransferiMi',
      headerName: 'Ödeme Tipi',
      width: 150,
      align: 'left',
      valueGetter: (params) => {
        return !params.row.isTransferMoney
          ? 'Fatura Ödemesi'
          : 'Para Transferi' || ''
      },
    },

    {
      field: 'isFinish',
      headerName: 'Ödeme Statü',
      width: 150,
      align: 'left',
      valueGetter: (params) => {
        return params.row.rzBlg?.rizaDrm === 'E'
          ? 'Tamamlandı'
          : params.row.rzBlg?.rizaDrm === 'B'
            ? 'Beklemede'
            : 'Başarısız' || ''
      },
    },
  ]

  const handlePaymentDataSubmission = (paymentData: IPayment) => {
    submitPayment({ formData: paymentData })
    sessionStorage.removeItem('transferMoney')
  }

  useEffect(() => {
    if (isPaymentSubmitSuccess && paymentData) {
      setErrorMessage('')
      const { hhsYonAdr } = paymentData.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    }

    if (isPaymentSubmitError && paymentError) {
      setisPreviewDialogOpen(false)
      const errMessage = getErrorMessageInfo(paymentError)
      setErrorMessage(errMessage)
      setshowErrorAlert(true)
    }
  }, [
    isPaymentSubmitSuccess,
    isPaymentSubmitError,
    isPaymentSubmitLoading,
    paymentData,
    paymentError,
  ])

  const handleRedirectConfirmClick = () => {
    window.open(paymentData?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  return (
    <Box>
      <SimpleHeader title="Para Transferleri" />
      <PaymentAccountDialog
        open={openAddPaymentInfoDialog}
        onClose={handlePaymentInfoClose}
      />
      <div style={{ textAlign: 'left', marginBottom: '25PX' }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: '10px' }}
          onClick={() => handlePaymentButtonClick()}
        >
          Para Gönder
        </Button>
      </div>
      <InvoiceTable
        rows={allOdemeEmri}
        columns={columns}
        onSelectionModelChange={handleSelectionModelChange}
      />

      {isPaymentDialogOpen && (
        <PaymentForm
          open={isPaymentDialogOpen}
          handleClose={() => setIsPaymentDialogOpen(false)}
          onSubmission={handlePaymentDataSubmission}
          paymentDataToAutofill={paymentDataToAutoFill}
          isPreviewDialogOpen={isPreviewDialogOpen}
          openPreviewDialog={() => setisPreviewDialogOpen(true)}
          closePreviewDialog={() => setisPreviewDialogOpen(false)}
        />
      )}

      {/* {isPaymentSubmitLoading && <LoadingSpinner/>} */}
      {/* 
      <RedirectPrompt
        openDialog={showRedirectConfirmationDialog}
        redirectUrl={`${paymentData?.gkd?.hhsYonAdr} `}
        onClose={() => setshowRedirectConfirmationDialog(false)}
        onConfirm={handleRedirectConfirmClick}
      /> */}
      <ErrorSnackbar
        open={showErrorAlert}
        onClose={() => setshowErrorAlert(false)}
        errorMessage={errorMessage}
      />
    </Box>
  )
}

export default ParaTransferi
