import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})
interface RemoveAccountConsentProps {
  open: boolean
  handleClose: () => void
  onConfirm: () => void
}
export default function RemoveAccountConsent({
  open,
  handleClose,
  onConfirm,
}: RemoveAccountConsentProps) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Hesap Erişimini Kaldır</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Seçilen hesap için erişimini kaldırmak istediğinize emin misiniz? Bu
            işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <Button onClick={onConfirm}>Onayla</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
