import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SimpleHeader from '../../../../components/SimpleHeader'
import InvoiceTable from '../../../../components/InvoiceTable'
import { IInvoiceTable } from '../../../../types/customTypes'
import { AccountBalance } from '@mui/icons-material'
import { useLazyGetMusteriTedarikciQuery } from '../../../../api/invoiceApi'
import PaymentAccountDialog from './PaymentAccount'
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
export const IconButtonStyled = styled(IconButton)`
  &&& {
    color: #333;
  }
`

const MusteriTedarikci: React.FC = () => {
  const navigate = useNavigate()
  const [getMusteriTedarikci, { isSuccess, data, isError }] =
    useLazyGetMusteriTedarikciQuery()
  const [openAddPaymentInfoDialog, setOpenPaymentInfoDialog] = useState(false)

  useEffect(() => {
    getMusteriTedarikci('IN')
  }, [])

  const handlePaymentInfoOpen = () => {
    setOpenPaymentInfoDialog(true)
  }

  const handlePaymentInfoClose = () => {
    setOpenPaymentInfoDialog(false)
  }

  const [rowData, setRowData] = useState<IInvoiceTable[]>([])
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([])

  useEffect(() => {
    if (data) {
      setRowData(data)
    }
  }, [data])
  const columns: GridColDef[] = [
    {
      field: 'companyPartyName',
      type: 'string',
      headerName: 'Şirket Adı',
      width: 500,
    },
    {
      field: 'companySchemeId',
      type: 'string',
      headerName: 'VKN/TCKN',
      width: 150,
    },
    {
      field: 'paymentType',
      type: 'string',
      headerName: 'Ödeme Yöntemi',
      width: 150,
    },
    {
      field: 'paymentFieldValue',
      type: 'string',
      headerName: 'IBAN/KOLAS',
      width: 400,
    },
    {
      field: 'companyPhone',
      type: 'string',
      headerName: 'Telefon',
      width: 150,
    },
    {
      field: 'companyEmail',
      type: 'string',
      headerName: 'E-posta',
      width: 250,
    },
    {
      headerAlign: 'left',
      align: 'left',
      field: 'actions',
      headerName: 'Aksiyon',
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="warning"
              title="Odeme"
              onClick={handlePaymentInfoOpen}
            >
              <AccountBalance />
            </IconButton>
          </>
        )
      },
    },
  ]

  return (
    <Box>
      <SimpleHeader title="Müşteri/Tedarikçi Bilgileri" />
      <PaymentAccountDialog
        open={openAddPaymentInfoDialog}
        onClose={handlePaymentInfoClose}
        selectedRowIds={rowSelectionModel}
      />
      {/**TODO: we should use our base table instead of this component,
       * but base table somehow deletes the selected rows. this temporary solution
       */}
      <DataGrid
        rows={rowData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default MusteriTedarikci
