import { Button } from '@mui/material'

interface NextButtonProps {
  buttonLabel?: string
  handleNextClick: () => void
  isDisabled?: boolean
}

interface BackButtonProps {
  handleBackClick: () => void
  isDisabled?: boolean
}

export const NextButton = ({
  handleNextClick,
  isDisabled = false,
  buttonLabel = 'Next',
}: NextButtonProps) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleNextClick}
      sx={{ marginTop: 5, width: '120px' }}
      disabled={isDisabled}
    >
      {buttonLabel}
    </Button>
  )
}

export const BackButton = ({
  handleBackClick,
  isDisabled = false,
}: BackButtonProps) => {
  return (
    <Button
      variant="outlined"
      onClick={handleBackClick}
      // fullWidth
      sx={{ marginTop: 5 }}
    >
      Back
    </Button>
  )
}
