import StepperCard from '../../../components/Card'
import { useStepperControlContext } from '../../../components/StepContextProvider'
import { StepperContainer } from '../../../components/StepperContainer'
import AutoComplete from '../../../components/CustomAutoComplete'
import { useFormContext } from 'react-hook-form'
import { Ihesapler } from '../../../types/customTypes'

const BireysalInputOption = ['91264223762']

function TCKNInput() {
  const { handleNextClick } = useStepperControlContext()
  const { setValue, watch } = useFormContext<Ihesapler>()

  const onNextButtonClick = () => {
    handleNextClick()
  }

  const handleInput = (value: string) => {
    setValue('kmlk.kmlkVrs', value)
  }

  return (
    <StepperContainer
      alignContent="center"
      justifyContent="center"
      display="flex"
      height="420px"
    >
      <StepperCard width="500px" height="100px" justifyContent="center">
        <AutoComplete
          onInput={handleInput}
          options={BireysalInputOption}
          value={watch('kmlk.kmlkVrs')}
          label="TCKN"
        />
      </StepperCard>
    </StepperContainer>
  )
}

export default TCKNInput
