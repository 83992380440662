import { IErisimBelirteci } from '../../types/customTypes'
import { api } from '../api'

export const erisimBelirteciSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    submitHesapErisimBelirteci: builder.mutation({
      query: (erisimBelirteciData: IErisimBelirteci) => ({
        url: '/erisim-belirteci',
        method: 'POST',
        body: erisimBelirteciData,
      }),
    }),

    submitOdemeErisimBelirteci: builder.mutation({
      query: (erisimBelirteciData: IErisimBelirteci) => ({
        url: '/odeme-erisim-belirteci',
        method: 'POST',
        body: erisimBelirteciData,
      }),
      extraOptions: { maxRetries: 0 },

    }),
  }),
})

export const {
  useSubmitHesapErisimBelirteciMutation,
  useSubmitOdemeErisimBelirteciMutation,
} = erisimBelirteciSlice
