import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import StepperRenderer from '../../../components/StepContentRenderer'

import Step1 from './Step_1'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import Iban from '../../../components/odeme/Iban'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IOdemeHesap } from '../../../types/odemeTypes'
import useFormPersist from 'react-hook-form-persist'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { odemeDefaultValues } from '../../../types/formDefaultValues'

const karekodKurumsalDefaultValues = {
  ...odemeDefaultValues,
  odmBsltm: {
    islTtr: {
      ...odemeDefaultValues.odmBsltm.islTtr,
      ttr: '1',
    },
    kmlk: {
      ...odemeDefaultValues.odmBsltm.kmlk,
      kmlkVrs: '44391484704',
    },
    gon: {
      hspNo: 'TR390412802878909614104471',
      unv: 'h4128 u44391484704',
    },
    kkod: {
      aksTur: '03',
      kkodRef: '12345678901',
      kkodUrtcKod: '0800',
    },
    odmAyr: {
      odmAcklm: 'Karekod-Kişiden kişiye ödemeler',
      odmAmc: '04',
      odmKynk: 'O',
      refBlg: '0111TLK01230405',
    },
  },
  isyOdmBlg: {
    isyKtgKod: '5661',
    altIsyKtgKod: '5661',
    genelUyeIsyeriNo: '01630618',
  },
}

export const components = [
  {
    index: 0,
    label: 'Ödeme Hizmeti Kullanıcısı Türü Seçimi',
    component: <Iban />,
  },
  {
    index: 1,
    label: 'Karekod-Kişiden kişiye ödemeler',
    component: <Step1 />,
  },
  {
    index: 2,
    label: 'Odeme Step 2',
    component: <OdemeStep_2 />,
  },
  {
    index: 3,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 4,
    label: 'Senaryo Tipi Seç',
    component: <Scenarios />,
  },
]
function KarekodOdeme() {
  const [odemeHesap, { isSuccess, isLoading, isError, data, error }] =
    useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    }
  }, [isSuccess, data])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)

  const karekodKurumsalFormValues = 'karekodKurumsal'

  const [defaultValues] = useState<any>(() => {
    const storedValues = window.sessionStorage.getItem(
      karekodKurumsalFormValues,
    )
    if (storedValues) {
      // Parse the stored values
      const parsedValues: any = JSON.parse(storedValues)
      return parsedValues
    } else {
      return karekodKurumsalDefaultValues
    }
  })

  const formMethods = useForm<IOdemeHesap>({
    defaultValues: defaultValues,
  })

  const { watch, setValue, getValues, unregister } = formMethods

  useFormPersist(karekodKurumsalFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const handleSubmitButtonClick = (data: IOdemeHesap) => {
    console.log(data)

    const scenarioType = getValues('scenarioType')
    odemeHesap({ formData: data, scenarioType })
  }

  useEffect(() => {
    unregister('odmBsltm.odmAyr.refBlg')
  }, [])

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    } else if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  useEffect(() => {
    if (errorMessage) {
      setshowErrorAlert(true)
    }
  }, [errorMessage])

  useEffect(() => {
    unregister('odmBsltm.odmAyr.refBlg')
  }, [])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepperRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default KarekodOdeme
