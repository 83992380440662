import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { useStepperControlContext } from './StepContextProvider'
import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'

interface StepContent {
  index: number
  label: string
  component: ReactNode
}

interface StepContentRendererProps {
  components: StepContent[]
  onSubmit: (data: any) => void
  isSubmitting?: boolean
}

function StepContentRenderer({
  components,
  onSubmit,
  isSubmitting,
}: StepContentRendererProps) {
  const { activeStep, handleNextClick, handleBackClick } =
    useStepperControlContext()
  const { handleSubmit } = useFormContext()

  const isLastStep = activeStep === components.length - 1
  const isFirstStep = activeStep === 0

  return (
    <Box>
      {isSubmitting ? (
        <Box
          width="70vw"
          margin="auto"
          marginTop="200px"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stepper activeStep={activeStep}>
            {components.map(({ label }, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box width="100%">
            {components.map(({ index, component }) => {
              if (index === activeStep) {
                return <div key={index}>{component}</div>
              }
              return null
            })}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              onClick={handleBackClick}
              disabled={isFirstStep}
              sx={{ marginTop: 5 }}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={isLastStep ? handleSubmit(onSubmit) : handleNextClick}
              sx={{ marginTop: 5, width: '120px' }}
              disabled={isSubmitting}
            >
              {isLastStep ? 'Finish' : 'Next'}
            </Button>{' '}
          </Box>
        </>
      )}
    </Box>
  )
}

export default StepContentRenderer
