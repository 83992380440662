import { Box } from '@mui/material'
import { ReactNode } from 'react'

function StepperCard({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        boxShadow: 3,
        borderRadius: 2,
        px: 4,
        py: 6,
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  )
}

export default StepperCard
