import React from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Box,
  Typography,
  ThemeProvider,
} from '@mui/material'
import { createTheme, styled, Theme } from '@mui/material/styles'
import { CheckCircleOutline, Warning } from '@mui/icons-material'
import theme from '../../../../theme/theme'
import { grey } from '@mui/material/colors'

const stepperPrimaryColor = '#284b75'

const QontoConnector = styled(StepConnector)(({ theme }: { theme: Theme }) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& .MuiStepConnector-line': {
      borderColor: stepperPrimaryColor,
    },
  },
  completed: {
    '& .MuiStepConnector-line': {
      borderColor: stepperPrimaryColor,
    },
  },
  line: {
    borderColor: theme.palette.secondary.main,
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))

const stepperTheme = createTheme({
  palette: {
    primary: {
      main: '#01295b',
    },
    secondary: theme.palette.secondary,
    info: {
      main: grey[400],
    },
  },
})

const StepIconRoot = styled('div')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme
    ownerState: { active?: boolean; completed?: boolean; error?: boolean }
  }) => ({
    backgroundColor: ownerState.error
      ? theme.palette.error.main
      : ownerState.completed
        ? stepperTheme.palette.secondary.main
        : ownerState.active
          ? stepperPrimaryColor
          : stepperTheme.palette.info.main,
    zIndex: 1,
    color: '#fff',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
)

const StepIcon = (props: any) => {
  const { active, completed, error, className } = props

  return (
    <StepIconRoot
      ownerState={{ completed, active, error }}
      className={className}
      theme={stepperTheme}
    >
      {error ? (
        <Warning />
      ) : completed ? (
        <CheckCircleOutline />
      ) : (
        <Typography>{props.icon}</Typography>
      )}
    </StepIconRoot>
  )
}

const steps = [
  'Alıcı Hesap',
  'Gönderen Hesap',
  'Tutar Belirleme',
  'Detay Belirleme',
]

interface CustomStepperProps {
  activeStep: number
  steps: string[]
  errorStep?: number
}

export default function CustomStepper({
  activeStep,
  steps,
  errorStep = -1,
}: CustomStepperProps) {
  return (
    <ThemeProvider theme={stepperTheme}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => {
          const labelProps: {
            optional?: React.ReactNode
            error?: boolean
          } = {}

          return (
            <Step key={`${label}-${index}`}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIcon {...props} error={index === errorStep} />
                )}
                {...labelProps}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    borderRadius: 1,
                    mb: 5,
                    bgcolor:
                      index === errorStep
                        ? theme.palette.error.main
                        : activeStep === index
                          ? stepperPrimaryColor
                          : activeStep > index
                            ? stepperTheme.palette.secondary.main
                            : stepperTheme.palette.info.main,
                    color: 'white',
                  }}
                >
                  {label}
                </Box>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </ThemeProvider>
  )
}
