import { api } from './api'

const hesaplerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    hesapRizaSorgula: builder.query<any, string>({
      query: (rizaNo) => ({
        url: `https://devtest.fincloudy.com/api/v1/hesap-riza-sorgula/${rizaNo}`,
      }),
    }),
    connectHesap: builder.mutation({
      query: ({ formData, scenarioType }) => ({
        url: `https://devtest.fincloudy.com/api/v1/hesap-bilgi-rizasi`,
        method: 'POST',
        body: formData,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    getHesapler: builder.query<any, { rizaNo: string }>({
      query: ({ rizaNo }) => ({
        url: `/hesaplar?syfKytSayi=10&syfNo=1`,
        headers: {
          rizaNo,
        },
      }),
    }),
    getHesaplarBakiye: builder.query<
      any,
      { rizaNo: string; isErisimBelirteciYenileme: boolean }
    >({
      query: ({ rizaNo, isErisimBelirteciYenileme }) => ({
        url:
          `/bakiye?syfKytSayi=10&syfNo=1&isErisimBelirteciYenileme=` +
          isErisimBelirteciYenileme,
        headers: {
          rizaNo,
        },
      }),
    }),
    getHesapByRefId: builder.query<any, { refId: string; rizaNo: string }>({
      query: ({ refId, rizaNo }) => ({
        url: `/hesaplar/${refId}`,
        headers: {
          rizaNo,
        },
      }),
    }),
    getBakiyeByRefId: builder.query<any, { refId: string; rizaNo: string }>({
      query: ({ refId, rizaNo }) => ({
        // url: '/hesap/bakiye/4b5abc0e-65c9-45e6-9b3c-d47e10cba4af?syfKytSayi=10&syfNo=1',

        url: `/hesap/bakiye/${refId}?syfKytSayi=10&syfNo=1`,
        headers: {
          rizaNo: rizaNo,
          // rizaNo: '011c7bed302443e8b05ddb122b0e6b20'
        },
      }),
    }),

    getRizaNo: builder.query<any, void>({
      query: () => ({
        url: `/riza-no`,
      }),
    }),
    getTransaction: builder.query<any, any>({
      query: ({
        rizaNo,
        hesapRef,
        hesapIslemBslTrh,
        hesapIslemBtsTrh,
        srlmYon = 'A',
      }) => {
        const queryParams = {
          hesapIslemBslTrh,
          hesapIslemBtsTrh,
          syfKytSayi: '5',
          syfNo: '1',
          srlmKrtr: 'islGrckZaman',
          srlmYon,
        }
        console.log(hesapIslemBslTrh)

        return {
          // url: `/hesap/transactions/${hesapRef}?${new URLSearchParams(queryParams)}`,
          url: `/hesap/transactions/${hesapRef}?hesapIslemBslTrh=${hesapIslemBslTrh}&hesapIslemBtsTrh=${hesapIslemBtsTrh}&syfKytSayi=5&syfNo=1&srlmKrtr=islGrckZaman&srlmYon=A&brcAlc=${srlmYon}`,
          // url: '/hesap/transactions',
          // url: `https://devtest.fincloudy.com/api/v1/hesap/transactions/4b5abc0e-65c9-45e6-9b3c-d47e10cba4af?hesapIslemBslTrh=2024-06-06&hesapIslemBtsTrh=2024-06-08&syfKytSayi=5&syfNo=1&srlmKrtr=islGrckZaman&srlmYon=A&brcAlc=${brcAlc}`,
          headers: {
            rizaNo,
            // rizaNo: '011c7bed302443e8b05ddb122b0e6b20',
          },
        }
      },
    }),
    hesapRizaIptal: builder.query<any, string>({
      query: (rizaNo) => ({
        url: `https://devtest.fincloudy.com/api/v1/hesap-riza-iptal/${rizaNo}`,
        method: 'DELETE',
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const {
  useConnectHesapMutation,
  useGetHesaplerQuery,
  useLazyGetHesaplerQuery,
  useLazyGetHesaplarBakiyeQuery,
  useGetRizaNoQuery,
  useLazyGetBakiyeByRefIdQuery,
  useLazyGetHesapByRefIdQuery,
  useLazyGetTransactionQuery,
  useLazyHesapRizaIptalQuery,
  useLazyHesapRizaSorgulaQuery,
} = hesaplerApi
