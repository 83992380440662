import { Box } from '@mui/system'
import StepperProvider from '../../../components/StepperProvider'
import Scenarios from '../../../components/Scanario'
import OdemeAraci from '../../../components/odeme/OdemeAraci'
import OdemeStep_2 from '../../../components/odeme/OdemeStep_2'
import OdemeStep_1 from '../../../components/odeme/KurmusalStep'

import { Grid, TextField, InputLabel } from '@mui/material'
import StepContentRenderer from '../../../components/StepContentRenderer'
import { StepperContainer } from '../../../components/StepperContainer'
import StepperCard from '../../../components/StepperCard'
import { useOdemeHesapMutation } from '../../../api/odemeApi'
import { useEffect, useState } from 'react'
import RedirectPrompt from '../../../components/RedirectPrompt'
import { IOdemeAyrık } from '../../../types/odemeTypes'
import { FormProvider, useForm } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import ErrorSnackbar from '../../../components/ErrorSnackbar'
import { odemeAyrikDefaultValues } from '../../../types/formDefaultValues'

const kolasKurumsalDefaultValues: IOdemeAyrık = {
  ...odemeAyrikDefaultValues,
  gkd: {
    ...odemeAyrikDefaultValues.gkd,

    ayrikGkd: {
      ...odemeAyrikDefaultValues.gkd.ayrikGkd,

      ohkTanimDeger: '34816477268',
    },
  },
  odmBsltm: {
    ...odemeAyrikDefaultValues.odmBsltm,
    islTtr: {
      ...odemeAyrikDefaultValues.odmBsltm.islTtr,
      ttr: '36',
    },
    kmlk: {
      ...odemeAyrikDefaultValues.odmBsltm.kmlk,
      kmlkVrs: '34816477268',
      krmKmlkTur: 'V',
      krmKmlkVrs: '2439371956',
      ohkTur: 'K',
    },
    alc: {
      kolas: {
        kolasDgr: '56402004528',
        kolasTur: 'K',
      },
    },
    gon: {
      hspNo: 'TR810412802850996477278419',
      unv: 'h4128 u34816477268',
    },
    odmAyr: {
      ...odemeAyrikDefaultValues.odmBsltm.odmAyr,
      odmAcklm: 'Ayrık Kolas Kurumsal',
      refBlg: 'Y-2701852-202011',
    },
  },
}

const KolasTCKN = () => {
  return (
    <StepperContainer>
      <StepperCard>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Alıcı TCKN</InputLabel>
            <TextField
              fullWidth
              required
              id="outlined-required"
              defaultValue="56402004528"
            />
          </Grid>
        </Grid>
      </StepperCard>
    </StepperContainer>
  )
}

export const components = [
  {
    index: 0,
    label: 'Ödeme Hizmeti Kullanıcısı Türü Seçimi',
    component: <OdemeStep_1 />,
  },

  {
    index: 1,
    label: 'Kolas TCKN',
    component: <KolasTCKN />,
  },
  {
    index: 2,
    label: 'Ödeme Adım 3',
    component: <OdemeStep_2 />,
  },
  {
    index: 3,
    label: 'Ödeme Adım 4',
    component: <OdemeAraci />,
  },
  {
    index: 4,
    label: 'Senaryo Tipi Seç',
    component: <Scenarios />,
  },
]

function AyrikKolasKurumsal() {
  const [odemeBireysel, { isSuccess, isLoading, isError, error, data }] =
    useOdemeHesapMutation()
  const [showRedirectConfirmationDialog, setshowRedirectConfirmationDialog] =
    useState(false)
  const kolasKurumsalFormValues = 'kolasKurumsalAyrik'
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorAlert, setshowErrorAlert] = useState(false)

  const [defaultValues] = useState<IOdemeAyrık>(() => {
    const storedValues = window.sessionStorage.getItem('kolasKurumsalAyrik')

    if (storedValues) {
      // Parse the stored values
      const parsedValues: IOdemeAyrık = JSON.parse(storedValues)
      return parsedValues
    } else {
      return kolasKurumsalDefaultValues
    }
  })

  const formMethods = useForm<IOdemeAyrık>({
    defaultValues: defaultValues,
    // resolver: zodResolver(hesaplerSchema),
  })

  const { watch, setValue, getValues } = formMethods

  useFormPersist(kolasKurumsalFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })

  const handleSubmitButtonClick = (data: any) => {
    const scenarioType = getValues('scenarioType')
    odemeBireysel({ formData: data, scenarioType })
  }

  useEffect(() => {
    if (isSuccess && data) {
      const { hhsYonAdr } = data.gkd

      if (hhsYonAdr) {
        setshowRedirectConfirmationDialog(true)
      }
    } else if (isError && error) {
      let err
      if (error && 'data' in error) {
        const errorData: any = error.data
        const {
          errorCode,
          httpCode,
          moreInformationTr,
          timestamp,
          httpMessage,
        } = errorData

        err = `
         errorCode: ${errorCode}
         http code: ${httpCode}
         httpMessage: ${httpMessage} 
        errorMessage: ${moreInformationTr} 
        timeStamp: ${timestamp} 

         `
      } else {
        err = 'Internal server error. Try again'
      }
      setErrorMessage(err)
    }
  }, [isSuccess, data, isError, error])

  const handleRedirectConfirmClick = () => {
    window.open(data?.gkd?.hhsYonAdr, '_blank')
    setshowRedirectConfirmationDialog(false)
  }

  useEffect(() => {
    setshowErrorAlert(true)
  }, [errorMessage])

  return (
    <Box>
      {errorMessage && (
        <ErrorSnackbar
          open={showErrorAlert}
          onClose={() => setshowErrorAlert(false)}
          errorMessage={errorMessage}
        />
      )}
      <StepperProvider>
        <FormProvider {...formMethods}>
          <StepContentRenderer
            components={components}
            onSubmit={handleSubmitButtonClick}
            isSubmitting={isLoading}
          />
        </FormProvider>
      </StepperProvider>
      {showRedirectConfirmationDialog && (
        <RedirectPrompt
          openDialog={showRedirectConfirmationDialog}
          redirectUrl={`${data?.gkd?.hhsYonAdr} `}
          onClose={() => setshowRedirectConfirmationDialog(false)}
          onConfirm={handleRedirectConfirmClick}
        />
      )}
    </Box>
  )
}

export default AyrikKolasKurumsal
