import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridRowModesModel,
  GridRowId,
} from '@mui/x-data-grid'
import { trTR } from '@mui/x-data-grid/locales'

import styled from 'styled-components'
import { IInvoiceTable } from '../types/customTypes'

export const StyledDataGridHeader = styled(DataGrid)`
  && .css-yrdy0g-MuiDataGrid-columnHeaderRow {
    font-size: small;
    background-color: #f5f5f5;
  }
`

interface ITableProps<T> {
  rows: T[]
  columns: GridColDef[]
  backgroundColor?: string
  onSelectionModelChange?: (selectedIds: GridRowId[]) => void
}

const InvoiceTable: React.FC<ITableProps<IInvoiceTable>> = ({
  rows,
  columns,
  backgroundColor,
  onSelectionModelChange,
}) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [rowData, setRowData] = useState<IInvoiceTable[]>([])
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([])

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  useEffect(() => {
    if (rows) {
      setRowData(rows)
    }
  }, [rows])

  const handleSelectionModelChange = (newSelectionModel: GridRowId[]) => {
    setSelectedRowIds(newSelectionModel)
    if (onSelectionModelChange) {
      onSelectionModelChange(newSelectionModel)
    }
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <StyledDataGridHeader
        sx={{ backgroundColor: `${backgroundColor}` }}
        rows={rowData}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowSelectionModelChange={handleSelectionModelChange}
        onRowModesModelChange={handleRowModesModelChange}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        getRowId={(row) => row.id}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  )
}
export default InvoiceTable
