import StepperCard from '../../../components/Card'
import { StepperContainer } from '../../../components/StepperContainer'
import AutoComplete from '../../../components/CustomAutoComplete'
import { useFormContext } from 'react-hook-form'
import { Ihesapler } from '../../../types/customTypes'

function TCKNInput() {
  const { setValue, watch } = useFormContext<Ihesapler>()

  const handleTCKNInput = (value: string) => {
    setValue('kmlk.kmlkVrs', value)

    const index = tcknOptions.findIndex((option) => option === value)
    if (index !== -1) {
      const vknToSelect = vknOptions[index]
      setValue('kmlk.krmKmlkVrs', vknToSelect)
    }
  }

  const handleVKNInput = (value: string) => {
    setValue('kmlk.krmKmlkVrs', value)

    const index = vknOptions.findIndex((option) => option === value)
    if (index !== -1) {
      const tcknToSelect = tcknOptions[index]
      setValue('kmlk.kmlkVrs', tcknToSelect)
    }
  }

  const tcknOptions = [
    '28744037410',
    '49842477106',
    '79604025092',
    '50128322430',
    '55405313736',
    '67746635194',
    '42282562708',
    '11654904156',
    '80370185484',
  ]

  const vknOptions = [
    '1695047314',
    '6072997284',
    '8821274427',
    '9525738893',
    '4740799245',
    '5336525438',
    '3108040139',
    '6474324928',
    '8002264572',
  ]

  return (
    <StepperContainer
      alignContent="center"
      justifyContent="center"
      display="flex"
      height="420px"
    >
      <StepperCard width="500px" height="200px" justifyContent="center">
        <AutoComplete
          onInput={handleTCKNInput}
          options={tcknOptions}
          label="TCKN"
          value={watch('kmlk.kmlkVrs')}
        />
        <AutoComplete
          onInput={handleVKNInput}
          options={vknOptions}
          label="VKNo"
          value={watch('kmlk.krmKmlkVrs')}
        />
      </StepperCard>
    </StepperContainer>
  )
}

export default TCKNInput
